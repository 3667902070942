<template>
	<div>
		<loading :show="show" :label="label">
	      <div class="processing-row" slot="loading-container">
	        <div class="text-center">
	          <div slot="loading-spinner">
	            <img src="@/assets/images/station-loading.gif" height="100" width="100"/>
	          </div>
	          <p class="text-center" slot="loading-text">{{ label }}</p>
	        </div>
	      </div>
	    </loading>

	    <div class="panel">
	    	<div class="panel-body">
	    		<fieldset>
	    			<legend>Load Information</legend>
	    			<div v-show="getCarrierId != null">
						<p class="text-danger">This load will create an automatic booking to {{ getCarrierName }} and will not be available in load searches. They will be notified of the booking award.</p>
					</div>
					<form class="form" @submit.prevent="save">
						<div class="row">
							<div class="col-md-3">
								<div class="form-group">
									<label>Load Type</label>
									<select class="form-control" name="loadType" id="loadType" v-model="load.loadType" required>
										<option value="" disabled>Select option</option>
										<option value="NORMAL">Normal</option>
										<option value="ABNORMAL">Abnormal</option>
									</select>
								</div>
							</div>
							<div class="col-md-3">
								<div class="form-group">
									<label>Load Mode</label>
									<select class="form-control" name="loadMode" id="loadMode" v-model="load.loadMode" required>
										<option value="" disabled>Select option</option>
										<option value="FTL">Full truckload</option>
										<option value="LTL">Less than truckload</option>
										<option value="FCL">Full Container Load</option>
									</select>
								</div>
							</div>
							<div class="col-md-3">
								<div class="form-group">
									<label>Expiry Date</label>
									<input type="date" class="form-control" name="expiryDate" id="expiryDate" v-model="load.expiryDate" required/>
								</div>
							</div>
						</div>

						<h4><i>EQUIPMENT TYPE</i></h4>
						<div class="form-group">
							<label>Trailer Required</label>
							<select class="form-control" v-model="load.trailerTypeRequired" required>
								<option value="" disabled>Select option</option>
								<option value="FLATBED">Flatbed</option>
								<option value="VAN">Van</option>
								<option value="REEFER">Reefer</option>
								<option value="STEP_DECK">Step Deck</option>
								<option value="POWER_ONLY">Power Only</option>
								<option value="AUTO_CARRIER">Auto Carrier</option>
								<option value="B_TRAIN">B Train</option>
								<option value="BOX_TRUCK">Box Truck</option>
								<option value="CONESTOGA">Consetoga</option>
								<option value="CONTAINERS">Containers</option>
								<option value="DOUBLE_DROP">Double Drop</option>
								<option value="DRY_BULK">Dry Bulk</option>
								<option value="DUMP_TRAILER">Dump Trailer</option>
								<option value="HOPPER_BOTTOM">Hooper Bottom</option>
								<option value="HOTSHOT">Hotshot</option>
								<option value="LOWBOY">Lowboy</option>
								<option value="TANKER">Tanker</option>
							</select>
						</div>

						<div class="form-group">
							<label>Commodity / Description/ Freight description</label>
							<textarea type="text" name="commodity" id="commodity" class="form-control" placeholder="Enter commodity e.g 44,315 lbs of OSB" v-model="load.commodity" rows="5" required></textarea>
						</div>

						<div class="row">
							<div class="col-md-6">
								<div class="form-group">
									<label>Weight</label>
									<nice-numeric-input label="" hide-label noControls :max="9999999999" :min="0" :maxDecimalPlaces="3" v-model="load.weight" placeholder="Enter Weight" required/>
								</div>
							</div>
							<div class="col-md-6">
								<div class="form-group">
									<label>Weight Measurement</label>
									<select class="form-control" v-model="load.weightMeasurement" required>
										<option value="" disabled>Select option</option>
										<option value="KG">Kilogram</option>
										<option value="TONNE">Tonne</option>
										<option value="GT">Gigatonne</option>
										<option value="CU">Cubic</option>
									</select>
								</div>
							</div>
						</div>

						<div class="row">
							<div class="col-md-10"></div>
							<div class="col-md-2">
								<div class="form-group">
									<label>&nbsp;</label>
									<button class="btn btn-success btn-block" type="submit">Submit</button>
								</div>
							</div>
						</div>
					</form>
				</fieldset>
	    	</div>
	    </div>
	</div>
</template>

<script type="text/javascript">
	import loading from "vue-full-loading";
	import LoadService from "@/services/LoadService";
	import Load from "@/models/Load";

	export default{
		name: 'NewLoad',
		components: {
			loading,
		},

		data: function(){
			return {
				label: "Processing request",
				show: false,
				provinceId: null,
				load: new Load,
			}
		},

		created(){
			console.log(this.getCarrierId == null);
			console.log(this.getAssignedTruckNumber == null);
		},

		computed: {
			getLoadNumber: function(){
				return this.$route.params.loadNumber;
			},

			getCarrierId(){
				return this.$route.query.carrierId;
			},

			getCarrierName(){
				return this.$route.query.carrierName;
			},

			getAssignedTruckNumber(){
				return this.$route.query.assignedTruckNumber;
			}
		},

		methods: {
			save: function(){
				this.show = true;
				if (this.getCarrierId) {
					localStorage.setItem('carrierId', JSON.stringify(this.getCarrierId));
				}
				if (this.getAssignedTruckNumber != null) {
					localStorage.setItem('assignedTruckNumber', JSON.stringify(this.getAssignedTruckNumber));
				}
				this.load.loadNumber = this.getLoadNumber;
				this.load.owner = {
					username: this.$store.state.auth.user.user.username
				};
				LoadService.save(this.load).then(response => {
					if (response.data.statusCode === 201) {
						this.$router.push({ name: 'new-load-timing', params: { loadNumber: response.data.body.loadNumber }});
					}
					this.show = false;
				}).catch(error => {
					console.log(error);
					this.show = false;
				});
			},
		}
	};
</script>