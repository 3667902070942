<template>
	<div>
		<div class="row">
			<div class="col-md-3">
				<router-link :to="{ name: '' }" @click.native="setSelectedComponent('CARRIER_ASSIGNED')">
					<div class="panel">
						<div class="panel-body">
							<h4 class="pull-right"><span class="label label-default">1</span></h4>
							<p class="text-center">
								<img src="@/assets/images/checkmark.png" alt="Assigned Loads" height="25" width="25" class="img img-rounded img-responsive center-block"/><br>
								Carrier Assigned
							</p>					
						</div>
					</div>
				</router-link>
			</div>
			<div class="col-md-2">
				<router-link :to="{ name: '' }" @click.native="setSelectedComponent('DRIVER_ASSIGNED')">
					<div class="panel">
						<div class="panel-body">
							<h4 class="pull-right"><span class="label label-primary">1</span></h4>
							<p class="text-center">
								<img src="@/assets/images/shipped.png" alt="Assigned Loads" height="25" width="25" class="img img-rounded img-responsive center-block"/><br>
								Loading
							</p>					
						</div>
					</div>
				</router-link>
			</div>
			<div class="col-md-2">
				<router-link :to="{ name: '' }" @click.native="setSelectedComponent('IN_TRANSIT')">
					<div class="panel">
						<div class="panel-body">
							<h4 class="pull-right"><span class="label label-warning">1</span></h4>
							<p class="text-center">
								<img src="@/assets/images/delivery-truck.png" alt="Assigned Loads" height="25" width="25" class="img img-rounded img-responsive center-block"/><br>
								In transit
							</p>					
						</div>
					</div>
				</router-link>
			</div>
			<div class="col-md-3">
				<router-link :to="{ name: '' }" @click.native="setSelectedComponent('DELIVERY_NEEDS_PAPERWORK')">
					<div class="panel">
						<div class="panel-body">
							<h4 class="pull-right"><span class="label label-warning">1</span></h4>
							<p class="text-center">
								<img src="@/assets/images/checklist.png" alt="Assigned Loads" height="25" width="25" class="img img-rounded img-responsive center-block"/><br>
								Delivered
							</p>					
						</div>
					</div>
				</router-link>
			</div>
			<div class="col-md-2">
				<router-link :to="{ name: '' }" @click.native="setSelectedComponent('RECENTLY_DELIVERED')">
					<div class="panel">
						<div class="panel-body">
							<h4 class="pull-right"><span class="label label-success">1</span></h4>
							<p class="text-center">
								<img src="@/assets/images/delivered.png" alt="Assigned Loads" height="25" width="25" class="img img-rounded img-responsive center-block"/><br>
								Recently delivered
							</p>					
						</div>
					</div>
				</router-link>
			</div>
		</div>

		<CarrierAssigned v-show="selectedComponent === 'CARRIER_ASSIGNED'"/>
		<DriverAssignedLoads v-show="selectedComponent === 'DRIVER_ASSIGNED'"/>
		<InTransitLoads v-show="selectedComponent === 'IN_TRANSIT'"/>
		<DeliveryNeedsPaperWorkLoads v-show="selectedComponent === 'DELIVERY_NEEDS_PAPERWORK'"/>
		<RecentlyDeliveredLoads v-show="selectedComponent === 'RECENTLY_DELIVERED'"/>
	</div>
</template>

<script type="text/javascript">
	import CarrierAssigned from "@/views/shipments/CarrierAssigned";
	import DriverAssignedLoads from "@/views/shipments/DriverAssignedLoads";
	import InTransitLoads from "@/views/shipments/InTransitLoads";
	import DeliveryNeedsPaperWorkLoads from "@/views/shipments/DeliveryNeedsPaperWorkLoads";
	import RecentlyDeliveredLoads from "@/views/shipments/RecentlyDeliveredLoads";

	export default{
		data: function(){
			return {
				selectedComponent: JSON.parse(localStorage.getItem('selectedComponent'))
			}
		},

		components: {
			CarrierAssigned,
			DriverAssignedLoads,
			InTransitLoads,
			DeliveryNeedsPaperWorkLoads,
			RecentlyDeliveredLoads
		},

		methods: {
			setSelectedComponent(selectedComponent){
				this.selectedComponent = selectedComponent;
				localStorage.setItem('selectedComponent', JSON.stringify(selectedComponent));
			}
		}
	}
</script>