<template>
	<div>
		<loading :show="show" :label="label">
	      <div class="processing-row" slot="loading-container">
	        <div class="text-center">
	          <div slot="loading-spinner">
	            <img src="@/assets/images/station-loading.gif" height="100" width="100"/>
	          </div>
	          <p class="text-center" slot="loading-text">{{ label }}</p>
	        </div>
	      </div>
	    </loading>

		<div class="panel">
			<div class="panel-body">
				<form class="form">
					<div class="row">
						<div class="col-md-2">
							<div class="form-group">
								<label>Pick Up Date</label>
								<input type="date" name="pickupDate" id="pickupDate" class="form-control">
							</div>
						</div>
						<div class="col-md-2">
							<div class="form-group">
								<label>Pick Up</label>
								<select class="form-control">
									<option value="" disabled>Select option</option>
									<option>Harare</option>
									<option>Bulawayo</option>
								</select>
							</div>
						</div>
						<div class="col-md-2">
							<div class="form-group">
								<label>DH-P (mi)</label>
								<select class="form-control">
									<option value="" disabled>Select option</option>
									<option>50</option>
									<option>100</option>
									<option>150</option>
									<option>200</option>
									<option>250</option>
									<option>300</option>
								</select>
							</div>
						</div>
						<div class="col-md-2">
							<div class="form-group">
								<label>Drop Off</label>
								<select class="form-control">
									<option value="" disabled>Select option</option>
									<option>Harare</option>
									<option>Bulawayo</option>
								</select>
							</div>
						</div>
						<div class="col-md-2">
							<div class="form-group">
								<label>DH-P (mi)</label>
								<select class="form-control">
									<option value="" disabled>Select option</option>
									<option>50</option>
									<option>100</option>
									<option>150</option>
									<option>200</option>
									<option>250</option>
									<option>300</option>
								</select>
							</div>
						</div>
						<div class="col-md-2">
							<div class="form-group">
								<label>Trailer Type</label>
								<select class="form-control">
									<option value="" disabled>Select option</option>    
									<option value="FLATBED">FLATBED</option>
									<option value="VAN">VAN</option>
									<option value="REEFER">REEFER</option>
									<option value="STEP_DECK">STEP_DECK</option>
									<option value="POWER_ONLY">POWER_ONLY</option>
									<option value="AUTO_CARRIER">AUTO_CARRIER</option>
									<option value="B_TRAIN">B_TRAIN</option>
									<option value="BOX_TRUCK">BOX_TRUCK</option>
									<option value="CONESTOGA">CONESTOGA</option>
									<option value="CONTAINERS">CONTAINERS</option>
									<option value="DOUBLE_DROP">DOUBLE_DROP</option>
									<option value="DRY_BULK">DRY_BULK</option>
									<option value="DUMP_TRAILER">DUMP_TRAILER</option>
									<option value="HOPPER_BOTTOM">HOPPER_BOTTOM</option>
									<option value="HOTSHOT">HOTSHOT</option>
									<option value="LOWBOY">LOWBOY</option>
									<option value="TANKER">TANKER</option>
								</select>
							</div>
						</div>
					</div>
					<div class="row">
						Filters
						<div class="col-md-2">
							<div class="form-group">
								<label>Weight (lbs)</label>
								<select class="form-control">
									<option value="" disabled>Any size</option>
									<option value="">30000</option>
								</select>
							</div>
						</div>
						<div class="col-md-2">
							<div class="form-group">
								<label>Length (ft)</label>
								<select class="form-control">
									<option value="" disabled>Any size</option>
									<option value="">30000</option>
								</select>
							</div>
						</div>
						<div class="col-md-2">
							<div class="form-group">
								<label>Load Size</label>
								<select class="form-control">
									<option value="" disabled>Any size</option>
									<option value="">30000</option>
								</select>
							</div>
						</div>
						<div class="col-md-2">
							<div class="form-group">
								<label>Brokers</label>
								<select class="form-control">
									<option value="" disabled>Any size</option>
									<option value="">30000</option>
								</select>
							</div>
						</div>

						<div class="col-md-2">
							<div class="form-group">
								<label>&nbsp;</label><br>
								<button class="btn btn-primary">Search</button>
							</div>
						</div>
					</div>
				</form>
			</div>
		</div>

		<h4>{{ loads.length }} loads available</h4>
		<p>Find the best offers for you below</p>
		<p>{{ new Date().toDateString() }}</p>

		<div class="panel" v-for="load in loads" :key="load.id">
			<div class="panel-body">
				<div class="row">
					<router-link :to="{ name: 'view-load', params: { loadNumber: load.loadNumber }}">
						<div class="col-md-2">
							<b><i><span class="label label-primary">PICKUP</span></i></b>
							<h4>{{ load.pickupAddress !== null ? load.pickupAddress.province.name: "Not available" }}, {{ load.pickupAddress !== null ? load.pickupAddress.city.name: "Not available" }}</h4>
							<p>{{ load.pickupDate }} <img src="@/assets/images/at.png" height="14" width="14"> {{ load.pickupTime }}</p>
							<p><span class="badge">{{ load.trailerTypeRequired }}</span> required - <span class="badge">{{ load.loadMode }}</span></p>
						</div>
						<div class="col-md-1">
							<br><br><br>
							<img src="@/assets/images/right-arrow.png" height="30" width="30">
						</div>
						<div class="col-md-3">
							<span v-if="load.destinationAddress !== null">
								<b><i><span class="label label-success">DELIVERY</span></i></b> <span class="badge">{{ load.estimatedDistance }} {{ load.estimatedDistanceMeasurement }}</span>
								<span class="badge">{{ load.distance }}{{ load.distanceMeasurement }}</span>
								<h4>{{ load.destinationAddress !== null ? load.destinationAddress.province.name: "Not available" }}, {{ load.destinationAddress !== null ? load.destinationAddress.city.name: "Not available" }}</h4>
								<p>{{ load.dropOffDate }} <img src="@/assets/images/at.png" height="14" width="14"> {{ load.dropOffTime }}</p>
								<p>{{ load.weight }} {{ load.weightMeasurement }} of {{ load.commodity }}</p>
							</span>
						</div>
					</router-link>
					<div class="col-md-2">
						<br><br>
						<h4>{{ load.currency }}${{ load.price }}</h4>
						<p><span class="badge">{{ load.loadType }} load</span></p>
					</div>
					<div class="col-md-2" v-show="getRoles.includes('CARRIER')">
						<br><br>
						<router-link :to="{ name: 'bid-load', params: { loadNumber: load.loadNumber }}">
							<button class="btn btn-default btn-block">Bid Load <span class="glyphicon glyphicon-ok-circle"></span></button>
						</router-link>
					</div>
					<div class="col-md-2" v-show="getRoles.includes('CARRIER')">
						<br><br>
						<router-link :to="{ name: 'book-load', params: { loadNumber: load.loadNumber }}">
							<button class="btn btn-primary btn-block">Book Load <span class="glyphicon glyphicon-ok-circle"></span></button>
						</router-link>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script type="text/javascript">
	import loading from "vue-full-loading";
	import LoadService from "@/services/LoadService";

	export default{
		name: 'LoadList',
		components: {
			loading,
		},

		data: function(){
			return {
				label: "Processing request",
				show: false,
				loads: []
			}
		},

		created(){
			this.getLoads();
		},

		methods: {
			getLoads: function(){
				LoadService.findAll().then(response => {
					this.loads = response.data.body.filter(load => {
						return load.status === 'ACTIVE' && load.carrier === null && load.payment === null;
					})
		        }).catch(error => {
		        	console.log(error);
		        });
			}
		},

		computed: {
			getUser: function() {
		        return this.$store.state.auth.user.user;
		    },

		    getRoles: function() {
		    	let roles = [];
		    	for (var i = 0; i < this.getUser.authorities.length; i++) {
		    		roles.push(this.getUser.authorities[i].authority);
		    	}
		    	return roles;
		    },
		}
	};
</script>