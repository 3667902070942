<template>
  <div>
    <loading :show="show" :label="label">
      <div class="processing-row" slot="loading-container">
        <div class="text-center">
          <div slot="loading-spinner">
            <img src="@/assets/images/station-loading.gif" height="100" width="100"/>
          </div>
          <p class="text-center" slot="loading-text">{{ label }}</p>
        </div>
      </div>
    </loading>

    <div class="panel">
      <div class="panel-body">
        <h4>New customer contact people</h4>
        <p class="error-message text-center" v-if="errorMessage != null">{{ errorMessage }}</p>

        <form class="form" @submit.prevent="save">
          <button class="btn btn-success btn-sm pull-right" @click="addContactPersonFields" type="button">Add contact person <span class="glyphicon glyphicon-plus"></span></button><br>
          
          <div v-show="contactPersonFields.length > 0">
            <div v-for="(contactPersonField, index) in contactPersonFields" :key="index">
              <div class="form-group">
                <label for="salutation">Salutation</label>
                <select v-model="contactPersonField.salutation" class="form-control border-info" required>
                  <option value="">Select salutation</option>
                  <option value="MR">Mr</option>
                  <option value="MRS">Mrs</option>
                  <option value="MISS">Miss</option>
                  <option value="MS">Ms</option>
                  <option value="DOCTOR">Doc</option>
                  <option value="PROFESSOR">Prof</option>
                  <option value="SIR">Sir</option>
                </select>
              </div>

              <div class="form-group">
                <label for="firstName">First Name</label>
                <input type="text" v-model="contactPersonField.firstName" id="firstName" class="form-control" placeholder="Enter First Name" required/>
              </div>

              <div class="form-group">
                <label for="lastName">Last Name <span class="text-danger">* </span></label>
                <input type="text" v-model="contactPersonField.lastName" id="lastName" class="form-control" placeholder="Enter Last Name" required/>
              </div>

              <div class="form-group">
                <label for="email">Email</label>
                <input type="email" v-model="contactPersonField.email" id="email" class="form-control" placeholder="Enter Email"/>
              </div>

              <div>
                <button class="btn btn-success btn-sm pull-right" @click="addPhoneNumberFields(index)" type="button">Add more <span class="glyphicon glyphicon-plus"></span></button><br>
                <div v-show="contactPersonField.phoneNumbers.length > 0">
                  <div v-for="(phoneNumberField, phoneNumberIndex) in contactPersonField.phoneNumbers" :key="phoneNumberIndex">
                    <div class="row">
                      <div class="col-md-4">
                        <div class="form-group">
                          <label for="phoneNumbers">Country Code</label>
                          <select class="form-control" v-model="phoneNumberField.zipCodeId" required>
                            <option value="" disabled>Select option</option>
                            <option v-for="zipCode in zipCodes" :key="zipCode.id" :value="zipCode.id">+{{ zipCode.code }}</option>
                          </select>
                        </div>
                      </div>
                      <div class="col-md-6">
                        <div class="form-group">
                          <label for="phoneNumbers">Phone Number</label>
                          <input type="number" v-model="phoneNumberField.phoneNumber" name="phoneNumber" id="phoneNumber" class="form-control" placeholder="Enter phone number without zero prefix e.g. 7712345678" maxlength="9" oninput="javascript: if (this.value.length > this.maxLength) { return this.value = this.value.slice(0, this.maxLength); }" required/>
                        </div>
                      </div>
                      <div class="col-md-2">
                        <div class="form-group" v-show="contactPersonField.phoneNumbers.length > 1">
                          <label>&nbsp;</label><br>
                          <button class="btn btn-danger btn-sm pull-left" @click="contactPersonField.phoneNumbers.splice(phoneNumberIndex, 1)" type="button">Remove <span class="glyphicon glyphicon-minus"></span></button><br>
                        </div>
                      </div>
                    </div>                
                  </div>
                </div>
              </div>

              <div class="form-group" v-show="contactPersonFields.length >= 1">
                <label>&nbsp;</label>
                <button class="btn btn-danger btn-sm pull-right" @click="contactPersonFields.splice(index, 1)">Remove contact person <span class="glyphicon glyphicon-minus"></span></button><br>
              </div>
            </div>
          </div>
          <br><br>
          
          <div v-show="contactPersonFields.length >= 1">
            <div class="row">              
              <div class="col-md-10"></div>
              <div class="col-md-2">
                <button class="btn btn-success btn-block pull-right" type="submit">Submit</button>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script type="text/javascript">
  import ZipCodeService from "@/services/ZipCodeService.js";
  import CountryService from "@/services/CountryService.js";
  import ProvinceService from "@/services/ProvinceService.js";
  import CityService from "@/services/CityService.js";
  import AreaService from "@/services/AreaService.js";
  import loading from "vue-full-loading";
  import CustomerService from "@/services/CustomerService.js";
  import Customer from "@/models/Customer.js";

  export default {
    components: {
      loading
    },

    data: function() {
      return {
        show: false,
        label: "Processing request",
        zipCodes: [],
        countries: [],
        provinces: [],
        cities: [],
        areas: [],
        errorMessage: null,
        errors: [],
        genders: [],
        salutations: [],
        nationalities: [],
        contactPersonFields: [{
          personType: "PERSON",
          firstName: null,
          lastName: null,
          email: null,
          salutation: null,
          phoneNumbers: [{
            phoneNumber: null,
            zipCodeId: null
          }],
        }],
        customer: new Customer
      };
    },

    computed: {
      getId() {
        return this.$route.params.id;
      },

      getUser(){
        return this.$store.state.auth.user.user;
      }
    },

    created() {
      this.getZipCodes();
    },

    methods: {
      addContactPersonFields: function(){
        this.contactPersonFields.push({
          personType: "PERSON",
          firstName: null,
          lastName: null,
          email: null,
          salutation: null,
          phoneNumbers: [{
            zipCodeId: null,
            phoneNumber: null
          }],
        });
      },

      addPhoneNumberFields: function(index){
        this.contactPersonFields[index].phoneNumbers.push({
          phoneNumber: null
        });
      },

      getZipCodes: function() {
        ZipCodeService.findAll()
          .then(response => {
            this.zipCodes = response.data.body;
          })
          .catch(error => {
            console.log(error);
          });
      },

      findByZipCodeId: function() {
        CountryService.findByZipCodeId(this.fields[0].personLocation.zipCodeId)
          .then(response => {
            this.countries = response.data.data;
          })
          .catch(error => {
            console.log(error);
          });
      },

      findByCountryId: function() {
        ProvinceService.findByCountryId(this.fields[0].personLocation.countryId)
          .then(response => {
            this.provinces = response.data.data;
          })
          .catch(error => {
            console.log(error);
          });
      },

      findByProvinceId: function() {
        CityService.findByProvinceId(this.fields[0].personLocation.provinceId)
          .then(response => {
            this.cities = response.data.data;
          })
          .catch(error => {
            console.log(error);
          });
      },

      findByCityId: function() {
        AreaService.findByCityId(this.fields[0].personLocation.cityId)
          .then(response => {
            this.areas = response.data.data;
          })
          .catch(error => {
            console.log(error);
          });
      },

      save: function() {
        this.show = true;
        this.customer.id = this.getId;
        this.customer.contactPeople = this.contactPersonFields;
        this.customer.associatedUserUsername = this.getUser.username;
        CustomerService.save(this.customer).then(response => {
            if (response.data.statusCode == 201) {
              this.$router.push({ name: "customer-list" });
            }
            this.show = false;
          }).catch(error => {
            console.log(error);
            this.show = false;
          });
      }
    }
  };
</script>
