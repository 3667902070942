<template>
  <div>
    <ul class="nav" v-for="menu in getUserMenu" :key="menu.name">
      <li>
        <router-link :to="{ name: menu.link }" replace>
          <div class="row">
            <div class="col-md-3">
              <img :src="'/menuIcons/' + menu.icon + imageExtension" v-bind:alt="menu.name" height="40" width="40" class="img img-rounded img-responsive"/>
            </div>
            <div class="col-md-9">
              {{ dashboardLinkNameHidden ? "" : menu.name }}
            </div>
          </div>
          <div v-show="sideBarMenuToggle">
            <img :src="'/menuIcons/' + menu.icon + imageExtension" v-bind:alt="menu.name" height="30" width="30" class="img img-rounded img-responsive"/>
            {{ dashboardLinkNameHidden ? "" : menu.name }}
          </div>
        </router-link>
      </li>
    </ul>
  </div>
</template>

<script type="text/javascript">
  export default {
    props: {
      roles: {
        type: Array,
        default: function() {
          return [];
        }
      },

      dashboardLinkNameHidden: {
        // hide link name when we toggle sidebar
        type: Boolean,
        default: false
      },
      sideBarMenuToggle: {
        type: Boolean,
        default: false
      }
    },

    data: function() {
      return {
        menuArray: [],
        imageExtension: ".png"
      };
    },

    created() {},

    methods: {},

    computed: {
      getUserMenu: function() {
        // need to extent default menu and also load from a JSON file
        let superAdminMenu = [
          {
            name: "Dashboard",
            link: "dashboard",
            icon: "dashboard"
          },

          {
            name: "Settings",
            link: "settings",
            icon: "configuration"
          },

          {
            name: "Users",
            link: "users",
            icon: "users"
          },

          {
            name: "Companies",
            link: "companies",
            icon: "users"
          },

          {
            name: "Payments",
            link: "payments",
            icon: "users"
          },

          {
            name: "Subscriptions",
            link: "subscriptions",
            icon: "users"
          }
        ];

        let carrierMenu = [
          {
            name: "Dashboard",
            link: "dashboard",
            icon: "dashboard"
          },

          {
            name: "Operations",
            link: "operations-dashboard",
            icon: "gear"
          },

          {
            name: "Customers",
            link: "customers-dashboard",
            icon: "demand"
          },

          {
            name: "Fleet",
            link: "fleet-dashboard",
            icon: "trucks"
          },

          {
            name: "Accounting",
            link: "accounting-dashboard",
            icon: "accounting"
          },

          {
            name: "Reporting",
            link: "reporting-dashboard",
            icon: "performanceDashboard"
          },

          {
            name: "Settings",
            link: "settings-dashboard",
            icon: "analysis"
          },

          // one account for all operations
          // {
          //   name: "Manage Users",
          //   link: "settings-dashboard",
          //   icon: "analysis"
          // }
           {
            name: "Help Center",
            link: "help-center",
            icon: "customer-service"
          }
        ];

        let brokerMenu = [
          {
            name: "Dashboard",
            link: "dashboard",
            icon: "dashboard"
          },

          {
            name: "Operations",
            link: "operations-dashboard",
            icon: "gear"
          },

          {
            name: "Customers",
            link: "customers-dashboard",
            icon: "demand"
          },

          {
            name: "Accounting",
            link: "accounting-dashboard",
            icon: "accounting"
          },

          {
            name: "Reporting",
            link: "reporting-dashboard",
            icon: "performanceDashboard"
          },

          {
            name: "Settings",
            link: "settings-dashboard",
            icon: "analysis"
          },

           {
            name: "Help Center",
            link: "help-center",
            icon: "customer-service"
          }
        ];

        let shipperMenu = [
          {
            name: "Dashboard",
            link: "dashboard",
            icon: "dashboard"
          },

          {
            name: "Operations",
            link: "operations-dashboard",
            icon: "gear"
          },

          {
            name: "Customers",
            link: "customers-dashboard",
            icon: "demand"
          },

          {
            name: "Accounting",
            link: "accounting-dashboard",
            icon: "accounting"
          },

          {
            name: "Reporting",
            link: "reporting-dashboard",
            icon: "performanceDashboard"
          },

          {
            name: "Settings",
            link: "settings-dashboard",
            icon: "analysis"
          },

           {
            name: "Help Center",
            link: "help-center",
            icon: "customer-service"
          }
        ];

        let defaultMenu = [];

        if (this.roles.includes("SUPER_ADMIN") || this.roles.includes("ADMIN")) {
          return superAdminMenu;
        }
        if (this.roles.includes("SHIPPER")) {
          return shipperMenu;
        }
        if (this.roles.includes("BROKER")) {
          return brokerMenu;
        }
        if (this.roles.includes("CARRIER")) {
          return carrierMenu;
        }
        else {
          return defaultMenu;
        }
      }
    }
  };
</script>
