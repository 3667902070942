<template>
  <div>
    <loading :show="show" :label="label">
      <div class="processing-row" slot="loading-container">
        <div class="text-center">
          <div slot="loading-spinner">
            <img src="@/assets/images/station-loading.gif" height="100" width="100"/>
          </div>
          <p class="text-center" slot="loading-text">{{ label }}</p>
        </div>
      </div>
    </loading>

    <div class="panel">
      <img src="@/assets/logo.png" width="95" height="65" class="img img-responsive img-rounded"/>        
    </div>

    <div class="panel">
      <div class="panel-body">
        <p><i>The following must be attached where relevant. [Try not to refresh the page uncessesarily for the best experience when uploading]</i></p>
        <h4>N.B Please ensure to privide all the required documents to proceed</h4>
        <fieldset>
          <legend>Business Documents</legend>
          <h4 class="text-danger" v-if="message != null">{{ message }}</h4>
          <br>
          <ul class="list-group">
            <li class="list-group-item">
              <form class="form" enctype="multipart/form-data" @submit.prevent="saveCERTIFICATE_OF_INCORPORATION_OR_REGISTRATION">
                <div class="form-group">
                  <div class="row">
                    <div class="col-md-8">
                      <label>1. {{ attachmentTypes[0].description }}</label>
                      <span v-if="checkIfAttachmentAlreadyUploaded(attachmentTypes[0].name)">
                        <span v-if="!this.reUploadCERTIFICATE_OF_INCORPORATION_OR_REGISTRATION">
                          <input type="text" class="form-control" ref="registrationCertificateRef" disabled :value="'File name already uploaded: ' + returnAttachmentAlreadyUploadedFileName(attachmentTypes[0].name)">
                        </span>
                      </span>
                      <span v-else>
                        <input type="file" ref="registrationCertificateRef" v-on:change="selectCERTIFICATE_OF_INCORPORATION_OR_REGISTRATION" class="form-control" required accept="application/pdf, image/jpeg, image/png, image/jpg"/>
                      </span>
                      <span v-if="this.reUploadCERTIFICATE_OF_INCORPORATION_OR_REGISTRATION">
                        <input type="file" ref="registrationCertificateRef" v-on:change="selectCERTIFICATE_OF_INCORPORATION_OR_REGISTRATION" class="form-control" required accept="application/pdf, image/jpeg, image/png, image/jpg"/>
                      </span>
                    </div>
                    <div class="col-md-4">
                      <br>
                      <span v-if="checkIfAttachmentAlreadyUploaded(attachmentTypes[0].name)">
                        <span v-if="this.reUploadCERTIFICATE_OF_INCORPORATION_OR_REGISTRATION">
                          <button class="btn btn-success" type="submit">Upload</button>
                        </span>
                        <span v-else>
                          <button class="btn btn-default" type="button" @click="reUpload(attachmentTypes[0].name)">Re-upload</button>&nbsp;&nbsp;<span class="text-danger"><b> FILE UPLOADED</b></span>
                        </span>
                      </span>
                      <span v-else>
                        <button class="btn btn-success" type="submit">Upload</button>
                      </span>
                    </div>
                  </div>
                </div>
              </form>
            </li>

            <li class="list-group-item">
              <form class="form" @submit.prevent="saveTAX_CLEARANCE_CERTIFICATE">
                <div class="form-group">
                  <div class="row">
                    <div class="col-md-8">
                      <label>2. {{ attachmentTypes[1].description }}</label>
                      <span v-if="checkIfAttachmentAlreadyUploaded(attachmentTypes[1].name)">
                        <span v-if="!this.reUploadTAX_CLEARANCE_CERTIFICATE">
                          <input type="text" ref="taxCertificateRef" class="form-control" disabled :value="'File name already uploaded: ' + returnAttachmentAlreadyUploadedFileName(attachmentTypes[1].name)">
                        </span>
                      </span>
                      <span v-else>
                        <input type="file" ref="taxCertificateRef" v-on:change="selectTAX_CLEARANCE_CERTIFICATE" class="form-control" required accept="application/pdf, image/jpeg, image/png, image/jpg"/>
                      </span>
                      <span v-if="this.reUploadTAX_CLEARANCE_CERTIFICATE">
                        <input type="file" ref="taxCertificateRef" v-on:change="selectTAX_CLEARANCE_CERTIFICATE" class="form-control" required accept="application/pdf, image/jpeg, image/png, image/jpg"/>
                      </span>
                    </div>
                    <div class="col-md-4">
                      <br>
                      <span v-if="checkIfAttachmentAlreadyUploaded(attachmentTypes[1].name)">
                        <span v-if="this.reUploadTAX_CLEARANCE_CERTIFICATE">
                          <button class="btn btn-success" type="submit">Upload</button>
                        </span>
                        <span v-else>
                          <button class="btn btn-default" type="button" @click="reUpload(attachmentTypes[1].name)">Re-upload</button>&nbsp;&nbsp;<span class="text-danger"><b> FILE UPLOADED</b></span>
                        </span>
                      </span>
                      <span v-else>
                        <button class="btn btn-success" type="submit">Upload</button>
                      </span>
                    </div>
                  </div>
                </div>
              </form>
            </li>
        </ul>
      </fieldset>

      <div v-if="company !== null">
        <div class="row" v-show="company.businessDocuments.length >= 2">
          <div class="col-md-10"></div>
          <div class="col-md-2">
            <span v-if="companyType === 'CARRIER'">
              <router-link :to="{ name: 'new-business-profile/bank-accounts', params: { uuid: this.getUuid }}">
                <button class="btn btn-primary btn-block pull-right"> Proceed to finish off profile</button>
              </router-link>
            </span>
            <span v-else>
              <router-link :to="{ name: 'new-business-profile/subscriptions', params: { uuid: this.getUuid }}">
                <button class="btn btn-primary btn-block pull-right"> Proceed to finish off profile</button>
              </router-link>
            </span>
          </div>
        </div>
      </div>
      </div>
    </div>
</div>
</template>

<script type="text/javascript">
  import axios from "axios";
  import { GENERIC_SERVICE_URL } from "@/config/GenericServiceConfig.js";
  import loading from "vue-full-loading";
  import CompanyService from "@/services/CompanyService";
  import companyAttachments from "@/repository/CompanyAttachments.json";

  export default {
    name: "NewBusinessDocuments",
    components: {
      loading
    },

    data: function() {
      return {
        checkIfAttachmentUploaded: true,
        GENERIC_SERVICE_URL: GENERIC_SERVICE_URL,
        label: "Uploading file. Please wait...",
        show: false,
        message: null,
        errorMessage: null,
        errors: [],
        formData: new FormData(),
        formData2: new FormData(),
        companyAttachmentListEnum: [
          "CERTIFICATE_OF_INCORPORATION_OR_REGISTRATION",
          "TAX_CLEARANCE_CERTIFICATE"
        ],
        attachmentTypes: [],
        company: null,
        reUploadCERTIFICATE_OF_INCORPORATION_OR_REGISTRATION: false,
        reUploadTAX_CLEARANCE_CERTIFICATE: false,
        companyType: JSON.parse(localStorage.getItem("companyType")),
        maxFileSize: 5,
        fileSizeExceeded: false,
      };
    },

    computed: {
      getUuid(){ return this.$route.params.uuid; }
    },

    created: function() {
      console.log(JSON.parse(localStorage.getItem('username')));
      this.attachmentTypes = companyAttachments;
    },

    mounted(){
      this.findByUuid();
    },

    methods: {
      selectCERTIFICATE_OF_INCORPORATION_OR_REGISTRATION: function(event) {
        if ((event.target.files[0].size / (1024 * 1024) > this.maxFileSize)) {
          this.fileSizeExceeded = true;
          alert('File exceeds size of ' + this.maxFileSize + 'MB');
          this.$refs.registrationCertificateRef.value = null;
          return; 
        }else{
          this.formData.append("attachment", event.target.files[0]);
          this.formData.append("attachmentEnum", this.companyAttachmentListEnum[0]);
          this.formData.append("uuid", this.getUuid);
        }
      },

      selectTAX_CLEARANCE_CERTIFICATE: function(event) {
        if ((event.target.files[0].size / (1024 * 1024) > this.maxFileSize)) {
          this.fileSizeExceeded = true;
          alert('File exceeds size of ' + this.maxFileSize + 'MB');
          this.$refs.taxCertificateRef.value = null;
          return;
        }else{
          this.formData2.append("attachment", event.target.files[0]);
          this.formData2.append("attachmentEnum", this.companyAttachmentListEnum[1]);
          this.formData2.append("uuid", this.getUuid);
        }
      },

      uploadAttachment: function() {
        this.showLoader();
        axios.post(GENERIC_SERVICE_URL + "uploads/companyAttachment", this.formData).then(response => {
          if (response.status === 201) {
            this.formData = new FormData;
            this.message = response.data.body;
            //window.location.reload();
            console.log(this.message);
            this.$refs.registrationCertificateRef.value = null;
            this.findByUuid();
          }
          this.show = false;
        }).catch(error => {
          console.log(error.response.status);
          this.show = false;
          if (error.response.status === 500) {
            // this.message = "Snap! error occured. Please try again or contact support if problem persists";
            this.message = error.response.data.message;
          }
        });
      },

      uploadAttachment2: function() {
        this.showLoader();
        axios.post(GENERIC_SERVICE_URL + "uploads/companyAttachment", this.formData2).then(response => {
          if (response.status === 201) {
            this.formData2 = new FormData;
            this.message = response.data.body;
            //window.location.reload();
            console.log(this.message);
            this.findByUuid();
            this.$refs.taxCertificateRef.value = null;
          }
          this.show = false;
        }).catch(error => {
          console.log(error.response.status);
          this.show = false;
          if (error.response.status === 500) {
            // this.message = "Snap! error occured. Please try again or contact support if problem persists";
            this.message = error.response.data.message;
          }
        });
      },

      saveCERTIFICATE_OF_INCORPORATION_OR_REGISTRATION: function(event) {
        console.log(event);
        this.uploadAttachment();
      },

      saveTAX_CLEARANCE_CERTIFICATE: function(event) {
        console.log(event);
        this.uploadAttachment2();
      },

      invalidateFormData: function() {
        this.formData = new FormData();
      },

      clearNotificationData: function() {
        this.errors = null;
        this.errorMessage = null;
      },

      setNotificationData: function(response) {
        this.errors = response.data.errors;
        this.errorMessage = response.data.message;
      },

      showLoader: function() {
        this.show = true;
      },

      findByUuid: function(){
        CompanyService.findByUuid(this.getUuid).then(response => {
          this.company = response.data.body;
        }).catch(error => {
          console.log(error);
        });
      },

      checkIfAttachmentAlreadyUploaded: function(attachmentType) {
        let check = false;
        if (this.company !== null) {
          for(var i = 0; i < this.company.businessDocuments.length; i++){
            if (this.company.businessDocuments[i].attachmentType === attachmentType) {
              check = true;
            }
          }
        }
        return check;
      },

      returnAttachmentAlreadyUploadedFileName: function(attachmentType) {
        let returnAttachmentAlreadyUploaded = null;
        if (this.company !== null) {
          for(var i = 0; i < this.company.businessDocuments.length; i++){
            if (this.company.businessDocuments[i].attachmentType === attachmentType) {
              returnAttachmentAlreadyUploaded = this.company.businessDocuments[i].fileName;
            }
          }
        }
        return returnAttachmentAlreadyUploaded;
      },

      reUpload: function(attachmentType){
        if (attachmentType === "CERTIFICATE_OF_INCORPORATION_OR_REGISTRATION") { 
          this.reUploadCERTIFICATE_OF_INCORPORATION_OR_REGISTRATION = true;
        }

        if (attachmentType === "TAX_CLEARANCE_CERTIFICATE") { 
          this.reUploadTAX_CLEARANCE_CERTIFICATE = true;
        }
      }
    }
  };
</script>
