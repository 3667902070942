<template>
  <div>
    <div class="panel">
      <div class="panel-body">
        <div class="row">
          <div class="col-md-10"></div>
            <div class="col-md-2">
            <router-link :to="{ name: 'invoice-list' }">
              <button class="btn btn-warning pull-right">Close</button>
            </router-link>
          </div>
        </div>
        <br><br>

        <ul class="list-group">
          <li class="list-group-item">ID : {{ invoice.id }}</li>
          <li class="list-group-item">Name : {{ invoice.narration }}</li>
          <li class="list-group-item">Amount : {{ invoice.amount }}</li>
          <li class="list-group-item">Quantity : {{ invoice.quantity }}</li>
          <li class="list-group-item">Additional Details : {{ invoice.additionalDetails }}</li>
          <li class="list-group-item">
            <a :href="PDF_API_URL + 'pdf/generateInvoice/' + invoice.id " target="_blank" class="btn btn-primary">View Invoice</a>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script type="text/javascript">
  import InvoiceService from "@/services/InvoiceService.js";
  import Invoice from "@/models/Invoice.js";
  import { PDF_API_URL } from "@/config/PdfServiceConfig.js";

  export default {
    data: function() {
      return {
        invoice: new Invoice,
        PDF_API_URL: PDF_API_URL,
      };
    },

    computed: {
      getInvoiceNumber: function() {
        return this.$route.params.invoiceNumber;
      }
    },

    created: function() {
      this.findByInvoiceNumber();
    },

    methods: {
      findByInvoiceNumber: function() {
        InvoiceService.findByInvoiceNumber(this.getInvoiceNumber).then(response => {
          this.invoice = response.data.body;
        }).catch(error => {
          console.log(error);
        });
      }
    }
  };
</script>
