<template>
	<div>
		<loading :show="show" :label="label">
			<div class="processing-row" slot="loading-container">
				<div class="text-center">
					<div slot="loading-spinner">
						<img src="@/assets/images/station-loading.gif" height="100" width="100"/>
					</div>
					<p class="text-center" slot="loading-text">{{ label }}</p>
				</div>
			</div>
	    </loading>

		<div class="panel">
			<div class="panel-body">
				<div class="row">
					<div class="col-md-10">
						<h4>View driver details</h4>
					</div>
					<div class="col-md-2">
            <span v-if="getSource === 'VIEW_DRIVER'">
              <router-link :to="{ name: 'view-truck', params: { truckNumber: getTruckNumber }}">
                <button class="btn btn-primary btn-block">Back</button>
              </router-link>
            </span>
            <span v-else>
              <router-link :to="{ name: 'driver-list'}">
                <button class="btn btn-primary btn-block">Back</button>
              </router-link>
            </span>
					</div>
				</div>
        <br>

				<ul class="list-group" v-if="driver !== null">
					<li class="list-group-item">Driver number: {{ driver.driverNumber }}</li>
					<li class="list-group-item">First name: {{ driver.firstName }}</li>
          <li class="list-group-item">Last name: {{ driver.lastName }}</li>
          <li class="list-group-item">Email: {{ driver.email }}</li>
          <li class="list-group-item">Phone numbers: 
            <ul class="list-group" v-for="phoneNumber in driver.phoneNumbers" :key="phoneNumber.id">
              <li class="list-group-item">Number: +{{ phoneNumber.country.code }}{{ phoneNumber.number }}</li>
            </ul>
          </li>
          <li class="list-group-item">Home address: {{ driver.address !== null ? driver.address.physicalAddress + ', ' + driver.address.city.name : '' }}</li>
          <li class="list-group-item">Postal address: {{ driver.postalAddress !== null ? driver.postalAddress.physicalAddress + ', ' + driver.postalAddress.city.name : '' }}</li>
          <li class="list-group-item">Notes: {{ driver.description }}</li>
				</ul>
			</div>
		</div>
	</div>
</template>

<script type="text/javascript">
  import DriverService from "@/services/DriverService.js";
  import loading from "vue-full-loading";

  export default {
    name: "ViewDriver",
    components: {
      loading
    },

    data: function() {
      return {
        show: false,
        label: "Processing request",
        errors: null,
        driver: null
      };
    },

    mounted() {
      this.findById();
    },

    computed: {
    	getId() {
    		return this.$route.params.id;
    	},

      getSource(){
        return this.$route.query.source;
      },

      getTruckNumber(){
        return this.$route.query.truckNumber
      }
    },

    methods: {
      findById: function() {
        this.show = true;
        DriverService.findById(this.getId).then(response => {
          if (response.data.statusCode === 200) {
            this.driver = response.data.body;
          }
          this.show = false;
        }).catch(error => {
        	console.log(error);
          	this.show = false;
        });
      }
    }
  };
</script>