<template>
	<div>
		<loading :show="show" :label="label">
	      <div class="processing-row" slot="loading-container">
	        <div class="text-center">
	          <div slot="loading-spinner">
	            <img src="@/assets/images/station-loading.gif" height="100" width="100"/>
	          </div>
	          <p class="text-center" slot="loading-text">{{ label }}</p>
	        </div>
	      </div>
	    </loading>

	    <div class="panel">
	    	<img src="@/assets/logo.png" width="260" height="80" class="img img-responsive img-rounded"/>        
    	</div>

		<div class="panel">
			<div class="panel-body">
				<div class="row">
					<div class="col-md-3"></div>
					<div class="col-md-6">
						<h4 class="text-danger">Subscription Expired</h4>
						<img src="@/assets/images/info.png" width="100px" height="100px"><br><br>
						 <p>Your subscription period has expired. Your account has been suspended because we're still unable to process your payment. <a href="#">Learn more</a></p>
						 <p>You won't be able to use Freight Board services until you update your subscription information.</p>
						 <br>
						
						<h4 class="text-danger" v-if="message !== null">{{ message }}</h4>
						<!-- <form class="form" @submit.prevent="getRedirectUrl"> -->
							 <!-- <input type="text" name="subscriptionNumber" id="subscriptionNumber" placeholder="Enter subscription number" class="form-control" v-model="subscriptionNumber" required><br><br> -->
							 <button class="btn btn-primary pull-right" type="submit">Login to Freight Board to renew subscription</button>
						<!-- </form> -->
					</div>
					<div class="col-md-3"></div>
				</div>
			</div>
		</div>
	</div>
</template>

<script type="text/javascript">
	import SubscriptionService from "@/services/SubscriptionService";
	import loading from "vue-full-loading";
	import PaymentService from "@/services/PaymentService.js";

	export default{
		components: {
			loading
		},

		data: function(){
			return {
				show: false,
				label: 'Processing request',
				subscriptionNumber: null,
				message: null,
				renewedSubscription: null,
				activeSubscription: null
			}
		},

		mounted(){
			this.findByActiveSubscription();
		},

		computed: {
			currentUser() {
				return this.$store.state.auth.user.user;
			},
		},

		methods: {
			findByActiveSubscription(){
				this.show = true;
				SubscriptionService.findByActiveSubscription(this.currentUser.username).then(response => {
					if (response.data.statusCode === 200) {
						this.activeSubscription = response.data.body;
						if (!this.activeSubscription.expired) {
							this.$router.replace(this.$route.query.redirect || 'dashboard');
						}
					}
					this.show = false;
				}).catch(error => {
					console.log(error);
					this.show = false;
				});
			},

			getRedirectUrl: function(){
		        this.show = true;
		        let paymentPostDto = {
		            uid: this.getUuid,
		            payerUsername: this.currentUser.username,
		            amount: this.activeSubscription.subscriptionPlan.price,
		            currency: this.activeSubscription.subscriptionPlan.currency,
		            description: this.activeSubscription.invoice.narration,
		            method: "ONLINE",
		            gateway: "PAYNOW",
		            paymentType: "SUB_RENEWAL"
		        };
		        PaymentService.getRedirectUrl(paymentPostDto).then(response => {
		          if (response.data.statusCode === 201) {
		            if (response.data.body !== null) {
		              window.location.replace(response.data.body);
		            }
		          }
		          this.show = false;
		        }).catch(error => {
		          console.log(error);
		          this.show = false;
		        });
		      this.show = false;
		    }
		}
	};
</script>