import { REGISTRATION_SERVICE_URL } from "@/config/RegistrationServiceConfig.js";
import axios from "axios";

class OneTimePasswordService {

  OTPExpired(otpCode) {
    return axios.get(REGISTRATION_SERVICE_URL + "otp/OTPExpired/" + otpCode);
  }
}

export default new OneTimePasswordService;
