<template>
  <div>
    <ul class="list-group" v-if="businessDocuments.length !== 0">
      <li
        class="list-group-item"
        v-for="(businessDocument, index) in businessDocuments"
        :key="businessDocument.id"
      >
        <a
          :href="PDF_API_URL + 'pdf/attachments/' + businessDocument.id"
          target="_blank"
        >
          {{ index + 1 }}. View {{ businessDocument.fileName }}</a
        >
      </li>
    </ul>
  </div>
</template>

<script type="text/javascript">
import { PDF_API_URL } from "@/config/PdfServiceConfig.js";

export default {
  name: "AttachmentsComponent",
  props: {
    companyId: {
      type: Number,
      default: null
    },

    businessDocuments: {
      type: Array,
      default: function() {
        return [];
      }
    }
  },

  data: function() {
    return {
      PDF_API_URL: PDF_API_URL
    };
  },

  created: function() {}
};
</script>
