<template>
	<div>
		<loading :show="show" :label="label">
			<div class="processing-row" slot="loading-container">
				<div class="text-center">
					<div slot="loading-spinner">
						<img src="@/assets/images/station-loading.gif" height="100" width="100"/>
					</div>
					<p class="text-center" slot="loading-text">{{ label }}</p>
				</div>
			</div>
	    </loading>

		<div class="panel">
			<div class="panel-body">
				<div class="row">
					<div class="col-md-10">
						<h4>View customer details</h4>
					</div>
					<div class="col-md-2">
						<router-link :to="{ name: 'customer-list' }">
							<button class="btn btn-primary btn-block">Close</button>
						</router-link>
					</div>
				</div>
        <br>

				<ul class="list-group" v-if="customer !== null">
					<li class="list-group-item">Customer number: {{ customer.customerNumber }}</li>
					<li class="list-group-item">Name: {{ customer.name }}</li>
          <li class="list-group-item">Email: {{ customer.email }}</li>
          <li class="list-group-item"><h4>Phone numbers:</h4>
            <ul class="list-group" v-for="phoneNumber in customer.phoneNumbers" :key="phoneNumber.id">
              <li class="list-group-item">Number: +{{ phoneNumber.zipCode.code }}{{ phoneNumber.phoneNumber }}</li>
            </ul>
          </li>
          <li class="list-group-item">Home address: {{ customer.physicalAddress !== null ? customer.physicalAddress.physicalAddress + ' ' + customer.physicalAddress.city.name : '' }}</li>
          <li class="list-group-item">Postal address: {{ customer.postalAddress !== null ? customer.postalAddress.physicalAddress + ' ' + customer.postalAddress.city.name : '' }}</li>
          <li class="list-group-item">
            <h4>Contact people:</h4>
            <ul class="list-group" v-for="contact in customer.contactPeople" :key="contact.id">
              <ul class="list-group">
                <li class="list-group-item">First name: {{ contact.firstName }}</li>
                <li class="list-group-item">Last name: {{ contact.firstName }}</li>
                <li class="list-group-item">Email: {{ contact.firstName }}</li>
                <li class="list-group-item">Phone numbers: 
                  <ul class="list-group" v-for="phoneNumber in contact.phoneNumbers" :key="phoneNumber.id">
                    <li class="list-group-item">Number: +{{ phoneNumber.zipCode.code }}{{ phoneNumber.phoneNumber }}</li>
                  </ul>
                </li>
              </ul>
            </ul>
          </li>
				</ul>
			</div>
		</div>
	</div>
</template>

<script type="text/javascript">
  import CustomerService from "@/services/CustomerService.js";
  import loading from "vue-full-loading";

  export default {
    name: "ViewCustomer",
    components: {
      loading
    },

    data: function() {
      return {
        show: false,
        label: "Processing request",
        errors: null,
        customer: null
      };
    },

    created() {
      this.findById();
    },

    computed: {
    	getId() {
    		return this.$route.params.id;
    	}
    },

    methods: {
      findById: function() {
        this.show = true;
        CustomerService.findById(this.getId).then(response => {
          if (response.data.statusCode === 200) {
            this.customer = response.data.body;
          }
          this.show = false;
        }).catch(error => {
        	console.log(error);
          	this.show = false;
        });
      }
    }
  };
</script>