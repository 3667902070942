<template>
	<div>
		<loading :show="show" :label="label">
			<div class="processing-row" slot="loading-container">
				<div class="text-center">
					<div slot="loading-spinner">
						<img src="@/assets/images/station-loading.gif" height="100" width="100"/>
					</div>
					<p class="text-center" slot="loading-text">{{ label }}</p>
				</div>
			</div>
		</loading>

    <div class="panel">
      <img src="@/assets/logo.png" width="95" height="65" class="img img-responsive img-rounded"/>        
    </div>

    <h4>Select your prefered subscription plan to get the best our of FreightBoard</h4>
    <p><i>Based on tiered pricing</i></p>
    <div class="row">
      <div class="col-md-4" v-for="subscriptionPlan in subscriptionPlans" :key="subscriptionPlan.id">
        <div class="panel">
          <div class="panel-body">
            <ul class="list-group">
              <li class="list-group-item"><h4 class="text-center">{{ subscriptionPlan.name }}</h4></li>
              <li class="list-group-item">{{ subscriptionPlan.identifier }}</li>
              <li class="list-group-item">{{ subscriptionPlan.currency }}${{ subscriptionPlan.price }} / month</li>
              <li class="list-group-item">{{ subscriptionPlan.subscriptionType }}</li>
              <li class="list-group-item">
                <h4>{{ subscriptionPlan.subscriptionType !== 'BASIC' ? 'All Basic Plan features +': ''}}</h4>
                <ul class="list-group" v-for="subscriptionPlanFeature in subscriptionPlan.subscriptionPlanFeatures" :key="subscriptionPlanFeature.id">
                  <li class="list-group-item">{{ subscriptionPlanFeature.name }}</li>
                </ul>
              </li>
              <br>             
              <button :class="getSelectedSubscriptionPlanNumber === subscriptionPlan.subscriptionPlanNumber ? 'btn btn-success btn-block': 'btn btn-primary btn-block'" type="submit" @click="setSelectedSubscriptionPlanNumber(subscriptionPlan.subscriptionPlanNumber, subscriptionPlan.price, subscriptionPlan.currency)">
              <h4 class="text-center">{{ getSelectedSubscriptionPlanNumber === subscriptionPlan.subscriptionPlanNumber ? 'Selected': 'Subscribe' }}</h4>
              </button>
              
            </ul>
          </div>
        </div>
      </div>
    </div>
    <div class="panel" v-show="getSelectedSubscriptionPlanNumber !== null">
      <div class="panel-body">
        <form class="form text-center" @submit.prevent="getRedirectUrl">
          <button class="btn btn-primary pull-right" type="submit">Proceed to Payment</button>
        </form>
      </div>
    </div>
	</div>
</template>

<script type="text/javascript">
  import loading from "vue-full-loading";
  import SubscriptionPlanService from "@/services/SubscriptionPlanService.js";
  import PaymentService from "@/services/PaymentService.js";

  export default {
    name: "NewSubscription",
    components: {
      loading
    },
    data: function() {
      return {
        show: false,
        label: "Processing Subscription",
        errors: null,
        companyId: null,
        zipCodes: [],
        errorMessage: null,
        phoneNumberFields: [{
          zipCodeId: null,
          phoneNumber: null
        }],
        maxLength: 9,
        companyType: JSON.parse(localStorage.getItem("companyType")),
        username: JSON.parse(localStorage.getItem("username")),
        subscriptionPlan: null,
        subscriptionPlans: [],
        selectedSubscriptionPlanNumber: this.$store.state.selectedSubscriptionPlanNumber
      };
    },

    created() {
      console.log(JSON.parse(localStorage.getItem('companyType')));
      this.findAll();
    },

    computed: {
    	getUuid(){
    		return this.$route.params.uuid;
    	},

      getSelectedSubscriptionPlanNumber(){
        return this.$store.state.selectedSubscriptionPlanNumber;
      }
    },

    methods: {
      setSelectedSubscriptionPlanNumber(subscriptionPlanNumber, price, currency){
        this.$store.commit('setSelectedPrice', price);
        this.$store.commit('setSelectedCurrency', currency);
        this.$store.commit('setSelectedSubscriptionPlanNumber', subscriptionPlanNumber);
      },

      findAll: function() {
        this.show = true;
        SubscriptionPlanService.findAll(this.companyType).then(response => {
          this.subscriptionPlans = response.data.body.filter(subscriptionPlan => {
            return subscriptionPlan.identifier === this.companyType;
          });
          this.show = false;
        }).catch(error => {
          console.log(error);
          this.show = false;
        });
      },

      getRedirectUrl: function(){
        this.show = true;
        var price;
        var currency;
        var subscriptionPlanNumber;
        localStorage.setItem("subscriptionPlanNumber", this.getSelectedSubscriptionPlanNumber);
        if (this.$store.state.selectedPrice !== null && this.$store.state.selectedCurrency && this.$store.state.selectedSubscriptionPlanNumber !== null) {
          price = this.$store.state.selectedPrice;
          currency = this.$store.state.selectedCurrency;
          subscriptionPlanNumber = this.$store.state.selectedSubscriptionPlanNumber;
        }else{
          alert('Please select a subscription plan');
          return;
        }
        console.log(subscriptionPlanNumber);
        let paymentPostDto = {
            uid: this.getUuid,
            payerUsername: this.username,
            amount: price,
            currency: currency,
            description: "Test Transaction TXN", // append subscriptionPlanNumber as description
            method: "ECOCASH",
            gateway: "PAYNOW",
            paymentType: "SUB"
        }
        PaymentService.getRedirectUrl(paymentPostDto).then(response => {
          if (response.status === 201) {
            if (response.data.body !== null) {
              window.location.replace(response.data.body);
            }
          }
          this.show = false;
        }).catch(error => {
          console.log(error);
          this.show = true;
        });
      },
    }
  };
</script>
