<template>
	<div>
		<loading :show="show" :label="label">
	      <div class="processing-row" slot="loading-container">
	        <div class="text-center">
	          <div slot="loading-spinner">
	            <img src="@/assets/images/station-loading.gif" height="100" width="100"/>
	          </div>
	          <p class="text-center" slot="loading-text">{{ label }}</p>
	        </div>
	      </div>
	    </loading>

		<div class="row">
			<div class="col-md-3">
				<router-link :to="{ name: '' }" @click.native="setSelectedComponent('LOADS')">
					<div class="panel">
						<div class="panel-body">
							<h4 class="pull-right"><span class="label label-warning">{{ loadBookings.length }}</span></h4>
							<p class="text-center">
								<img src="@/assets/images/checklist.png" alt="Assigned Loads" height="25" width="25" class="img img-rounded img-responsive center-block"/><br>
								Shipments
							</p>					
						</div>
					</div>
				</router-link>
			</div>
			<div class="col-md-3">
				<router-link :to="{ name: '' }" @click.native="setSelectedComponent('RATE_MANAGER')">
					<div class="panel">
						<div class="panel-body">
							<h4 class="pull-right"><span class="label label-success">1</span></h4>
							<p class="text-center">
								<img src="@/assets/images/delivered.png" alt="Assigned Loads" height="25" width="25" class="img img-rounded img-responsive center-block"/><br>
								Rates
							</p>					
						</div>
					</div>
				</router-link>
			</div>
			<div class="col-md-3">
				<router-link :to="{ name: '' }" @click.native="setSelectedComponent('TRACKING')">
					<div class="panel">
						<div class="panel-body">
							<h4 class="pull-right"><span class="label label-default">&nbsp;</span></h4>
							<p class="text-center">
								<img src="@/assets/images/checkmark.png" alt="Assigned Loads" height="25" width="25" class="img img-rounded img-responsive center-block"/><br>
								TruckBoard Tracking
							</p>					
						</div>
					</div>
				</router-link>
			</div>
		</div>

		<Tracking v-show="selectedComponent === 'TRACKING'"/>
		<LoadBoard v-show="selectedComponent === 'LOAD_BOARD'"/>
		<Shipments v-show="selectedComponent === 'LOADS'"/>
		<Tenders v-show="selectedComponent === 'TENDERS'"/>
		<RateManager v-show="selectedComponent === 'RATE_MANAGER'"/>
	</div>
</template>

<script type="text/javascript">
	import Tracking from "@/views/operations/Tracking";
	import LoadBoard from "@/views/operations/LoadBoard";
	import Shipments from "@/views/operations/Shipments";
	import Tenders from "@/views/operations/Tenders";
	import RateManager from "@/views/operations/RateManager";
	import LoadBookingService from "@/services/LoadBookingService.js";
	import loading from "vue-full-loading";

	export default{
		data: function(){
			return {
				selectedComponent: JSON.parse(localStorage.getItem('selectedComponent')),
				loadBookings: [],
				label: 'Processing request',
				show: false
			}
		},

		components: {
			Tracking,
			LoadBoard,
			Shipments,
			Tenders,
			RateManager,
			loading
		},

		mounted(){
			if (this.getRoles.includes('BROKER') || this.getRoles.includes('SHIPPER')) {
				this.findByLoadOwnerUsername();
		    }
		    if (this.getRoles.includes('CARRIER')) {
		    	this.findByBookingCarrierAssociatedUserUsername();
		    }
		},

		computed: {
			getUser: function() {
				return this.$store.state.auth.user.user;
		    },

		    getRoles: function() {
		    	let roles = [];
		    	for (var i = 0; i < this.getUser.authorities.length; i++) {
		    		roles.push(this.getUser.authorities[i].authority);
		    	}
		    	return roles;
		    },
		},

		methods: {
			setSelectedComponent(selectedComponent){
				this.selectedComponent = selectedComponent;
				localStorage.setItem('selectedComponent', JSON.stringify(selectedComponent));
			},

			findByLoadOwnerUsername: function() {
				this.show = true;
		        LoadBookingService.findByLoadOwnerUsername(this.getUser.username).then(response => {
		    //     	this.loadBookings = response.data.body.filter(loadBooking => {
		    //     		return (loadBooking.booking.status === 'BOOKED' && loadBooking.load.status === 'BOOKED' && !loadBooking.load.released) ||
		    // 		(loadBooking.booking.status === 'BOOKED' && loadBooking.load.released && loadBooking.load.collected && !loadBooking.load.delivered && !loadBooking.load.confirmedDelivery) || 
		    // 		(loadBooking.booking.status === 'BOOKED' && loadBooking.load.released && loadBooking.load.collected && loadBooking.load.delivered && !loadBooking.load.confirmedDelivery || loadBooking.load.status === 'DELIVERED_WITH_DEFECTS') || 
						// (loadBooking.booking.status === 'BOOKED' && loadBooking.load.released && loadBooking.load.collected && loadBooking.load.delivered && loadBooking.load.confirmedDelivery) || (loadBooking.booking.status === 'CANCELLED')
		    //     	});

		        	this.loadBookings = response.data.body.filter(loadBooking => {
						return (loadBooking.booking.status === 'BOOKED' && !loadBooking.load.released) || (loadBooking.booking.status === 'BOOKED' && loadBooking.load.status === 'BOOKED' && loadBooking.load.released && !loadBooking.load.collected) || (loadBooking.booking.status === 'BOOKED' && loadBooking.load.released && loadBooking.load.collected && !loadBooking.load.delivered && !loadBooking.load.confirmedDelivery) || (loadBooking.booking.status === 'BOOKED' && loadBooking.load.released && loadBooking.load.collected && loadBooking.load.delivered && !loadBooking.load.confirmedDelivery || loadBooking.load.status === 'DELIVERED_WITH_DEFECTS') || (loadBooking.booking.status === 'BOOKED' && loadBooking.load.released && loadBooking.load.collected && loadBooking.load.delivered && loadBooking.load.confirmedDelivery && loadBooking.load.status === 'DELIVERY_CONFIRMED') || (loadBooking.booking.status === 'CANCELLED');
					});
		        	this.show = false;
		        }).catch(error => {
		        	console.log(error);
		        	this.show = false;
		        });
		    },

		    findByBookingCarrierAssociatedUserUsername: function() {
				this.show = true;
		        LoadBookingService.findByBookingCarrierAssociatedUserUsername(this.getUser.username).then(response => {
		    //     	this.loadBookings = response.data.body.filter(loadBooking => {
		    //     		return (loadBooking.booking.status === 'BOOKED' &&  loadBooking.load.status === 'BOOKED' && !loadBooking.load.released) ||
		    // 		(loadBooking.booking.status === 'BOOKED' && loadBooking.load.released && loadBooking.load.collected && !loadBooking.load.delivered && !loadBooking.load.confirmedDelivery) || 
		    // 		(loadBooking.booking.status === 'BOOKED' && loadBooking.load.released && loadBooking.load.collected && loadBooking.load.delivered && !loadBooking.load.confirmedDelivery || loadBooking.load.status === 'DELIVERED_WITH_DEFECTS') || 
						// (loadBooking.booking.status === 'BOOKED' && loadBooking.load.released && loadBooking.load.collected && loadBooking.load.delivered && loadBooking.load.confirmedDelivery) || (loadBooking.booking.status === 'CANCELLED')
		    //     	});
		    this.loadBookings = response.data.body.filter(loadBooking => {
						return (loadBooking.booking.status === 'BOOKED' && !loadBooking.load.released) || (loadBooking.booking.status === 'BOOKED' && loadBooking.load.status === 'BOOKED' && loadBooking.load.released && !loadBooking.load.collected) || (loadBooking.booking.status === 'BOOKED' && loadBooking.load.released && loadBooking.load.collected && !loadBooking.load.delivered && !loadBooking.load.confirmedDelivery) || (loadBooking.booking.status === 'BOOKED' && loadBooking.load.released && loadBooking.load.collected && loadBooking.load.delivered && !loadBooking.load.confirmedDelivery || loadBooking.load.status === 'DELIVERED_WITH_DEFECTS') || (loadBooking.booking.status === 'BOOKED' && loadBooking.load.released && loadBooking.load.collected && loadBooking.load.delivered && loadBooking.load.confirmedDelivery && loadBooking.load.status === 'DELIVERY_CONFIRMED') || (loadBooking.booking.status === 'CANCELLED');
					});
		        	this.show = false;
		        }).catch(error => {
		        	console.log(error);
		        	this.show = false;
		        });
		    },
		}
	}
</script>