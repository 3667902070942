<template>
	<div>
		<loading :show="show" :label="label">
	      <div class="processing-row" slot="loading-container">
	        <div class="text-center">
	          <div slot="loading-spinner">
	            <img src="@/assets/images/station-loading.gif" height="100" width="100"/>
	          </div>
	          <p class="text-center" slot="loading-text">{{ label }}</p>
	        </div>
	      </div>
	    </loading>

		<div v-if="subscription !== null">
	        <div v-if="subscription.subscriptionPlan.subscriptionType === 'BASIC'">
	          <div class="panel">
	            <div class="panel-body">
	              <h4 class="text-danger">Oops, Sorry you need to upgrade your subscription plan to manage dispatch.</h4>
	              <p>This feature allows you to manage your dispatch in the comfort of your home or office!</p>
	              <br>
	              <router-link :to="{ name: 'upgrade-subscription' }">
	                <button class="btn btn-primary">Upgrade Subscription</button>
	              </router-link>
	            </div>
	          </div>
	        </div>
	        <div v-else>
	        	<div class="panel">
					<div class="panel-body">
						<h4>Dispatch coming soon!</h4>
					</div>
				</div>	
	        </div>
	    </div>
	</div>
</template>

<script type="text/javascript">
	import loading from "vue-full-loading";
	import SubscriptionService from "@/services/SubscriptionService.js";

	export default{
		name: 'Shipments',
		components: {
			loading,
		},

		data: function(){
			return {
				label: "Processing request",
				show: false,
			    subscription: null
			}
		},

		mounted(){
			this.findByActiveSubscription();
		},

		computed: {
			getUser: function() {
				return this.$store.state.auth.user.user;
		    }
		},

		methods: {
			findByActiveSubscription(){
		        this.show = true;
		        SubscriptionService.findByActiveSubscription(this.getUser.username).then(response => {
		          if (response.data.statusCode === 200) {
		            this.subscription = response.data.body;
		          }
		          this.show = false;
		        }).catch(error => {
		          console.log(error);
		          this.show = false;
		        });
		    },
		}
	};
</script>