import { GENERIC_SERVICE_URL } from "@/config/GenericServiceConfig.js";
import axios from "axios";

class SubscriptionPlanService {
  findAll() {
    return axios.get(GENERIC_SERVICE_URL + "subscriptionPlans");
  }

  findByIdentifier(identifier) {
    return axios.get(GENERIC_SERVICE_URL + "subscriptionPlans/findByIdentifier/" + identifier);
  }
}

export default new SubscriptionPlanService;
