<template>
  	<div class="row">
  		<div class="col-md-4">
  			<div>
			  	<table class="table table-hover table-bordered">
			      <tbody>
			        <tr>
			          <th class="bg-success">distance</th>
			          <td>{{ distance }}</td>
			        </tr>

			        <tr>
			          <th class="bg-success">summary</th>
			          <td>{{ summary }}</td>
			        </tr>

			        <tr>
			          <th class="bg-success">copyrights</th>
			          <td>{{ copyrights }}</td>
			        </tr>

			        <tr>
			          <th class="bg-success">duration</th>
			          <td>{{ duration }}</td>
			        </tr>

			        <tr>
			          <th class="bg-success">start_address</th>
			          <td>{{ start_address }}</td>
			        </tr>

			        <tr>
			          <th class="bg-success">end_address</th>
			          <td>{{ end_address }}</td>
			        </tr>
			      </tbody>
			    </table>	
  		</div>
  	</div>
  	<div class="col-md-8">
  		<div id="map-canvas"></div>
  	</div>
  </div>
</template>

<script>
	import * as VueGoogleMaps from 'vue2-google-maps';

	export default {
	  name: 'google-map',
	  props: ['name'],
	  data: function () {
	    return {
	      mapName: this.name + "-map",
	      markerCoordinates: [{
	        latitude: 51.501527,
	        longitude: -0.1921837
	      }, {
	        latitude: 51.505874,
	        longitude: -0.1838486
	      }, {
	        latitude: 51.4998973,
	        longitude: -0.202432
	      }],
	      map: null,
	      bounds: null,
	      markers: [],

	      distance: 0,
	      summary: '',
	      copyrights: '',
	      duration: '',
	      start_address: '',
      	end_address: ''
	    }
	  },

	  computed: {
	  	google: VueGoogleMaps.gmapApi,
	  },

	  mounted: function () {
	  	this.$gmapApiPromiseLazy().then(() => {
	  		var directionsDisplay;
		    var directionsService = new this.google.maps.DirectionsService();
		    var map;

		    // function initialize() {
		        directionsDisplay = new this.google.maps.DirectionsRenderer();
		        var chicago = new this.google.maps.LatLng(-17.7801722, 31.0119168);
		        var mapOptions = {
		            zoom: 7,
		            center: chicago
		        };
		        map = new this.google.maps.Map(document.getElementById('map-canvas'), mapOptions);
		        directionsDisplay.setMap(map);
		        // google.maps.event.addDomListener(document.getElementById('routebtn'), 'click', calcRoute);
		    // }

		    // function calcRoute() {
		        var start = new this.google.maps.LatLng(-17.7801722, 31.0119168);
		        //var end = new google.maps.LatLng(38.334818, -181.884886);
		        var end = new this.google.maps.LatLng(-17.7902952, 31.073527);
		        /*
		          var startMarker = new google.maps.Marker({
		                      position: start,
		                      map: map,
		                      draggable: true
		                  });
		                  var endMarker = new google.maps.Marker({
		                      position: end,
		                      map: map,
		                      draggable: true
		                  });
		          */
		        var bounds = new this.google.maps.LatLngBounds();
		        bounds.extend(start);
		        bounds.extend(end);
		        map.fitBounds(bounds);
		        
		        var request = {
		            origin: start,
		            destination: end,
		            travelMode: this.google.maps.TravelMode.DRIVING
		        };
		        var self = this;
		        directionsService.route(request, function (response, status) {
		            if (status == self.google.maps.DirectionsStatus.OK) {
		                directionsDisplay.setDirections(response);
		                directionsDisplay.setMap(map);
		                var sumKM = 0;
		                var myroute = response.routes[0];
		                for (var i = 0; i < myroute.legs.length; i++) {
		                    sumKM += myroute.legs[i].distance.value;  //legs = Abschnitte from point to point
		                }
		                sumKM = sumKM / 1000;
		                console.log('sumKM: ', sumKM);

		                var distanceMeters = response.routes[0].legs[0].distance.value;
		                self.distance = distanceMeters;
		                self.summary = response.routes[0].summary;
		                self.copyrights = response.routes[0].copyrights;
		                self.duration = response.routes[0].legs[0].duration;
		                self.start_address = response.routes[0].legs[0].start_address;
		                self.end_address = response.routes[0].legs[0].end_address;
		                console.log(response.routes[0].legs[0]);
		            } else {
		                alert("Directions Request from " + start.toUrlValue(6) + " to " + end.toUrlValue(6) + " failed: " + status);
		            }
		        });
		    // }
		    //self.google.maps.event.addDomListener(window, 'load', this.initialize);
	  	});
	  }
	};
</script>
<style scoped>
#map-canvas {
  width: 800px;
  height: 600px;
  margin: 0 auto;
  background: gray;
}
</style>