<template>
	<div>
		<loading :show="show" :label="label">
	      <div class="processing-row" slot="loading-container">
	        <div class="text-center">
	          <div slot="loading-spinner">
	            <img src="@/assets/images/station-loading.gif" height="100" width="100"/>
	          </div>
	          <p class="text-center" slot="loading-text">{{ label }}</p>
	        </div>
	      </div>
	    </loading>

	    <div class="panel">
	    	<div class="panel-body">
	    		<h4>Driver assigned</h4>
	    		<table class="table table-responsive table-bordered">
			    	<thead class="bg-primary">
				        <th>Load Number</th>
				        <th>Booking Number</th>
				        <th>Load Price</th>
				        <th>Pickup</th>
				        <th>Drop Off</th>
				        <th>Assigned Truck</th>
				        <th>Load Status</th>
				      </thead>
				      <tbody>
				        <tr v-for="loadBooking in loadBookings" :key="loadBooking.id">
				        	<td>
				        		<router-link :to="{ name: 'view-load', params: { loadNumber: loadBooking.load.loadNumber }}">
				        			{{ loadBooking.load.loadNumber }}
				        		</router-link>
				        	</td>
				        	<td>{{ loadBooking.booking.bookingNumber }}</td>
				        	<td>{{ loadBooking.load.currency }}{{ loadBooking.load.price }}</td>
				          	<td>
				          		{{ loadBooking.load.pickupAddress !== null ? loadBooking.load.pickupAddress.city.name + ' ' + loadBooking.load.pickupAddress.province.name: '' }}
				          	</td>
				          	<td>
				          		{{ loadBooking.load.dropOffAddress !== null ? loadBooking.load.dropOffAddress.city.name + ' ' + loadBooking.load.dropOffAddress.province.name: '' }}
				          	</td>
				          	<td>
				          		<router-link :to="{ name: 'trucks/view', params: { truckNumber: loadBooking.booking.assignedTruck.truckNumber }}">
				          			{{ loadBooking.booking.assignedTruck !== null ? loadBooking.booking.assignedTruck.registrationNumber: '' }}
				          		</router-link>
				          	</td>
				          	<td>{{ loadBooking.load.status }}</td>
				          </tr>
				    </tbody>
				</table>
	    	</div>
	    </div>    
	</div>
</template>

<script type="text/javascript">
	import loading from "vue-full-loading";
	import BookingService from "@/services/BookingService.js";
	import LoadBookingService from "@/services/LoadBookingService.js";
	import LoadService from "@/services/LoadService.js";

	export default{
		name: 'AssignedLoads',
		components: {
			loading,
		},

		data: function(){
			return {
				label: "Processing request",
				show: false,
			    bookings: [],
			    loadBookings: []
			}
		},

		mounted(){
			this.findByLoadOwnerUsername();
		},

		computed: {
			getUser: function() {
				return this.$store.state.auth.user.user;
		    },

		    getRoles: function() {
		    	let roles = [];
		    	for (var i = 0; i < this.getUser.authorities.length; i++) {
		    		roles.push(this.getUser.authorities[i].authority);
		    	}
		    	return roles;
		    },
		},

		methods: {
			findByCarrierAssociatedUserUsername: function() {
				this.show = true;
		        BookingService.findByCarrierAssociatedUserUsername(this.getUser.username).then(response => {
		        	this.bookings = response.data.body;
		        	this.show = false;
		        }).catch(error => {
		        	console.log(error);
		        	this.show = false;
		        });
		    },

		    findByLoadOwnerUsername: function() {
				this.show = true;
		        LoadBookingService.findByLoadOwnerUsername(this.getUser.username).then(response => {
		        	this.loadBookings = response.data.body.filter(loadBooking => {
		        		console.log(loadBooking.load.released);
		        		return loadBooking.booking.status === 'BOOKED' && loadBooking.load.status === 'IN_TRANSIT' && loadBooking.load.released && loadBooking.load.collected;
		        	});
		        	this.show = false;
		        }).catch(error => {
		        	console.log(error);
		        	this.show = false;
		        });
		    },

		    collected: function(load){
				this.show = true;
				LoadService.collected(load).then(response => {
					if (response.data.statusCode === 201){
						this.findByBookingCarrierAssociatedUserUsername();
					}
				this.show = false;
				}).catch(error => {
					console.log(error);
					this.show = false;
				});
			},

			carrierConfirmedDelivery: function(load){
				this.show = true;
				LoadService.carrierConfirmedDelivery(load).then(response => {
					if (response.data.statusCode === 201){
						this.findByBookingCarrierAssociatedUserUsername();
					}
				this.show = false;
				}).catch(error => {
					console.log(error);
					this.show = false;
				});
			}
		}
	};
</script>