<template>
	<div>
		<button class="btn btn-primary" @click="pay">Pay Now</button>
		<br><br>
		<span v-show="redirectURL !== null">
			<h4><a :href="redirectURL">Click Here to Finish Processing Payment</a></h4>
		</span>
	</div>
</template>

<script type="text/javascript">
	import PaymentService from "@/services/PaymentService";

	export default {
		name: 'TestPayment',
		data: function(){
			return {
				redirectURL: null
			}
		},

		methods: {
			pay: function(){
				PaymentService.pay().then(response => {
					console.log(response);
					if (response.status === 200) {
						console.log('Yay Paid');
						this.redirectURL = response.data;
					}
				}).catch(error => {
					console.log(error);
				});
			},

			async: function(){
				// poll for transaction // wait for transaction to return response
				// save trans and generate POP for shipper
			}
		}
	};
</script>