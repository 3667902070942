<template>
	<div>
		<loading :show="show" :label="label">
      <div class="processing-row" slot="loading-container">
        <div class="text-center">
          <div slot="loading-spinner">
            <img src="@/assets/images/LucecoPLC-Loading.gif" height="100" width="100"/>
          </div>
          <p class="text-center" slot="loading-text">{{ label }}</p>
        </div>
      </div>
    </loading>
    
    <div class="panel">
      <div class="panel-body">
        <div v-if="company === null">
          <h4>You have not finished creating your profile.</h4>
          <p>Please proceeed to finish off.</p><br>
          <router-link :to="{ name: 'newBusinessProfile' }">
            <button class="btn btn-primary">Proceed</button>
          </router-link>
        </div>
      </div>
    </div>
	</div>
</template>

<script type="text/javascript">
  import loading from "vue-full-loading";
  import User from "@/models/User.js";
  import CompanyService from "@/services/CompanyService";
  import Company from "@/models/Company";

  export default {
    components: {
      loading
    },

    data() {
      return {
        user: new User,
        show: false,
        label: "Signing in. Please wait...",
        errorz: [],
        message: "",
        path: "/dashboardNavigation",
        errorMessage: null,
        company: new Company
      };
    },

    computed: {
      loggedIn() {
        return this.$store.state.auth.status.loggedIn;
      },

      getUser: function() {
        return this.$store.state.auth.user.user;
      },

      getRoles: function() {
        let roles = [];
        for (var i = 0; i < this.getUser.authorities.length; i++) {
          roles.push(this.getUser.authorities[i].authority);
        }
        return roles;
      },
    },

    created() {
      this.findByAssociatedUserUsername();
    },

    methods: {
      findByAssociatedUserUsername: function() {
        this.show = true;
        CompanyService.findByAssociatedUserUsername(this.$store.state.auth.user.user.username).then(response => {
          if (response.data.statusCode == 200) {
            this.company = response.data.body;
            if (this.company !== null) {
              this.$router.push({ name: 'dashboard' });
            }
            else if (this.getRoles.includes('ADMIN')) {
              this.$router.push({ name: 'dashboard' });
            }
          }
          this.show = false;
        }).catch(error => {
          console.log(error);
          this.show = false;
        });
      }
    }
  };
</script>