<template>
  <div>
    <loading :show="show" :label="label">
      <div class="processing-row" slot="loading-container">
        <div class="text-center">
          <div slot="loading-spinner">
            <img src="@/assets/images/station-loading.gif" height="100" width="100"/>
          </div>
          <p class="text-center" slot="loading-text">{{ label }}</p>
        </div>
      </div>
    </loading>

    <ul class="nav nav-tabs">
      <li class="active"><a href="#viewed" data-toggle="tab">Viewed</a></li>
      <li><a href="#contacted" data-toggle="pill">Contacted</a></li>
      <li><a href="#saved" data-toggle="pill">Saved</a></li>
      <li><a href="#offered" data-toggle="pill">Offered</a></li>
      <li><a href="#booked" data-toggle="pill">Booked</a></li>
    </ul>
    <br><br>

    <div class="tab-content">
      <div class="tab-pane fade in active" id="viewed">
        <ViewedLoads/>
      </div>

      <div class="tab-pane fade in" id="contacted">
      	<ContactedLoads/>
      </div>

      <div class="tab-pane fade in" id="saved">
      	<SavedLoads/>
      </div>

      <div class="tab-pane fade in" id="offered">
      	<OfferedLoads/>
      </div>

      <div class="tab-pane fade in" id="viewed">
      	<BookedLoads/>
      </div>
    </div>

  </div>
</template>

<script type="text/javascript">
	import loading from "vue-full-loading";
	import ViewedLoads from "@/components/loads/ViewedLoads";
	import ContactedLoads from "@/components/loads/ContactedLoads";
	import SavedLoads from "@/components/loads/SavedLoads";
	import OfferedLoads from "@/components/loads/OfferedLoads";
	import BookedLoads from "@/components/loads/BookedLoads";

	export default{
		name: 'CarrierMyLoadList',
		components: {
			loading,
			ViewedLoads,
			ContactedLoads,
			SavedLoads,
			OfferedLoads,
			BookedLoads
		},

		data: function(){
			return {
				label: "Processing request",
				show: false,
			    bids: []
			}
		},

		created(){
		},

		methods: {
			
		}
	};
</script>

