export default class Bid{
	constructor(id, bidNumber, loadNumber, carrierUsername, amount, currency, assignedTruckId){
		this.id = id;
		this.bidNumber = bidNumber;
		this.loadNumber = loadNumber;
		this.amount = amount;
		this.carrierUsername = carrierUsername;
		this.amount = amount;
		this.currency = currency;
		this.assignedTruckId = assignedTruckId;
	}
}