<template>
  <div>
    <loading :show="show" :label="label">
      <div class="processing-row" slot="loading-container">
        <div class="text-center">
          <div slot="loading-spinner">
            <img src="@/assets/images/station-loading.gif" height="100" width="100"/>
          </div>
          <p class="text-center" slot="loading-text">{{ label }}</p>
        </div>
      </div>
    </loading>

    <div class="panel">
      <div class="panel-body">
        <h4>Filters</h4>
        <form class="form" @submit.prevent="searchTrucks">
          <div class="row">
            <div class="col-md-2">
              <div class="form-group">
                <label>Pick Up Date</label>
                <input type="date" name="pickupDate" id="pickupDate" class="form-control" v-model="truck.yay">
              </div>
            </div>
            <div class="col-md-2">
              <div class="form-group">
                <label>Pick Up</label>
                <select class="form-control" v-model="truck.yay">
                  <option value="" disabled>Select option</option>
                  <option>Harare</option>
                  <option>Bulawayo</option>
                </select>
              </div>
            </div>
            <div class="col-md-2">
              <div class="form-group">
                <label>DH-P (mi)</label>
                <select class="form-control" v-model="truck.yay">
                  <option value="" disabled>Select option</option>
                  <option>50</option>
                  <option>100</option>
                  <option>150</option>
                  <option>200</option>
                  <option>250</option>
                  <option>300</option>
                </select>
              </div>
            </div>
            <div class="col-md-2">
              <div class="form-group">
                <label>Drop Off</label>
                <select class="form-control" v-model="truck.yay">
                  <option value="" disabled>Select option</option>
                  <option>Harare</option>
                  <option>Bulawayo</option>
                </select>
              </div>
            </div>
            <div class="col-md-2">
              <div class="form-group">
                <label>DH-P (mi)</label>
                <select class="form-control" v-model="truck.yay">
                  <option value="" disabled>Select option</option>
                  <option>50</option>
                  <option>100</option>
                  <option>150</option>
                  <option>200</option>
                  <option>250</option>
                  <option>300</option>
                </select>
              </div>
            </div>
            <div class="col-md-2">
              <div class="form-group">
                <label>Trailer Type</label>
                <select class="form-control" v-model="truck.trailerType" required>
                  <option value="" disabled>Select option</option>    
                  <option value="FLATBED">FLATBED</option>
                  <option value="VAN">VAN</option>
                  <option value="REEFER">REEFER</option>
                  <option value="STEP_DECK">STEP_DECK</option>
                  <option value="POWER_ONLY">POWER_ONLY</option>
                  <option value="AUTO_CARRIER">AUTO_CARRIER</option>
                  <option value="B_TRAIN">B_TRAIN</option>
                  <option value="BOX_TRUCK">BOX_TRUCK</option>
                  <option value="CONESTOGA">CONESTOGA</option>
                  <option value="CONTAINERS">CONTAINERS</option>
                  <option value="DOUBLE_DROP">DOUBLE_DROP</option>
                  <option value="DRY_BULK">DRY_BULK</option>
                  <option value="DUMP_TRAILER">DUMP_TRAILER</option>
                  <option value="HOPPER_BOTTOM">HOPPER_BOTTOM</option>
                  <option value="HOTSHOT">HOTSHOT</option>
                  <option value="LOWBOY">LOWBOY</option>
                  <option value="TANKER">TANKER</option>
                </select>
              </div>
            </div>
          </div>

          <div class="row">
            <div class="col-md-2">
              <div class="form-group">
                <label>Weight (kg)</label>
                <nice-numeric-input label="" hide-label noControls :max="9999999999" :min="0" :maxDecimalPlaces="3" placeholder="Enter weight" v-model="truck.weight"/>
              </div>
            </div>
            <div class="col-md-2">
              <div class="form-group">
                <label>Length (m)</label>
                <nice-numeric-input label="" hide-label noControls :max="9999999999" :min="0" :maxDecimalPlaces="3" placeholder="Enter length" v-model="truck.length"/>
              </div>
            </div>
            <div class="col-md-2">
              <div class="form-group">
                <label>Load Size</label>
                <select class="form-control" name="loadMode" id="loadMode" v-model="truck.loadMode" required>
                  <option value="" disabled>Select option</option>
                  <option value="FTL">Full truckload</option>
                  <option value="LTL">Less than truckload</option>
                  <option value="FCL">Full Container Load</option>
                </select>
              </div>
            </div>
            <div class="col-md-2">
              <div class="form-group">
                <label>Truck Type</label>
                <select class="form-control" name="truckType" v-model="truck.truckType" required>
                  <option value="" disabled>Select option</option>
                  <option value="STANDARD">STANDARD</option>
                  <option value="BOX_TRUCK">BOX_TRUCK</option>
                  <option value="POWER_ONLY">POWER_ONLY</option>
                </select>
              </div>
            </div>
            <div class="col-md-2">
              <div class="form-group">
                <label>&nbsp;</label><br>
                <button class="btn btn-primary" type="submit">Search <span class="glyphicon glyphicon-filter"></span></button>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>


    <div class="panel">
      <div class="panel-body">
        <h4>Find available carriers based on your load requirements.</h4>
        <p>You may input the lane info you’re looking for to view all truck capacities recommended.</p>
        <br><br>
        
        <table class="table table-responsive table-bordered" v-show="carrierTrucks.length > 0">
          <thead class="bg-primary">
            <th>ID</th>
            <th>Truck Number</th>
            <th>Carrier</th>
            <th>Vehicle Registration Number</th>
            <th>Trailer Type</th>
            <th>Max Load Size</th>
            <th>Actions</th>
          </thead>
          <tbody>
            <tr v-for="carrierTruck in carrierTrucks" :key="carrierTruck.id">
              <td>{{ carrierTruck.truck.id }}</td>
              <td>{{ carrierTruck.truck.truckNumber }}</td>
              <td>{{ carrierTruck.carrier.name }}</td>
              <td>{{ carrierTruck.truck.vehicleRegistrationNumber }}</td>
              <td>{{ carrierTruck.truck.trailerType }}</td>
              <td>{{ carrierTruck.truck.maxLoadSize }}{{ truck.maxLoadSizeMeasurement }}</td>
              <td>
                <router-link :to="{ name: 'trucks/view', params: { truckNumber: carrierTruck.truck.truckNumber }}">
                  <button class="btn btn-primary col-4">View <span class="glyphicon glyphicon-info-sign"></span></button>
                </router-link>&nbsp;
                <router-link :to="{ name: 'new-load-basic-details', query: { carrierId: carrierTruck.carrier.id, carrierName: carrierTruck.carrier.name, assignedTruckNumber: carrierTruck.truck.truckNumber }}">
                  <button class="btn btn-warning col-4">Book truck <span class="glyphicon glyphicon-edit"></span></button>
                </router-link>
              </td>
            </tr>
          </tbody>
        </table>

        <h4 class="text-danger" v-show="carrierTrucks.length === 0">Oops, no trucks that meet search criteria found</h4>

        <ul class="pagination">
          <li><a href="#">Prev</a></li>
          <li class="active"><a href="#">1</a></li>
          <li><a href="#">2</a></li>
          <li><a href="#">Next</a></li>
        </ul>
      </div>
    </div>

  </div>
</template>

<script type="text/javascript">
  import CarrierTruckService from "@/services/CarrierTruckService";
  import loading from "vue-full-loading";
  import Truck from "@/models/Truck";

  export default {
    name: "SearchTrucks",
    components: {
      loading
    },

    data: function() {
      return {
        carrierTrucks: [],
        show: false,
        label: "Processing request",
        truck: new Truck,
      };
    },

    created(){
    },

    methods: {
      refresh: function(){
        this.findAll();
      },

      searchTrucks: function() {
        this.show = true;
        CarrierTruckService.searchTrucks(this.truck).then(response => {
          this.carrierTrucks = response.data.body;
          // save the search to localStorage and show it and delete when move away from page
          this.show = false;
        }).catch(error => {
          console.log(error);
          this.show = false;
        });
      }
    }
  };
</script>