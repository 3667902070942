<template>
	<div>
		<loading :show="show" :label="label">
			<div class="processing-row" slot="loading-container">
				<div class="text-center">
					<div slot="loading-spinner">
						<img src="@/assets/images/station-loading.gif" height="100" width="100"/>
					</div>
					<p class="text-center" slot="loading-text">{{ label }}</p>
				</div>
			</div>
	    </loading>

		<h4>Find facilities for loads</h4>
		<div class="panel">
	      <div class="panel-body">
	        <form class="form">
	          <div class="row">
	            <div class="col-md-4">
	            	<div class="form-group">
	            		<label>Facility name</label>
	            		<input type="text" name="registrationNumber" class="form-control" v-model="registrationNumber" placeholder="Enter registration number">
	            	</div>
	            </div>
	            <div class="col-md-4"></div>
	            <div class="col-md-2">
					<div class="form-group">
						<label>&nbsp;</label><br>
						<!-- <button class="btn btn-block btn-primary" type="button" @click="search()">Search</button> -->
					</div>
				</div>
	            <div class="col-md-2">
	              <div class="form-group">
	                <label>&nbsp;</label><br>
	                <button class="btn btn-block" type="button" @click="clearFilter()" style="background-color: #000000; color: #ffffff;">Clear filter</button>
	              </div>
	            </div>
	          </div>
	        </form>
	      </div>
	    </div>

		<div class="panel">
			<div class="panel-body">
				<table class="table table-bordered table-responsive table-hover">
					<thead class="bg-primary">
						<th>Facility Name</th>
						<th>Address</th>
						<th>Phone</th>
					</thead>
					<tbody>
						<tr v-for="facility in paginatedFacilityList" :key="facility.id">
							<td>{{ facility.name }}</td>
							<td>
								<span v-if="facility.address !== null">
									<span v-if="facility.address.physicalAddress !== null">
										{{ facility.address.physicalAddress }}
									</span>
								</span>
							</td>
							<td>
								<span v-if="!facility.isHidden">{{ facility.phoneNumbers[0].number }}</span>&nbsp;
								<button :class="facility.isHidden ? 'btn btn-primary': 'btn btn-danger'" @click="facility.isHidden = !facility.isHidden">
									<span v-if="facility.isHidden">Show phone number</span>
									<span v-else>Hide</span>
								</button>
							</td>
						</tr>
					</tbody>
				</table>
			</div>
		</div>
		<div class="row" v-if="totalPages > 1">
			<div class="col-md-7"></div>
	      	<div class="col-md-3 pull-right">
	      		<div class="panel">
	      			<div class="panel-body">
	      				<button class="btn btn-primary glyphicon glyphicon-menu-left" :disabled="currentPage === 1" @click="prevPage"></button>
	      				&nbsp;<span>Page {{ currentPage }} of {{ totalPages }}</span>&nbsp;
	      				<button class="btn btn-primary glyphicon glyphicon-menu-right" :disabled="currentPage === totalPages" @click="nextPage"></button>
	      			</div>
	      		</div>
	      </div>
	    </div>
	</div>
</template>

<script type="text/javascript">
  import CompanyService from "@/services/CompanyService.js";
  import loading from "vue-full-loading";
  import CityService from "@/services/CityService";

  export default {
    name: "FacilityList",
    components: {
      loading
    },

    data: function() {
      return {
        show: false,
        label: "Processing request",
        errors: null,
        facilities: [],
        cities: [],
        currentPage: JSON.parse(localStorage.getItem('currentPage')) !== null ? JSON.parse(localStorage.getItem('currentPage')): 1,
        itemsPerPage: 3,
        registrationNumber: null,
        truckNumber: null,
        trailerType: null,
        make: null,
        model: null,
        year: null,
        vehicleIdentificationNumber: null
      };
    },

    created() {
    	this.registrationNumber = JSON.parse(localStorage.getItem('registrationNumber'));
	    this.truckNumber = JSON.parse(localStorage.getItem('truckNumber'));
	    this.trailerType = JSON.parse(localStorage.getItem('trailerType'));
	    this.make = JSON.parse(localStorage.getItem('make'));
	    this.model = JSON.parse(localStorage.getItem('model'));
	    this.year = JSON.parse(localStorage.getItem('year'));
	    this.vehicleIdentificationNumber = JSON.parse(localStorage.getItem('vehicleIdentificationNumber'));
      	this.findAll();
    },

    methods: {
    	findAllCities: function(){
	        CityService.findAll().then(response => {
	          this.cities = response.data.body;
	        }).catch(error => {
	          console.log(error);
	        });
	      },

      findAll: function() {
        this.show = true;
        CompanyService.findAll().then(response => {
          if (response.data.statusCode === 200) {
            this.facilities = response.data.body.filter(company => {
            	return (company.type === 'BROKER' || company.type === 'SHIPPER') && company.verified
            		&& company.associatedUser.username !== this.getUser.username;
            });
           	let transformedFacilities = [];
           	for(let i = 0; i < this.facilities.length; i++){
           		transformedFacilities.push({
           			name: this.facilities[i].name,
           			phoneNumbers: this.facilities[i].phoneNumbers,
                address: this.facilities[i].address,
           			isHidden: true
           		});
           	}
           	this.facilities = transformedFacilities;
          }
          this.show = false;
        }).catch(error => {
        	console.log(error);
          	this.show = false;
        });
      },

       prevPage(){
          if (this.currentPage > 1) {
            const currentPage = --this.currentPage;
            localStorage.setItem('currentPage', JSON.stringify(currentPage));
          }
        },

        nextPage(){
          if (this.currentPage < this.totalPages) {
            const currentPage = ++this.currentPage;
            localStorage.setItem('currentPage', JSON.stringify(currentPage));
          }
        },

        clearFilter(){
          localStorage.removeItem('registrationNumber');
          localStorage.removeItem('truckNumber');
          localStorage.removeItem('trailerType');
          localStorage.removeItem('make');
          localStorage.removeItem('model');
          localStorage.removeItem('year');
          localStorage.removeItem('vehicleIdentificationNumber');
          localStorage.removeItem('currentPage');
          window.location.reload();
      }
    },

        computed: {
      getTruckNumber(){
        return this.$route.query.truckNumber;
      },

      years: function() {
          const year = new Date().getFullYear();
          const years = Array.from(
            { length: year - 1900 },
            (value, index) => 1901 + index
          );
          return years;
        },

      getUser: function() {
            return this.$store.state.auth.user.user;
        },

        totalPages(){
          return Math.ceil(this.filteredFacilities.length / this.itemsPerPage);
        },

        filteredFacilities(){
          let filteredFacilities;
          if ((this.registrationNumber !== null && this.registrationNumber !== undefined) || 
            (this.truckNumber !== null && this.truckNumber !== undefined) ||
            (this.trailerType !== null && this.trailerType !== undefined) || 
            (this.make !== null && this.make !== undefined) || 
            (this.model !== null && this.model !== undefined) || 
            (this.year !== null && this.year !== undefined) || 
            (this.vehicleIdentificationNumber !== null && this.vehicleIdentificationNumber !== undefined)) {

            localStorage.setItem('registrationNumber', JSON.stringify(this.registrationNumber));
            localStorage.setItem('truckNumber', JSON.stringify(this.truckNumber));
            localStorage.setItem('trailerType', JSON.stringify(this.trailerType));
            localStorage.setItem('make', JSON.stringify(this.make));
            localStorage.setItem('model', JSON.stringify(this.model));
            localStorage.setItem('year', JSON.stringify(this.year));
            localStorage.setItem('vehicleIdentificationNumber', JSON.stringify(this.vehicleIdentificationNumber));

            filteredFacilities = this.facilities.filter(truck => {
              return truck.registrationNumber === this.registrationNumber || 
                truck.truckNumber === this.truckNumber || 
                truck.trailerType === this.trailerType || 
                truck.make === this.make || 
                truck.model === this.model || 
                truck.year === this.year || 
                truck.vehicleIdentificationNumber === this.vehicleIdentificationNumber
            });
          }else{
            filteredFacilities = this.facilities;
          }
          return filteredFacilities;
        },

        paginatedFacilityList(){
          const startIndex = (this.currentPage - 1) * this.itemsPerPage;
          return this.filteredFacilities.slice(startIndex, startIndex + this.itemsPerPage);
      }
    }
  };
</script>