<template>
  <div>
    <loading :show="show" :label="label">
      <div class="processing-row" slot="loading-container">
        <div class="text-center">
          <div slot="loading-spinner">
            <img src="@/assets/images/station-loading.gif" height="100" width="100"/>
          </div>
          <p class="text-center" slot="loading-text">{{ label }}</p>
        </div>
      </div>
    </loading>

    <div class="panel">
      <div class="panel-body">
        <p>Driver Address</p>
        <hr>

        <p class="error-message text-center" v-if="message != null">{{ message }}</p>
        <form class="form" @submit.prevent="save">
          <div class="form-group">
            <label>City</label>
            <select class="form-control" v-model="address.cityId" required>
              <option value="">Select City</option>
              <option v-for="city in cities" v-bind:key="city.id" v-bind:value="city.id">{{ city.name }}</option>
            </select>
          </div>
            
          <div class="form-group">
            <label>Physical Address</label>
            <textarea rows="5" class="form-control" v-model="address.physicalAddress" minlength="5" maxlength="250" placeholder="Enter physical address" required></textarea>
          </div>

          <div class="form-group">
              <input type="checkbox" v-model="address.sameAsPostalAddress"/> Tick here if the address is the same as postal address
          </div>
          <br/>

          <div class="row">
            <div class="col-md-10"></div>
            <div class="col-md-2">
              <button class="btn btn-success btn-block pull-right" type="submit">Submit</button>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script type="text/javascript">
  import DriverService from "@/services/DriverService.js";
  import CityService from "@/services/CityService.js";
  import loading from "vue-full-loading";
  import Driver from "@/models/Driver.js";

  export default {
    components: {
      loading
    },

    name: "NewDriverAddress",
    data: function() {
      return {
        show: false,
        label: "Processing request",
        cities: [],
        errorMessage: null,
        errors: null,
        address: {
          zipCodeId: null,
          countryId: null,
          provinceId: null,
          cityId: null,
          areaId: null,
          physicalAddress: null,
          sameAsPostalAddress: false
        },
        driver: new Driver,
        message: null
      };
    },

    computed: {
      getId() {
        return this.$route.params.id;
      }
    },

    mounted() {
      this.findAllCities();
    },

    methods: {
      findAllCities: function() {
        this.show = true;
        CityService.findAll().then(response => {
          this.cities = response.data.body;
          this.show = false;
        }).catch(error => {
          console.log(error);
          this.show = false;
        });
      },

      save: function() {
        this.show = true;
        this.driver.id = this.getId;
        this.driver.address = this.address;
        DriverService.save(this.driver).then(response => {
          if (response.data.statusCode === 201) {
            if (this.address.sameAsPostalAddress) {
              this.$router.push({ name: "driver-list", params: { id: this.getId }});
            }else{
              this.$router.push({ name: "new-driver-postal-address", params: { id: this.getId }});
            }
          }
          this.show = false;
        }).catch(error => {
          if (error.response.status === 500) {
            this.message = error.response.data.message;
          }
          this.show = false;
        });
      }
    }
  };
</script>
