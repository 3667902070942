import { GENERIC_SERVICE_URL } from "@/config/GenericServiceConfig.js";
import axios from "axios";

class TrailerService {
    findAll() {
        return axios.get(GENERIC_SERVICE_URL + "trailers");
    }

    findById(id) {
        return axios.get(GENERIC_SERVICE_URL + "trailers/" + id);
    }

    save(trailer) {
        return axios.post(GENERIC_SERVICE_URL + "trailers", {
            id: trailer.id,
            carrierUsername: trailer.carrierUsername,
            trailerNumber: trailer.trailerNumber,
            registrationNumber: trailer.registrationNumber,
            type: trailer.type,
            inServiceDate: trailer.inServiceDate,
            make: trailer.make,
            model: trailer.model,
            year: trailer.year,
            ownershipType: trailer.ownershipType,
            regularPickUpAddress: trailer.regularPickUpAddress,
            regularDropOffAddress: trailer.regularDropOffAddress,
            recurringType: trailer.recurringType,
            desiredRate: trailer.desiredRate,
            currency: trailer.currency,
            desiredRateMeasurement: trailer.desiredRateMeasurement,
            estimatedDeliveryTimeMeasurement: trailer.estimatedDeliveryTimeMeasurement,
            length: trailer.length,
            lengthMeasurement: trailer.lengthMeasurement,
            maxLoadSize: trailer.maxLoadSize,
            maxLoadSizeMeasurement: trailer.maxLoadSizeMeasurement,
            grossVehicleMass: trailer.grossVehicleMass,
            grossVehicleMassMeasurement: trailer.grossVehicleMassMeasurement,
            netVehicleMass: trailer.netVehicleMass,
            netVehicleMassMeasurement: trailer.netVehicleMassMeasurement,
            maxHeightClearance: trailer.maxHeightClearance,
            maxHeightClearanceMeasurement: trailer.maxHeightClearanceMeasurement,
            loadMode: trailer.loadMode,
            vehicleIdentificationNumber: trailer.vehicleIdentificationNumber,
            description: trailer.description
        });
    }

    findByTrailerNumber(trailerNumber) {
        return axios.get(GENERIC_SERVICE_URL + "trailers/findByTrailerNumber/" + trailerNumber);
    }
}
export default new TrailerService;
