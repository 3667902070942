 <template>
  <div>
    <ul class="list-group" v-if="attachments.length !== 0">
      <li class="list-group-item" v-for="(attachment, index) in attachments" :key="attachment.id">
        <span v-if="ENV === 'development' || ENV === 'test'">
          <a :href="PDF_API_URL + 'pdf/attachments/' + attachment.id" target="_blank">
            {{ index + 1 }}. View {{ attachment.fileAlternativeText }}
          </a>
        </span>
        <span v-if="ENV === 'production'">
          <a :href="AWS_S3_BUCKET + '/' + attachment.fileName" target="_blank">
            {{ index + 1 }}. View {{ attachment.fileName }}
          </a>
        </span>
      </li>
    </ul>
  </div>
</template>

<script type="text/javascript">
  import { PDF_API_URL } from "@/config/PdfServiceConfig.js";

  export default {
    name: "BusinessDocumentsComponent",
    props: {
      companyId: {
        type: Number,
        default: null
      },

      attachments: {
        type: Array,
        default: function() {
          return [];
        }
      }
    },

    data: function() {
      return {
        PDF_API_URL: PDF_API_URL,
        AWS_S3_BUCKET: process.env.VUE_APP_AWS_BUSINESS_DOCUMENTS_S3_BUCKET,
        ENV: process.env.NODE_ENV
      };
    },

    created: function() {}
  };
</script>
