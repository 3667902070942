import { GENERIC_SERVICE_URL } from "@/config/GenericServiceConfig";
import axios from "axios";

class InvoiceService {
  findAll() {
    return axios.get(GENERIC_SERVICE_URL + "invoices");
  }

  findById(id) {
    return axios.get(GENERIC_SERVICE_URL + "invoices/" + id);
  }

  save(invoice) {
    return axios.post(GENERIC_SERVICE_URL + "invoices", {
      name: invoice.name,
      dueDate: invoice.dueDate,
      invoiceItems: invoice.invoiceItems,
      extraNotes: invoice.extraNotes,
      taxPercentage: invoice.taxPercentage,
      discountPercentage: invoice.discountPercentage,
      invoiceType: invoice.invoiceType,
      invoiceStatus: invoice.invoiceStatus,
      academicTerm: invoice.academicTerm,
      academicYear: invoice.academicYear,
      grades: invoice.grades
    });
  }

  findByInvoiceNumber(invoiceNumber) {
    return axios.get(GENERIC_SERVICE_URL + "invoices/findByInvoiceNumber/" + invoiceNumber);
  }
}

export default new InvoiceService;
