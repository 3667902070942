<template>
  <div>
    <loading :show="show" :label="label">
      <div class="processing-row" slot="loading-container">
        <div class="text-center">
          <div slot="loading-spinner">
            <img src="@/assets/images/station-loading.gif" height="100" width="100"/>
          </div>
          <p class="text-center" slot="loading-text">{{ label }}</p>
        </div>
      </div>
    </loading>

    <div v-if="company !== null">
      <div class="panel" v-show="company.companyType === 'CARRIER'">
        <div class="panel-body">
          <h4>Post Your Trailer</h4>
          <p>Post your information so brokers and shippers can find you and give you loads!</p>
          <span v-if="company.carrierType === 'OWN_OPERATOR'">
            <span v-if="company.trucks.length < 1">
              <router-link to="trucks/new" replace>
                <button class="btn btn-success pull-right">New Trailer <span class="glyphicon glyphicon-plus"></span></button>
              </router-link>
            </span>
          </span>
          <span v-if="company.carrierType === 'FLEET_OWNER'">
            <router-link to="trucks/new" replace>
              <button class="btn btn-success pull-right">New Trailer <span class="glyphicon glyphicon-plus"></span></button>
            </router-link>
          </span>
          </div>
        </div>
    </div>

    <h4>Trailers list</h4>
    <div class="panel">
      <div class="panel-body">
        <form class="form">
          <div class="row">
            <div class="col-md-8">
              <div class="form-group">
                <label>&nbsp;</label>
                <input type="text" name="search" class="form-control" placeholder="Search here..."/>
              </div>
            </div>
            <div class="col-md-2">
              <div class="form-group">
                <label>&nbsp;</label><br />
                <button class="btn btn-success btn-block" type="button">Filter <span class="glyphicon glyphicon-filter"></span></button>
              </div>
            </div>

            <div class="col-md-2">
              <div class="form-group">
                <label>&nbsp;</label>
                <button class="btn btn-primary btn-block" type="button" @click="refresh">Refresh <span class="glyphicon glyphicon-refresh"></span></button>
              </div>
            </div>
          </div>
        </form>

        <table class="table table-responsive table-bordered" v-if="company !== null">
          <thead class="bg-primary">
            <th>ID</th>
            <th>Truck Number</th>
            <th>Registration Number</th>
            <th>Make</th>
            <th>Model</th>
            <th>Year</th>
            <th>VIN</th>
            <th>Current Driver</th>
            <th>Current Load</th>
            <th>Year</th>
            <th>Status</th>
            <th>Actions</th>
          </thead>
          <tbody>
            <tr v-for="truck in company.trucks" :key="truck.id">
              <td>{{ truck.id }}</td>
              <td>{{ truck.truckNumber }}</td>
              <td>{{ truck.vehicleRegistrationNumber }}</td>
              <td>{{ truck.trailerType }}</td>
              <td>{{ truck.maxLoadSize }}{{ truck.maxLoadSizeMeasurement }}</td>
              <td>
                <router-link :to="{ name: 'trucks/view', params: { truckNumber: truck.truckNumber }}">
                  <button class="btn btn-primary col-4">View <span class="glyphicon glyphicon-info-sign"></span></button>
                </router-link>
              </td>
            </tr>
          </tbody>
        </table>

        <ul class="pagination">
          <li><a href="#">Prev</a></li>
          <li class="active"><a href="#">1</a></li>
          <li><a href="#">2</a></li>
          <li><a href="#">Next</a></li>
        </ul>
      </div>
    </div>

  </div>
</template>

<script type="text/javascript">
  import loading from "vue-full-loading";
  import CompanyService from "@/services/CompanyService";

  export default {
    name: "CompanyList",
    components: {
      loading
    },

    data: function() {
      return {
        trucks: [],
        show: false,
        label: "Processing request",
        company: null
      };
    },

    created(){
      this.findByAssociatedUserUsername();
    },

    methods: {
      refresh: function(){
        this.findAll();
      },

      findByAssociatedUserUsername: function() {
        this.show = true;
        CompanyService.findByAssociatedUserUsername(this.$store.state.auth.user.user.username).then(response => {
          this.company = response.data.body;
          this.show = false;
        }).catch(error => {
          console.log(error);
          this.show = false;
        });
      }
    }
  };
</script>