<template>
	<div>
		<div class="row">
			<div class="col-md-3">
				<router-link :to="{ name: '' }" @click.native="setSelectedComponent('INVOICE')">
					<div class="panel">
						<div class="panel-body">
							<h4 class="pull-right"><span class="label label-default">1</span></h4>
							<p class="text-center">
								<img src="@/assets/images/bill.png" alt="Assigned Loads" height="25" width="25" class="img img-rounded img-responsive center-block"/><br>
								Invoices
							</p>					
						</div>
					</div>
				</router-link>
			</div>
			<div class="col-md-3">
				<router-link :to="{ name: '' }" @click.native="setSelectedComponent('WITHHOLDING')">
					<div class="panel">
						<div class="panel-body">
							<h4 class="pull-right"><span class="label label-primary">1</span></h4>
							<p class="text-center">
								<img src="@/assets/images/payments.png" alt="Assigned Loads" height="25" width="25" class="img img-rounded img-responsive center-block"/><br>
								Witholding
							</p>					
						</div>
					</div>
				</router-link>
			</div>
			<div class="col-md-3">
				<router-link :to="{ name: '' }" @click.native="setSelectedComponent('PAID')">
					<div class="panel">
						<div class="panel-body">
							<h4 class="pull-right"><span class="label label-warning">1</span></h4>
							<p class="text-center">
								<img src="@/assets/images/payments.png" alt="Assigned Loads" height="25" width="25" class="img img-rounded img-responsive center-block"/><br>
								PAID
							</p>					
						</div>
					</div>
				</router-link>
			</div>
			<div class="col-md-3">
				<router-link :to="{ name: '' }" @click.native="setSelectedComponent('OUTSTANDING')">
					<div class="panel">
						<div class="panel-body">
							<h4 class="pull-right"><span class="label label-success">1</span></h4>
							<p class="text-center">
								<img src="@/assets/images/payments.png" alt="Assigned Loads" height="25" width="25" class="img img-rounded img-responsive center-block"/><br>
								Outstanding/Disputed
							</p>					
						</div>
					</div>
				</router-link>
			</div>
		</div>

	</div>
</template>

<script type="text/javascript">

	export default{
		data: function(){
			return {
				selectedComponent: JSON.parse(localStorage.getItem('selectedComponent'))
			}
		},

		components: {
			
		},

		methods: {
			setSelectedComponent(selectedComponent){
				this.selectedComponent = selectedComponent;
				localStorage.setItem('selectedComponent', JSON.stringify(selectedComponent));
			}
		}
	}
</script>