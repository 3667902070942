<template>
  <div>
    <loading :show="show" :label="label">
      <div class="processing-row" slot="loading-container">
        <div class="text-center">
          <div slot="loading-spinner">
            <img src="@/assets/images/station-loading.gif" height="100" width="100"/>
          </div>
          <p class="text-center" slot="loading-text">{{ label }}</p>
        </div>
      </div>
    </loading>

    <div class="panel">
      <div class="panel-body">
        <p class="error-message" v-if="errorMessage !== null">{{ errorMessage }}</p>
        <form class="form" @submit.prevent="save">
          <h4>Primary Applicant Details</h4>
          <br/>

          <div class="form-group">
            <label for="organizationType">Company Type <span class="text-danger">* </span></label>
            <select v-model="company.companyType" id="organizationType" class="form-control" required>
              <option value="" disabled>Select option</option>
              <option value="LENDING_INSTITUTION">LENDING_INSTITUTION</option>
              <option value="REINSURER">REINSURER</option>
            </select>
          </div>

          <div class="form-group">
            <label for="organizationType">Business Organization Type <span class="text-danger">* </span></label>
            <select v-model="company.organizationType" id="organizationType" class="form-control" required>
              <option value="" disabled>Select Organization Type</option>
              <option value="PRIVATE_LIMITED">PRIVATE_LIMITED</option>
              <option value="SOLE_TRADER">SOLE_TRADER</option>
              <option value="PARTNERSHIP">PARTNERSHIP</option>
              <option value="CORPORATION">CORPORATION</option>
              <option value="PRIVATE_BUSINESS_CCORPORATION">PRIVATE_BUSINESS_CCORPORATION</option>
            </select>
          </div>

          <div class="form-group">
            <label for="name"
              >Applicant Name <span class="text-danger">* </span></label
            >
            <input
              type="text"
              v-model="company.name"
              id="name"
              class="form-control border-info"
              placeholder="Enter Applicant Name"
              required
            />
            <div class="error-message">
              <span v-for="error in errors" v-bind:key="error.name">{{
                error.name
              }}</span>
            </div>
          </div>

          <div class="form-group" v-show="company.organizationType !=='SOLE_TRADER' ">
            <label for="tradingName">Trading Name</label>
            <input
              type="text"
              v-model="company.tradingName"
              id="tradingName"
              class="form-control"
              placeholder="Enter Trading Name e.g ABC or XYZ etc"
            />
            <div class="error-message">
              <span v-for="error in errors" v-bind:key="error.tradingName">{{
                error.tradingName
              }}</span>
            </div>
          </div>

          <div class="form-group" v-show="company.organizationType !=='SOLE_TRADER' ">
            <label for="shortName"
              >Business Partner Number(Zimra BP)
              <span class="text-danger">*</span></label
            >
            <input
              type="text"
              v-model="company.businessPartnerNumber"
              id="businessPartnerNumber"
              class="form-control border-info"
              placeholder="Enter Business Partner Number"
            />
            <div class="error-message">
              <span
                v-for="error in errors"
                v-bind:key="error.businessPartnerNumber"
                >{{ error.businessPartnerNumber }}</span
              >
            </div>
          </div>

          <button class="btn btn-success btn-sm pull-right" type="button" @click="addPhoneNumberField">Add another phone number</button><br>
          <div v-if="phoneNumberFields.length > 0">
            <div v-for="(phoneNumberItem, phoneNumberIndex) in phoneNumberFields" :key="phoneNumberItem.id">
              <div class="form-group">
                <label for="phoneNumbers">Phone Number</label>
                <input
                  type="number"
                  v-model="phoneNumberItem.phoneNumber"
                  id="phoneNumber"
                  class="form-control border-info"
                  placeholder="Enter Phone Number"/>
              </div>

              <div class="form-group" v-show="phoneNumberFields.length > 1">
                <label>&nbsp;</label>
                <button class="btn btn-danger btn-sm pull-right" type="button" @click="phoneNumberFields.splice(phoneNumberIndex, 1)">Remove phone number</button><br>
              </div>
            </div>
          </div>

          <div class="form-group">
            <label for="email"
              >Email <span class="text-danger">* </span></label
            >
            <input
              type="text"
              v-model="company.email"
              id="email"
              class="form-control border-info"
              placeholder="Enter Business Email"
              required
            />
            <div class="error-message">
              <span v-for="error in errors" v-bind:key="error.email">{{
                error.email
              }}</span>
            </div>
          </div>

          <div class="form-group" v-show="company.organizationType !=='SOLE_TRADER' ">
            <label for="faxNumber">Fax</label>
            <input
              type="text"
              v-model="company.faxNumber"
              id="faxNumber"
              class="form-control"
              placeholder="Enter fax Number"
            />
            <div class="error-message">
              <span v-for="error in errors" v-bind:key="error.faxNumber">{{
                error.faxNumber
              }}</span>
            </div>
          </div>

          <div class="form-group" v-show="company.organizationType !=='SOLE_TRADER' ">
            <label for="websiteUrl">Website Url</label>
            <input
              type="text"
              v-model="company.websiteUrl"
              id="websiteUrl"
              class="form-control"
              placeholder="Enter Company Website Url"
            />
            <div class="error-message">
              <span v-for="error in errors" v-bind:key="error.websiteUrl">{{
                error.websiteUrl
              }}</span>
            </div>
          </div>

          <div class="form-group" v-show="company.organizationType !=='SOLE_TRADER' ">
            <label for="businessSector"
              >Business Sector <span class="text-danger">* </span></label
            >
            <select
              v-model="company.businessSectorId"
              id="businessSector"
              class="form-control border-info"
              v-on:change="findByBusinessSectorId"
            >
              <option value="" disabled>Select Business Sector</option>
              <option
                v-for="businessSector in businessSectors"
                v-bind:key="businessSector.id"
                :value="businessSector.id"
                >{{ businessSector.name }}</option
              >
            </select>
            <div class="error-message">
              <span
                v-for="error in errors"
                v-bind:key="error.businessSector"
                >{{ error.businessSector }}</span
              >
            </div>
          </div>

          <div class="form-group" v-show="company.organizationType !=='SOLE_TRADER' ">
            <label for="businessCategory"
              >Business Category <span class="text-danger">* </span></label
            >
            <select
              v-model="businessCategories"
              id="businessCategory"
              class="form-control border-info"
              multiple
            >
              <option value="" disabled>Select Business Category</option>
              <option
                v-for="businessCategory in businessCategoryList"
                v-bind:key="businessCategory.id"
                :value="businessCategory.id"
                >{{ businessCategory.name }}</option
              >
            </select>
            <div class="error-message">
              <span
                v-for="error in errors"
                v-bind:key="error.businessCategory"
                >{{ error.businessCategory }}</span
              >
            </div>
          </div>

          <div class="form-group" v-show="company.organizationType !=='SOLE_TRADER' ">
            <label for="numberOfEmployees"
              >Current Number Of Employees
              <span class="text-danger">* </span></label
            >
            <input
              type="number"
              v-model="company.numberOfEmployees"
              id="numberOfEmployees"
              class="form-control border-info"
              placeholder="Enter Current Number of Employees"
            />
            <div class="error-message">
              <span
                v-for="error in errors"
                v-bind:key="error.numberOfEmployees"
                >{{ error.numberOfEmployees }}</span
              >
            </div>
          </div>

          <div class="form-group">
            <label for="establishedYear"
              >Year of Establishment <span class="text-danger">* </span></label
            >
            <select
              class="form-control border-info"
              v-model="company.establishedYear"
            >
              <option value="" disabled>Select established Year</option>
              <option v-for="year in years" :value="year" :key="year">{{
                year
              }}</option>
            </select>
            <div class="error-message">
              <span
                v-for="error in errors"
                v-bind:key="error.establishedYear"
                >{{ error.establishedYear }}</span
              >
            </div>
          </div>

          <div class="row">
            <div class="col-md-6"></div>
            <div class="col-md-6">
              <div class="form-group">
                <label>&nbsp;</label>
                <button
                  class="btn btn-success btn-block pull-right"
                  type="sumbit"
                >
                  Submit & Save
                </button>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script type="text/javascript">
import BusinessSectorService from "@/services/BusinessSectorService.js";
import BusinessCategoryService from "@/services/BusinessCategoryService.js";
import CompanyService from "@/services/CompanyService.js";
import Company from "@/models/Company.js";
import loading from "vue-full-loading";

export default {
  name: "NewProposal",
  components: {
    loading
  },
  data: function() {
    return {
      businessSectors: [],
      businessCategoryList: [],
      businessSector: null,
      businessCategory: null,
      company: new Company(),
      show: false,
      label: "Processing...",
      errors: null,
      companyId: null,
      businessCategories: [],
      errorMessage: null,
      phoneNumberFields: [{
        zipCodeId: null,
        phoneNumber: null
      }]
    };
  },

  created() {
    this.getAllBusinessSectors();
  },

  methods: {
    addPhoneNumberField: function(){
      this.phoneNumberFields.push({
        zipCodeId: null,
        phoneNumber: null
      });
    },

    getAllBusinessSectors: function() {
      return BusinessSectorService.findAll()
        .then(response => {
          this.businessSectors = response.data.body;
        })
        .catch(error => {
          console.log(error);
        });
    },

    findByBusinessSectorId: function() {
      return BusinessCategoryService.findByBusinessSectorId(
        this.company.businessSectorId
      )
        .then(response => {
          this.businessCategoryList = response.data.body;
        })
        .catch(error => {
          console.log(error);
        });
    },

    save: function() {
      this.company.phoneNumbers = this.phoneNumberFields;
      this.company.businessCategories = this.businessCategories;
      var businessCategoriesList = [];
      for (var i = 0; i < this.company.businessCategories.length; i++) {
        businessCategoriesList.push({
          businessCategoryId: this.company.businessCategories[i]
        });
      }
      this.company.businessCategories = businessCategoriesList;

      this.showLoader();
      CompanyService.save(this.company)
        .then(response => {
          this.clearLoader();
          if (response.data.statusCode == 422) {
            this.setNotificationData(response);
          }
          if (response.data.statusCode === 400) {
            this.setNotificationData(response);
          }

          if (response.data.statusCode == 201) {
            this.companyId = response.data.body.id;
            this.clearNotificationData();
            this.$router.push({
              name: "companies/new/address",
              params: { companyId: this.companyId }
            });
          }
        })
        .catch(error => {
          console.log(error);
        });
    },

    // make these functions global :: reuse, via the app instance
    clearNotificationData: function() {
      this.errors = null;
      this.errorMessage = null;
    },

    setNotificationData: function(response) {
      this.errors = response.data.errors;
      this.errorMessage = response.data.message;
    },

    clearLoader: function() {
      this.show = false;
    },

    showLoader: function() {
      this.show = true;
    }
  },

  computed: {
    years: function() {
      const year = new Date().getFullYear();
      const years = Array.from(
        { length: year - 1900 },
        (value, index) => 1901 + index
      );
      return years;
    },

    getUser: function() {
      return JSON.parse(localStorage.getItem("user"));
    }
  }
};
</script>
