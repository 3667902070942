<template>
    <div>
      <loading :show="show" :label="label">
        <div class="processing-row" slot="loading-container">
          <div class="text-center">
            <div slot="loading-spinner">
              <img src="@/assets/images/station-loading.gif" height="100" width="100"/>
            </div>
            <p class="text-center" slot="loading-text">{{ label }}</p>
          </div>
        </div>
      </loading>
      
      <div v-if="truck !== null">
        <div class="panel">
          <div class="panel-body">
            <div v-if="truck.photos.length < VUE_APP_TRUCK_IMAGE_LIMIT">
              <p class="text-danger">Please ensure that you put pictures that outline the front, back, side views of the truck to get more chances of getting load for your next delivery offer!</p>
              <div class="alert alert-success" v-if="message !== null">
                  <h4 class="text-danger">{{ message }}</h4>
              </div>
              <div>
                  <h4>Choose image (can upload multiple files or select one by one)</h4><br>
                  <input type="file" multiple class="form-control" accept="image/png, image/jpeg, image/jpg" @change="handleFileUpload" ref="photosRef" required>
              </div><br>
              <div v-if="images">
                  <div class="row">
                      <div class="col-md-2" v-for="(image, index) in images" :key="index">
                          <img :src="image.previewUrl" class="img img-responsive img-rounded img-upload"/>
                          <br>
                          <button class="btn btn-danger btn-sm" @click="removeImage(index)">Remove image</button>
                          <br><br>
                      </div>
                  </div>
              </div>
              <br><br><br>
              <span v-show="images.length > 0">
                <div class="row">
                  <div class="col-md-10"></div>
                  <div class="col-md-2">
                    <span v-if="images.length <= VUE_APP_TRUCK_IMAGE_LIMIT">
                      <button class="btn btn-primary btn-block" @click="save">Submit</button>
                    </span>
                  </div>
                </div>
              </span>
              <span v-if="images.length > VUE_APP_TRUCK_IMAGE_LIMIT">
                  <h4>Cannot submit images. Please remove some images. Max limit is {{ VUE_APP_TRUCK_IMAGE_LIMIT }}</h4>
              </span>
            </div>
            <div v-if="truck.photos.length >= VUE_APP_TRUCK_IMAGE_LIMIT">
              <p class="text-danger">Max number of images for this truck already reached</p>
            </div>
          </div>
      </div>
    </div>
  </div>
</template>

<script type="text/javascript">
  import axios from "axios";
  import { GENERIC_SERVICE_URL } from "@/config/GenericServiceConfig.js";
  import loading from "vue-full-loading";
  import TruckService from "@/services/TruckService";
  import ActiveProfileService from "@/services/ActiveProfileService";
  
  export default{
    components: {
      loading
    },

    data(){
      return {
        images: [],
        formData: new FormData,
        show: false,
        label: 'Processing request',
        message: null,
        maxFileSize: process.env.VUE_APP_MAX_FILE_SIZE_IMAGE_LIMIT,
        VUE_APP_TRUCK_IMAGE_LIMIT: process.env.VUE_APP_TRUCK_IMAGE_LIMIT,
        // PHOTO_UPLOAD_ENDPOINT: process.env.NODE_ENV === 'production' ? 'aws-file-endpoint/uploadTruckPhotos': 'trucks/uploadPhotos',
        PHOTO_UPLOAD_ENDPOINT: null,
        truck: null,
        VUE_APP_AWS_TRUCK_S3_BUCKET: process.env.VUE_APP_AWS_TRUCK_S3_BUCKET,
        activeProfile: null
      }
    },

    mounted(){
      this.findByTruckNumber();
      this.getActiveProfile();
    },

    computed: {
      getTruckNumber(){
        return this.$route.params.truckNumber;
      }
    },

  methods: {
    findByTruckNumber(){
      this.show = true;
      TruckService.findByTruckNumber(this.getTruckNumber).then(response => {
        this.truck = response.data.body;
        this.show = false;
      }).catch(error => {
        console.log(error);
      });
    },

    onFileChange(e) {
    
      var files = e.target.files || e.dataTransfer.files;
      if (!files.length) return;
    },

    createImage(files) {
      for (var index = 0; index < files.length; index++) {
        var reader = new FileReader();
        reader.onload = (e) => {
            this.images.push({
                file: files[index],
                previewUrl: e.target.result
              });
            };
        reader.readAsDataURL(files[index]);
      }
    },

    handleFileUpload(event) {
        if (this.images.length < this.VUE_APP_TRUCK_IMAGE_LIMIT) {
          const files = event.target.files;
          for (let i = 0; i < files.length; i++) {
            if (files[i].size / (1024 * 1024) > this.maxFileSize) {
              alert('File ' + files[i].name + ' exceeds size of ' + this.maxFileSize + 'MB');
              this.$refs.photosRef.value = null;
              return;
            }
            else{
              const reader = new FileReader();
              reader.onload = (e) => {
                this.images.push({
                  file: files[i],
                  previewUrl: e.target.result
                });
              };
              reader.readAsDataURL(files[i]);
            }
          }
        }else{
            alert('Max number of images reached.');
        }
        
    },

    removeImage(index) {
      this.images.splice(index, 1)
    },

    save(){
      this.show = true;
      if ((this.truck.photos.length + this.images.length) > this.VUE_APP_TRUCK_IMAGE_LIMIT) {
        alert('Max number of images for this truck already reached.');
        this.images = [];
        this.show = false;
        return;
      }else{
        for (var i = 0; i < this.images.length; i++) {
          this.formData.append('photos', this.images[i].file);
        }
        axios.post(GENERIC_SERVICE_URL + this.PHOTO_UPLOAD_ENDPOINT + "/" + this.getTruckNumber, this.formData).then(response => {
          console.log(response.data);
          if (response.status === 201) {
            this.message = response.data.body;
            this.formData = new FormData;
            this.$refs.photosRef.value = null;
            this.images = [];
            this.$router.push({ name: 'truck-list' });
          }
          this.show = false;
        }).catch(error => {
          console.log(error.response.status);
          this.show = false;
          if (error.response.status === 500) {
            this.message = error.response.data.message;
          }
          this.show = false;
        });
      }
    },

    getActiveProfile() {
      this.show = true;
      ActiveProfileService.getActiveProfile().then(response => {
        this.activeProfile = response.data.body;
        this.PHOTO_UPLOAD_ENDPOINT = (this.activeProfile === 'default' || this.activeProfile === 'test') ? 'trucks/uploadPhotos': 'aws-file-endpoint/uploadTruckPhotos';
        this.show = false;
      }).catch(error => {
        this.show = false;
        console.log(error);
      });
    },
  }
};

</script>

<style scoped>
  .img-upload {
    width: 100%;
    height: 200px;
    margin: auto;
    display: block;
    object-fit: cover;
  }
</style>