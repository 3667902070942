<template>
	<div>
		<loading :show="show" :label="label">
			<div class="processing-row" slot="loading-container">
				<div class="text-center">
					<div slot="loading-spinner">
						<img src="@/assets/images/station-loading.gif" height="100" width="100"/>
					</div>
					<p class="text-center" slot="loading-text">{{ label }}</p>
				</div>
			</div>
	    </loading>
	</div>
</template>

<script type="text/javascript">
	import SubscriptionService from "@/services/SubscriptionService";
	import loading from "vue-full-loading";

	export default{
		components: {
			loading,
    	},

		data: function(){
			return {
				otpCode: null,
				message: null,
				label: 'Processing request',
				show: false
			}
		},

		mounted(){
			if (!this.getRoles.includes('SUPER_ADMIN') || !this.getRoles.includes('ADMIN')) {
				this.subscriptionExpired();
			}else{
				this.$router.push({ name: 'dashboard'});
			}
		},

		computed: {
			getUser() {
				return this.$store.state.auth.user.user;
			},

			loggedIn() {
		        return this.$store.state.auth.status.loggedIn;
		    },
		    getRoles: function() {
		    	let roles = [];
		    	for (var i = 0; i < this.getUser.authorities.length; i++) {
		    		roles.push(this.getUser.authorities[i].authority);
		    	}
		    	return roles;
		    }
		},

		methods: {
			subscriptionExpired: function(){
		        this.show = true;
		        SubscriptionService.subscriptionExpired(this.getUser.username).then(response => {
		          this.isSubscriptionExpired = response.data.body;
		          console.log(this.isSubscriptionExpired);
		          if (this.isSubscriptionExpired) {
		            this.$router.push({ name: 'subscription-expired'});
		          }else{
		          	this.$router.push({ name: 'dashboard'});
		          }
		          this.show = false;
		        }).catch(error => {
		          if (error.response.status === 500) {
		            this.message = error.response.data.message;
		          }
		          this.show = false;
		        });
		    }
		}
	};
</script>