export default class Load{
	constructor(id, loadType, loadNumber, loadMode, expiryDate, pickupAddress, destinationAddress, pickupDate, pickupTime,
		dropOffAddresses, dropOffDate, dropOffTime, estimatedDistance,  estimatedDistanceMeasurement, estimatedLoadingTime,
		estimatedLoadingTimeMeasurement, estimatedDeliveryTime, estimatedDeliveryTimeMeasurement, trailerTypeRequired, 
		commodity, weight, weightMeasurement, price, currency, carrierId, marketAveragePrice, estimatedDrivingTime,
		estimatedDrivingTimeMeasurement, owner, bookNowEnabled, collectLoadOffers, contactInformationForCarrier, 
		copyFromMyAccount, setToPrivateLoad, payment, status, deliveryComments, assignedTruckNumber){
		this.id = id;
		this.loadType = loadType;
		this.loadNumber = loadNumber;
		this.loadMode = loadMode;
		this.expiryDate = expiryDate;
		this.pickupAddress = pickupAddress;
		this.destinationAddress = destinationAddress;
		this.pickupDate = pickupDate;
		this.pickupTime = pickupTime;
		this.dropOffAddresses = dropOffAddresses;
		this.dropOffDate = dropOffDate;
		this.dropOffTime = dropOffTime;
		this.estimatedDistance = estimatedDistance;
		this.estimatedDistanceMeasurement = estimatedDistanceMeasurement;
		this.estimatedLoadingTime = estimatedLoadingTime;
		this.estimatedLoadingTimeMeasurement = estimatedLoadingTimeMeasurement;
		this.estimatedDeliveryTime = estimatedDeliveryTime;
		this.estimatedDeliveryTimeMeasurement = estimatedDeliveryTimeMeasurement;
		this.trailerTypeRequired = trailerTypeRequired;
		this.commodity = commodity;
		this.weight = weight;
		this.weightMeasurement = weightMeasurement;
		this.price = price;
		this.currency = currency;
		this.carrierId = carrierId;
		this.marketAveragePrice = marketAveragePrice;
		this.estimatedDrivingTime = estimatedDrivingTime;
		this.estimatedDrivingTimeMeasurement = estimatedDrivingTimeMeasurement;
		this.owner = owner;
		this.bookNowEnabled = bookNowEnabled;
		this.collectLoadOffers = collectLoadOffers;
		this.contactInformationForCarrier = contactInformationForCarrier;
		this.copyFromMyAccount = copyFromMyAccount;
		this.setToPrivateLoad = setToPrivateLoad;
		this.payment = payment;
		this.status = status;
		this.deliveryComments = deliveryComments;
		this.assignedTruckNumber = assignedTruckNumber;
	}
}