<template>
  <div>
    <router-link :to="{ name: 'companies' }">
      <button class="btn btn-primary">Back</button>
    </router-link><br>

    <h4 class="text-center">Applicant details for {{ company.name }}</h4>

    <ul class="nav nav-pills">
      <li class="nav-item active">
        <a href="#basicDetails" class="nav-link active" data-toggle="pill">Primary Details</a
        >
      </li>

      <li class="nav-item">
        <a href="#addressDetails" class="nav-link" data-toggle="pill">
          Address Details
        </a>
      </li>

      <li class="nav-item">
        <a href="#postalAddress" class="nav-link" data-toggle="pill"
          >Postal Details</a
        >
      </li>

      <li class="nav-item">
        <a href="#contactPersonDetails" class="nav-link" data-toggle="pill"
          >Contact People</a
        >
      </li>

      <li class="nav-item">
        <a
          href="#socialMediaAccountsDetails"
          class="nav-link"
          data-toggle="pill"
          >Social Media Accounts</a
        >
      </li>

      <li class="nav-item">
        <a href="#directorDetails" class="nav-link" data-toggle="pill"
          >Directors</a
        >
      </li>

      <li class="nav-item">
        <a href="#shareholderDetails" class="nav-link" data-toggle="pill"
          >Shareholders</a
        >
      </li>

      <li class="nav-item">
        <a href="#attachmentDetails" class="nav-link" data-toggle="pill"
          >Attachments</a
        >
      </li>
    </ul>
    <br />

    <div class="tab-content">
      <div class="tab-pane container active" id="basicDetails">
        <basic-details-component
          v-bind:companyId="company.id"
          v-bind:name="company.name"
          v-bind:tradingName="company.tradingName"
          v-bind:phoneNumbers="company.phoneNumbers"
          v-bind:email="company.email"
          v-bind:faxNumber="company.faxNumber"
          v-bind:websiteUrl="company.websiteUrl"
          v-bind:description="company.description"
          v-bind:status="company.status"
          v-bind:createdAt="company.createdAt"
          :businessSector="company.businessSector"
          :businessCategories="company.businessCategories"
          :organizationType="company.organizationType"
          :numberOfEmployees="company.numberOfEmployees"
          :dateJoinedLendingInstitution="company.dateJoinedLendingInstitution"/>
      </div>

      <div class="tab-pane container" id="addressDetails">
        <address-component :physicalAddress="company.physicalAddress"/>
      </div>

      <div class="tab-pane container" id="postalAddress">
        <postal-address-component :postalAddress="company.postalAddress" />
      </div>

      <div class="tab-pane container" id="contactPersonDetails">
        <contact-people-component
          :contactPeople="company.contactPeople"
        />
      </div>

      <div class="tab-pane container" id="socialMediaAccountsDetails">
        <social-media-accounts-component
          :socialMediaAccounts="company.socialMediaAccounts"
        />
      </div>

      <div class="tab-pane container" id="directorDetails">
        <directors-component :directors="company.directors" />
      </div>

      <div class="tab-pane container" id="shareholderDetails">
        <shareholders-component :shareholders="company.shareholders" />
      </div>

      <div class="tab-pane container" id="attachmentDetails">
        <attachments-component
          :companyId="company.id"
          :attachments="company.attachments"/>
      </div>
    </div>
  </div>
</template>

<script type="text/javascript">
import CompanyService from "@/services/CompanyService";
import BasicDetailsComponent from "@/components/companies/BasicDetailsComponent.vue";
import AddressComponent from "@/components/companies/AddressComponent.vue";
import PostalAddressComponent from "@/components/companies/PostalAddressComponent.vue";
import ContactPeopleComponent from "@/components/companies/ContactPeopleComponent.vue";
import SocialMediaAccountsComponent from "@/components/companies/SocialMediaAccountsComponent.vue";
import DirectorsComponent from "@/components/companies/DirectorsComponent.vue";
import ShareholdersComponent from "@/components/companies/ShareholdersComponent.vue";
import AttachmentsComponent from "@/components/companies/AttachmentsComponent.vue";

export default {
  name: "ViewCompany",
  components: {
    BasicDetailsComponent,
    AddressComponent,
    PostalAddressComponent,
    ContactPeopleComponent,
    SocialMediaAccountsComponent,
    DirectorsComponent,
    ShareholdersComponent,
    AttachmentsComponent
  },

  data: function() {
    return {
      company: {
        type: Object,
        default: null
      },
      companyDetailsShow: false,
      companyLocationShow: false,
      companyPostalAddressShow: false,
      contactPersonShow: false,
      companyStampShow: false,
      socialMediaAccountsShow: false,
      directorsShow: false,
      shareholdersShow: false,
      financialHistoriesShow: false,
      otherCreditFacilitiesShow: false,
      attachmentsShow: false,
      branchesShow: false
    };
  },

  computed: {
    getCompanyId() {
      return this.$route.params.companyId;
    }
  },

  created() {
    this.findById();
  },

  methods: {
    findById: function() {
      CompanyService.findById(this.getCompanyId)
        .then(response => {
          if (response.data.statusCode == 200) {
            this.company = response.data.body;
            console.log(this.company);
          }
        })
        .catch(error => {
          console.log(error);
        });
    }
  }
};
</script>
