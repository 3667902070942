<template>
	<div>
		<loading :show="show" :label="label">
			<div class="processing-row" slot="loading-container">
				<div class="text-center">
					<div slot="loading-spinner">
						<img src="@/assets/images/station-loading.gif" height="100" width="100"/>
					</div>
					<p class="text-center" slot="loading-text">{{ label }}</p>
				</div>
			</div>
		</loading>

		<div class="panel">
			<div class="panel-body">
				<h4>Downgrade Subscription</h4>
				<hr>
				<p class="text-danger" v-if="message !== null">{{ message }}</p>
				<br>
				<h4 class="text-danger">Downgrade to your subscription plan. This will affect some of your services in FreightBoard and you may lose access to other service features!</h4>
			    <p><i>Based on tiered pricing</i></p>
			    <div class="row">
			      <div class="col-md-4" v-for="subscriptionPlan in subscriptionPlans" :key="subscriptionPlan.id">
			         <ul class="list-group">
		              <li class="list-group-item"><h4 class="text-center">{{ subscriptionPlan.name }}</h4></li>
		              <li class="list-group-item">{{ subscriptionPlan.identifier }}</li>
		              <li class="list-group-item">{{ subscriptionPlan.currency }}${{ subscriptionPlan.price }} / month</li>
		              <li class="list-group-item">{{ subscriptionPlan.subscriptionType }}</li>
		              <li class="list-group-item">
		                <h4>{{ subscriptionPlan.subscriptionType !== 'BASIC' ? 'All Basic Plan features +': ''}}</h4>
		                <ul class="list-group" v-for="subscriptionPlanFeature in subscriptionPlan.subscriptionPlanFeatures" :key="subscriptionPlanFeature.id">
		                  <li class="list-group-item">{{ subscriptionPlanFeature.name }}</li>
		                </ul>
		              </li>
		              <br>             
		              <button :class="getSelectedSubscriptionPlanNumber === subscriptionPlan.subscriptionPlanNumber ? 'btn btn-success btn-block': 'btn btn-primary btn-block'" type="submit" @click="setSelectedSubscriptionPlanNumber(subscriptionPlan.subscriptionPlanNumber, subscriptionPlan.price, subscriptionPlan.currency)">
		              <h4 class="text-center">{{ getSelectedSubscriptionPlanNumber === subscriptionPlan.subscriptionPlanNumber ? 'Selected': 'Subscribe' }}</h4>
		              </button>
		            </ul>
			      </div>
			    </div>

			    <span v-show="getSelectedSubscriptionPlanNumber !== null">
			        <form class="form text-center" @submit.prevent="downgrade">
			          <button class="btn btn-primary pull-right" type="submit">Proceed to Payment</button>
			        </form>
			    </span>
			</div>
		</div>
	</div>
</template>

<script type="text/javascript">
  import loading from "vue-full-loading";
  import SubscriptionPlanService from "@/services/SubscriptionPlanService.js";
  import PaymentService from "@/services/PaymentService.js";
  import SubscriptionService from "@/services/SubscriptionService";

  export default {
    name: "NewSubscription",
    components: {
      loading
    },
    data: function() {
      return {
        show: false,
        label: "Processing Subscription",
        errors: null,
        companyId: null,
        zipCodes: [],
        errorMessage: null,
        phoneNumberFields: [{
          zipCodeId: null,
          phoneNumber: null
        }],
        maxLength: 9,
        companyType: JSON.parse(localStorage.getItem("companyType")),
        username: JSON.parse(localStorage.getItem("username")),
        subscriptionPlan: null,
        subscriptionPlans: [],
        selectedSubscriptionPlanNumber: this.$store.state.selectedSubscriptionPlanNumber,
        message: null
      };
    },

    created() {
      console.log(JSON.parse(localStorage.getItem('companyType')));
      this.findAll();
    },

    computed: {
    	getUuid(){
    		return this.$route.params.uuid;
    	},

      	getSelectedSubscriptionPlanNumber(){
        	return this.$store.state.selectedSubscriptionPlanNumber;
      	},

      	currentUser() {
	    	return this.$store.state.auth.user;
		},
    },

    methods: {
      setSelectedSubscriptionPlanNumber(subscriptionPlanNumber, price, currency){
        this.$store.commit('setSelectedPrice', price);
        this.$store.commit('setSelectedCurrency', currency);
        this.$store.commit('setSelectedSubscriptionPlanNumber', subscriptionPlanNumber);
      },

      findAll: function() {
        this.show = true;
        SubscriptionPlanService.findAll(this.companyType).then(response => {
          this.subscriptionPlans = response.data.body.filter(subscriptionPlan => {
            return subscriptionPlan.identifier === this.companyType;
          });
          this.show = false;
        }).catch(error => {
          console.log(error);
          this.show = false;
        });
      },

      downgrade: function(){
	        this.show = true;
	        SubscriptionService.downgrade(this.currentUser.user.username, this.getSelectedSubscriptionPlanNumber).then(response => {
	        	if (response.data.statusCode === 201) {
	        		console.log(response.data.body);
	        	}
	          this.show = false;
	        }).catch(error => {
	        	console.log(error);
	          if (error.response.status === 500) {
	            this.message = error.response.data.message;
	          }
	          this.show = false;
	        });
	  },

      getRedirectUrl: function(){
        this.show = true;
        var price;
        var currency;
        var subscriptionPlanNumber;
        localStorage.setItem("subscriptionPlanNumber", this.getSelectedSubscriptionPlanNumber);
        if (this.$store.state.selectedPrice !== null && this.$store.state.selectedCurrency && this.$store.state.selectedSubscriptionPlanNumber !== null) {
            price = this.$store.state.selectedPrice;
            currency = this.$store.state.selectedCurrency;
            subscriptionPlanNumber = this.$store.state.selectedSubscriptionPlanNumber;
        }else{
          alert('Please select a subscription plan');
          return;
        }
        console.log(subscriptionPlanNumber);
        let paymentPostDto = {
            uid: this.getUuid,
            payerUsername: this.username,
            amount: price,
            currency: currency,
            description: "Test Transaction TXN", // append subscriptionPlanNumber as description
            method: "ECOCASH",
            gateway: "PAYNOW",
            paymentType: "SUB"
        }
        PaymentService.getRedirectUrl(paymentPostDto).then(response => {
          if (response.status === 201) {
            if (response.data.body !== null) {
              window.location.replace(response.data.body);
            }
          }
          this.show = false;
        }).catch(error => {
          console.log(error);
          this.show = true;
        });
      },
    }
  };
</script>