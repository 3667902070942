export default class Company {
  constructor(id, uuid, companyType, name, tradingName, businessPartnerNumber, phoneNumbers, email, physicalAddress,
    postalAddress, associatedUser, rmtNumber, carrierType, customsAgentPermitNumber){
    this.id = id;
    this.uuid = uuid;
    this.companyType = companyType;
    this.name = name;
    this.tradingName = tradingName;
    this.businessPartnerNumber = businessPartnerNumber;
    this.phoneNumbers = phoneNumbers;
    this.email = email;
    this.physicalAddress = physicalAddress;
    this.postalAddress = postalAddress;
    this.associatedUser = associatedUser;
    this.rmtNumber = rmtNumber;
    this.carrierType = carrierType;
    this.customsAgentPermitNumber = customsAgentPermitNumber;
  }
}
