export default class Subscription {
  constructor(id, associatedUserUsername, subscriptionPlanNumber, subscriptionNumber, startDate, endDate){
    this.id = id;
    this.associatedUserUsername = associatedUserUsername;
    this.subscriptionPlanNumber = subscriptionPlanNumber;
    this.subscriptionNumber = subscriptionNumber;
    this.startDate = startDate;
    this.endDate = endDate;
  }
}
