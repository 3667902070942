import { GENERIC_SERVICE_URL } from "@/config/GenericServiceConfig";
import axios from "axios";

class BusinessSectorService {
  save(businessSector) {
    return axios.post(GENERIC_SERVICE_URL + "businessSectors", {
      businessSectorId: businessSector.businessSectorId,
      name: businessSector.name,
      description: businessSector.description,
      status: businessSector.status
    });
  }

  findAll() {
    return axios.get(GENERIC_SERVICE_URL + "businessSectors");
  }

  findById(entityId) {
    return axios.get(GENERIC_SERVICE_URL + "businessSectors" + "/" + entityId);
  }
}

export default new BusinessSectorService();
