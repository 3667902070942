<template>
	<div>
		<loading :show="show" :label="label">
	      <div class="processing-row" slot="loading-container">
	        <div class="text-center">
	          <div slot="loading-spinner">
	            <img src="@/assets/images/station-loading.gif" height="100" width="100"/>
	          </div>
	          <p class="text-center" slot="loading-text">{{ label }}</p>
	        </div>
	      </div>
	    </loading>

		<div class="panel">
			<div class="panel-body">
				<div class="row">
					<div class="col-md-2">
						<div class="form-group">
							<label>Trailer Type</label>
							<select class="form-control">
								<option value="" disabled>Select option</option>
								<option value="FLATBED">Flatbed</option>
								<option value="VAN">Van</option>
								<option value="REEFER">Reefer</option>
								<option value="STEP_DECK">Step Deck</option>
								<option value="POWER_ONLY">Power Only</option>
								<option value="AUTO_CARRIER">Auto Carrier</option>
								<option value="B_TRAIN">B Train</option>
								<option value="BOX_TRUCK">Box Truck</option>
								<option value="CONESTOGA">Consetoga</option>
								<option value="CONTAINERS">Containers</option>
								<option value="DOUBLE_DROP">Double Drop</option>
								<option value="DRY_BULK">Dry Bulk</option>
								<option value="DUMP_TRAILER">Dump Trailer</option>
								<option value="HOPPER_BOTTOM">Hooper Bottom</option>
								<option value="HOTSHOT">Hotshot</option>
								<option value="LOWBOY">Lowboy</option>
								<option value="TANKER">Tanker</option>
							</select>
						</div>
					</div>
					<div class="col-md-2">
						<div class="form-group">
							<label>Region</label>
							<select class="form-control">
								<option value="" disabled>Select option</option>
								<option v-for="province in provinces" :key="province.id">{{ province.name }}</option>
							</select>
						</div>
					</div>
					<div class="col-md-3"></div>
					<div class="col-md-3">
						<div class="btn btn-group">
							<button class="btn btn-primary" @click="changeLoadDensityType('list')">View as List</button>
							<button class="btn btn-success" @click="changeLoadDensityType('map')">View as Map</button>
						</div>
					</div>
				</div>
			</div>
		</div>

		<div class="panel">
			<div class="panel-body">
				<div class="row">
					<div class="col-md-3">
						<table class="table table-bordered table-hover table-responsive">
							<thead>
								<th>State</th>
								<th>Inbound</th>
								<th>Instrastate</th>
								<th>Out</th>
							</thead>
							<tbody>
								<tr v-for="load in loads" :key="load.id">
									<td>{{ load.destinationAddress !== null ? load.destinationAddress.province.name: '' }}</td>
									<td><span class="label label-warning">{{ loads.length }}</span></td>
									<td><span class="label label-primary">{{ loads.length }}</span></td>
									<td><span class="label label-default">{{ loads.length }}</span></td>
								</tr>
							</tbody>
						</table>
					</div>
					<div class="col-md-9">
						<span v-if="loadDensityType === 'list'">
							<ListLoadDensity :loads="loads"/>
						</span>
						
						<span v-if="loadDensityType === 'map' && markerCoordinates.length > 0"> <!-- wait for coordinates to fully load since map executes lazily! -->
							<MapLoadDensity :markerCoordinates="markerCoordinates"/>
						</span>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script type="text/javascript">
	import ListLoadDensity from "@/components/loadDensity/ListLoadDensity";
	import MapLoadDensity from "@/components/loadDensity/MapLoadDensity";
	import LoadService from "@/services/LoadService";
	import loading from "vue-full-loading";
	import ProvinceService from "@/services/ProvinceService";

	export default{
		components: {
			ListLoadDensity,
			MapLoadDensity,
			loading
		},

		data: function(){
			return {
				show: false,
				label: 'Processing request',
				loadDensityType: 'list',
				loads: [],
				markerCoordinates: [],
				provinces: []
			}
		},

		mounted(){
			this.getLoads();
			this.getProvinces();
		},

		methods: {
			changeLoadDensityType: function(type){
				this.loadDensityType = type;
			},

			getLoads: function(){
				this.show = true;
				LoadService.findAll().then(response => {
					this.loads = response.data.body.filter(load => {
						return load.status === 'ACTIVE' && load.carrier === null && load.payment === null;
					});
					for (var i = 0; i < this.loads.length; i++) {
						if(this.loads[i].pickupAddress !== null){
							this.markerCoordinates.push({
								latitude: this.loads[i].pickupAddress.latitude !== null ? this.loads[i].pickupAddress.latitude: 0,
								longitude: this.loads[i].pickupAddress.longitude !== null ? this.loads[i].pickupAddress.longitude: 0
							});
						}
					}
					this.show = false;
		        }).catch(error => {
		        	console.log(error);
		        });
			},

			getProvinces: function(){
				this.show = true;
				ProvinceService.findAll().then(response => {
					this.provinces = response.data.body;
					this.show = false;
		        }).catch(error => {
		        	console.log(error);
		        });
			}
		}
	}
</script>