import { GENERIC_SERVICE_URL } from "@/config/GenericServiceConfig.js";
import axios from "axios";

class AdditionalChargeService {
  findAll() {
    return axios.get(GENERIC_SERVICE_URL + "additionalCharges");
  }

  findById(id) {
    return axios.get(GENERIC_SERVICE_URL + "additionalCharges/" + id);
  }

  save(additionalCharge) {
    return axios.post(GENERIC_SERVICE_URL + "additionalCharges", {
      id: additionalCharge.id,
      narration: additionalCharge.narration,
      price: additionalCharge.price,
      currency: additionalCharge.currency,
      totalItems: additionalCharge.totalItems,
      associatedUserUsername: additionalCharge.associatedUserUsername
    });
  }
}
export default new AdditionalChargeService;