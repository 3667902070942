import AuthService from "@/services/AuthService.js";
import UserService from "@/services/UserService.js";

const user = JSON.parse(localStorage.getItem("user"));

const initialState = user
  ? { status: { loggedIn: true }, user }
  : { status: { loggedIn: false }, user: null };

export const auth = {
  namespaced: true,
  state: initialState,
  mutations: {
    loginSuccess(state, user) {
      state.status.loggedIn = true;
      state.user = user;
    },

    loginFailure(state) {
      state.status.loggedIn = false;
      state.user = null;
    },

    logout(state) {
      state.user = null;
    },

    registerSuccess(state) {
      state.status.loggedIn = true;
    },

    registerFailure(state) {
      state.status.loggedIn = false;
    }
  },

  actions: {
    login({ commit }, user) {
      return AuthService.login(user).then(response => {
        commit("loginSuccess", response.data);
        // set user access token
        if (response.data.access_token) {
          localStorage.setItem("user", JSON.stringify(response.data));
        }
        return Promise.resolve(response.data);
      }).catch(error => {
        commit("loginFailure");
        return Promise.reject(error);});
    },

    register({ commit }, user) {
      return UserService.register(user).then(response => {
        commit("registerSuccess");
        return Promise.resolve(response); // resolve the promise with a response from the server, otherwise request does not go back to caller!!
      }).catch(error => {
        commit("registerFailure");
        return Promise.reject(error);
      });
    },

    logout({ commit }) {
      AuthService.logout();
      commit("logout");
    }
  },

  getters: {}
};
