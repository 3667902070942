<template>
	<div>
		<div class="row">
			<!-- <div class="col-md-3">
				<router-link :to="{ name: '' }" @click.native="setSelectedComponent('ASSIGNED_LOADS')">
					<div class="panel">
						<div class="panel-body">
							<h4 class="pull-right"><span class="label label-default">1</span></h4>
							<p class="text-center">
								<img src="@/assets/images/checkmark.png" alt="Assigned Loads" height="25" width="25" class="img img-rounded img-responsive center-block"/><br>
								Integrations
							</p>					
						</div>
					</div>
				</router-link>
			</div> -->
			<div class="col-md-3">
				<router-link :to="{ name: 'business-profile' }" @click.native="setSelectedComponent('IN_TRANSIT')">
					<div class="panel">
						<div class="panel-body">
							<h4 class="pull-right"><span class="label label-primary">&nbsp;</span></h4>
							<p class="text-center">
								<router-link :to="{ name: 'business-profile'}"><img src="@/assets/images/user.png" height="20" width="20"> Business Profile</router-link>
							</p>					
						</div>
					</div>
				</router-link>
			</div>
		</div>

		<!-- <GeneralSettings v-show="selectedComponent === 'ASSIGNED_LOADS'"/> -->
		<router-view>
			<!-- <GeneralSettings/> -->
		</router-view>
	</div>
</template>

<script type="text/javascript">
	// import GeneralSettings from "@/views/settings/GeneralSettings";

	export default{
		data: function(){
			return {
				selectedComponent: JSON.parse(localStorage.getItem('selectedComponent'))
			}
		},

		components: {
			// GeneralSettings
		},

		methods: {
			setSelectedComponent(selectedComponent){
				this.selectedComponent = selectedComponent;
				localStorage.setItem('selectedComponent', JSON.stringify(selectedComponent));
			}
		}
	}
</script>