<template>
  <div>
    <loading :show="show" :label="label">
      <div class="processing-row" slot="loading-container">
        <div class="text-center">
          <div slot="loading-spinner">
            <img src="@/assets/images/station-loading.gif" height="100" width="100"/>
          </div>
          <p class="text-center" slot="loading-text">{{ label }}</p>
        </div>
      </div>
    </loading>

    <div class="panel">
      <div class="panel-body">
        <p>Customer Postal Address</p>
        <hr>

        <p class="error-message text-center" v-if="message != null">{{ message }}</p>
        <form class="form" @submit.prevent="save">
          <div class="form-group">
            <label>City</label>
            <select class="form-control" v-model="postalAddress.cityId" required>
              <option value="">Select City</option>
              <option v-for="city in cities" v-bind:key="city.id" v-bind:value="city.id">{{ city.name }}</option>
            </select>
          </div>                 

          <div class="form-group">
            <label>Physical Address</label>
            <textarea rows="5" class="form-control" placeholder="Enter physical postal address" v-model="postalAddress.physicalAddress" required></textarea>
          </div>

          <div class="row">
            <div class="col-md-10"></div>
            <div class="col-md-2">
              <button class="btn btn-success pull-right btn-block" type="submit">Submit</button>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script type="text/javascript">
  import CustomerService from "@/services/CustomerService.js";
  import CityService from "@/services/CityService.js";
  import Customer from "@/models/Customer.js";
  import loading from "vue-full-loading";

  export default {
    components: {
      loading
    },

    name: "NewCustomerPostalAddress",
    data: function() {
      return {
        show: false,
        label: "Processing request",
        cities: [],
        message: null,
        errors: null,
        postalAddress: {
          zipCodeId: null,
          countryId: null,
          provinceId: null,
          cityId: null,
          areaId: null,
          postalAddress: null
        },
        customer: new Customer,
      };
    },

    computed: {
      getId() {
        return this.$route.params.id;
      },

      getUser(){
        return this.$store.state.auth.user.user;
      }
    },

    created() {
      this.findAllCities();
    },

    methods: {
      findAllCities: function() {
        CityService.findAll().then(response => {
          this.cities = response.data.body;
        }).catch(error => {
          console.log(error);
        });
      },

      save: function() {
        this.show = true;
        this.customer.id = this.getId;
        this.customer.postalAddress = this.postalAddress;
        this.customer.associatedUserUsername = this.getUser.username;
        CustomerService.save(this.customer).then(response => {
          if (response.data.statusCode === 201) {
            this.$router.push({ name: "new-customer-contact-people" });
          }
          this.show = false;
        }).catch(error => {
          if (error.response.status === 500) {
            this.message = error.response.data.message;
          }
          this.show = false;
        });
      }
    }
  };
</script>
