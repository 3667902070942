<template>
	<div>
		<loading :show="show" :label="label">
	      <div class="processing-row" slot="loading-container">
	        <div class="text-center">
	          <div slot="loading-spinner">
	            <img src="@/assets/images/station-loading.gif" height="100" width="100"/>
	          </div>
	          <p class="text-center" slot="loading-text">{{ label }}</p>
	        </div>
	      </div>
	    </loading>
    
		<div class="panel">
			<div class="panel-body">
				<fieldset>
					<legend>Load Pricing and Offers</legend>
					<form class="form" @submit.prevent="save">
						<div class="row">
							<div class="col-md-6">
								<div class="form-group">
									<label>Price</label>
									<nice-numeric-input label="" hide-label noControls :max="9999999999" :min="0" :maxDecimalPlaces="3" v-model="load.price" placeholder="Enter load price" required/>
								</div>
							</div>
							<div class="col-md-6">
								<div class="form-group">
									<label>Currency</label>
									<select class="form-control" v-model="load.currency" required>
										<option value="" disabled>Select option</option>
										<option value="ZWL">ZWL</option>
										<option value="USD">USD</option>
									</select>
								</div>
							</div>
						</div>
						<br>
						<div class="row">
							<div class="col-md-4" v-if="!load.setToPrivateLoad">
								<input type="checkbox" name="" v-model="load.bookNowEnabled"> Enable Book Now <br><br>
								<p class="text-danger"><i>Allow carriers to book your load instantly</i></p>
								<br><br>
							</div>
							<div class="col-md-4" v-if="!load.setToPrivateLoad">
								<input type="checkbox" name="" v-model="load.collectLoadOffers"> Enable Collect Load Offers/Bids
								<br><br>
								<p class="text-danger"><i>Allow carriers to bid your load or negotiate offer</i></p>
								<br><br>
							</div>
							<div class="col-md-4" v-if="carrierId == null">
								<input type="checkbox" name="" v-model="load.setToPrivateLoad"> Set to Private Load <br><br>
								<p class="text-danger"><i>Only whitelisted carriers can see this load</i></p>
							</div>
						</div>

						<div class="form-group" v-show="carrierId == null && premiumCarriers.length > 0 && load.setToPrivateLoad">
							<label>Offer this load to whitelisted carriers</label>
							<select class="form-control" v-model="load.carrierId">
								<option value="">Select option</option>
								<option v-for="premiumCarrier in premiumCarriers" :key="premiumCarrier.id" :value="premiumCarrier.id">{{ premiumCarrier.name
									 }}</option>
							</select>
						</div>

						<div class="row">
							<div class="col-md-10"></div>
							<div class="col-md-2">
								<div class="form-group">
									<label>&nbsp;</label>
									<button class="btn btn-success btn-block" type="submit">Submit</button>
								</div>
							</div>
						</div>
					</form>
				</fieldset>
			</div>
		</div>
	</div>
</template>

<script type="text/javascript">
	import loading from "vue-full-loading";
	import LoadService from "@/services/LoadService";
	import CompanyService from "@/services/CompanyService";
	import Load from "@/models/Load";

	export default{
		name: 'NewLoadPriceAndOffers',
		components: {
			loading,
		},

		data: function(){
			return {
				label: "Processing request",
				show: false,
				premiumCarriers: [],
				pickupCities: [],
				deliveryCities: [],
				provinceId: null,
				load: new Load,
			    zipCodes: [],
			    brokerId: null,
			    carrierId: JSON.parse(localStorage.getItem('carrierId')) != null ? JSON.parse(localStorage.getItem('carrierId')): null,
			    assignedTruckNumber: JSON.parse(localStorage.getItem('assignedTruckNumber')) != null ? JSON.parse(localStorage.getItem('assignedTruckNumber')): null,
			}
		},

		mounted(){
			console.log('carrierId null: ', this.carrierId == null);
			if (this.carrierId != null) {
				this.load.setToPrivateLoad = true;
			}
			console.log('assignedTruckNumber null: ', this.assignedTruckNumber == null);
			this.findPremiumCarriers();
		},

		computed: {
			getLoadNumber: function(){
				return this.$route.params.loadNumber;
			},

			getUser: function() {
				return this.$store.state.auth.user.user;
			},

			getRoles: function() {
				let roles = [];
				for (var i = 0; i < this.getUser.authorities.length; i++) {
					roles.push(this.getUser.authorities[i].authority);
				}
				return roles;
			}
		},

		methods: {
			findPremiumCarriers: function(){
				CompanyService.findPremiumCarriers().then(response => {
					this.premiumCarriers = response.data.body;
		        }).catch(error => {
		        	console.log(error);
		        });
			},

			save: function(){
				this.show = true;
				this.load.loadNumber = this.getLoadNumber;
				this.load.carrierId = this.carrierId !== null ? this.carrierId: this.load.carrierId;
				this.load.assignedTruckNumber = this.assignedTruckNumber !== null ? this.assignedTruckNumber: this.load.assignedTruckNumber;
				console.log('setToPrivateLoad: ', this.load.setToPrivateLoad);
				LoadService.save(this.load).then(response => {
					if (response.data.statusCode === 201) {
						localStorage.removeItem('carrierId');
						localStorage.removeItem('assignedTruckNumber');
						this.$router.push({ name: 'new-load-contact-information-for-carrier', params: { loadNumber: this.getLoadNumber }});
					}
					this.show = false;
				}).catch(error => {
					console.log(error);
					this.show = false;
				});
			},
		},
	};
</script>