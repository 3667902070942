import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import axios from "axios";
import helpers from "./helpers/helper";

// validation
import VeeValidate from "vee-validate";

// import the es6 version
import 'zingchart/es6';
// import the component AFTER ZingChart since it is a DIRECT dependency
import zingchartVue from 'zingchart-vue';

Vue.component('zingchart', zingchartVue);

// not using this@moment
Vue.use(VeeValidate, {
  errorBagName: "vErrors" // we renamed Vee-validate error name was casing errors already defined in views,
});

Vue.config.productionTip = false;

const plugins = {
  install(){
    Vue.helpers = helpers;
    Vue.prototype.$helpers = helpers;
  }
}
Vue.use(plugins);

import NiceNumericInput from 'nice-numeric-input'
Vue.use(NiceNumericInput)

import * as VueGoogleMaps from "vue2-google-maps";

Vue.use(VueGoogleMaps, {
  load: {
    key: 'AIzaSyDq3ZUgFHvNEIj7BUR5mVbA0t8KKoj82Yg',
    libraries: ['geometry', 'places'] // necessary libraries
  }
});

new Vue({
  // why is the data not a function here
  data: {
    // global loading using custom loader
    isLoading: false,
    axiosInterceptor: null
  },

  router,
  store,

  methods: {
    enableInterceptor() {
      (this.axiosInterceptor = axios.interceptors.request.use(config => {
        this.isLoading = true;
        return config;
      })),
        error => {
          this.isLoading = false;
          return Promise.reject(error);
        };

      axios.interceptors.response.use(response => {
        this.isLoading = false;
        return response;
      }),
        error => {
          this.isLoading = false;
          return Promise.reject(error);
        };
    },
    disableInterceptor() {
      // use this method if component should not call the interceptor
      axios.interceptors.request.eject(this.axiosInterceptor);
    }
  },
  render: h => h(App)
}).$mount("#app");
