import { GENERIC_SERVICE_URL } from "@/config/GenericServiceConfig";
import axios from "axios";

class BusinessCategoryService {
  findAll() {
    return axios.get(GENERIC_SERVICE_URL + "businessCategories");
  }

  findById(id) {
    return axios.get(GENERIC_SERVICE_URL + "businessCategories/" + id);
  }

  findByBusinessSectorId(businessSectorId) {
    return axios.get(
      GENERIC_SERVICE_URL +
        "businessCategories/findByBusinessSectorId/" +
        businessSectorId
    );
  }

  save(businessCategory) {
    return axios.post(GENERIC_SERVICE_URL + "businessCategories", {
      businessCategoryId: businessCategory.businessCategoryId,
      businessSectorId: businessCategory.businessSectorId,
      name: businessCategory.name,
      description: businessCategory.description,
      status: businessCategory.status
    });
  }
}

export default new BusinessCategoryService;
