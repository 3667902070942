var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('loading',{attrs:{"show":_vm.show,"label":_vm.label}},[_c('div',{staticClass:"processing-row",attrs:{"slot":"loading-container"},slot:"loading-container"},[_c('div',{staticClass:"text-center"},[_c('div',{attrs:{"slot":"loading-spinner"},slot:"loading-spinner"},[_c('img',{attrs:{"src":require("@/assets/images/station-loading.gif"),"height":"100","width":"100"}})]),_c('p',{staticClass:"text-center",attrs:{"slot":"loading-text"},slot:"loading-text"},[_vm._v(_vm._s(_vm.label))])])])]),(_vm.company !== null)?_c('div',[_c('div',{staticClass:"panel"},[_c('div',{staticClass:"panel-body text-center"},[_c('h3',[_vm._v("Welcome to FreightBoard TMS 🎉, "+_vm._s(_vm.company != null ? _vm.company.name: ''))]),_c('h4',[_vm._v("You just started a smarter way to manage your fleet and loads efficiently")])])])]):_vm._e(),_vm._m(0),_vm._m(1),_vm._m(2),_vm._m(3),_vm._m(4),_vm._m(5),_vm._m(6)],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-4"},[_c('div',{staticClass:"panel"},[_c('div',{staticClass:"panel-body text-center"},[_c('h2',[_vm._v("01 "),_c('img',{attrs:{"src":require("@/assets/images/checkmark.png"),"width":"50px","height":"50px"}})]),_c('h4',[_vm._v("Setup your organization")]),_c('p',[_vm._v("Add the pay day, load start number, per diem and more.")])])])]),_c('div',{staticClass:"col-md-4"},[_c('div',{staticClass:"panel"},[_c('div',{staticClass:"panel-body text-center"},[_c('h2',[_vm._v("02")]),_c('h4',[_vm._v("Build your first load")]),_c('p',[_vm._v("Create loads to be assigned to a driver and dispatched.")])])])]),_c('div',{staticClass:"col-md-4"},[_c('div',{staticClass:"panel"},[_c('div',{staticClass:"panel-body"},[_c('h2',[_vm._v("03")]),_c('h4',[_vm._v("Send your first invoice")]),_c('p',[_vm._v("Select invoices to be sent to your customer/broker.")])])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('h4',[_c('img',{attrs:{"src":require("@/assets/images/pro.png"),"width":"50px","height":"50px"}}),_vm._v(" Integrations")])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-4"},[_c('div',{staticClass:"panel"},[_c('div',{staticClass:"panel-body text-center"},[_c('h4',[_vm._v("ELD provider")]),_c('p',[_vm._v("Track your fleet in real-time. "),_c('a',{attrs:{"href":"#"}},[_vm._v("Read more.")])]),_c('button',{staticClass:"btn btn-default"},[_vm._v("Integrate ELD")])])])]),_c('div',{staticClass:"col-md-4"},[_c('div',{staticClass:"panel"},[_c('div',{staticClass:"panel-body text-center"},[_c('h4',[_vm._v("Loadboards")]),_c('p',[_vm._v("Book loads directly from CarrierTMS. "),_c('a',{attrs:{"href":"#"}},[_vm._v("Read more.")])]),_c('button',{staticClass:"btn btn-default"},[_vm._v("Integrate LoadBoards")])])])]),_c('div',{staticClass:"col-md-4"},[_c('div',{staticClass:"panel"},[_c('div',{staticClass:"panel-body"},[_c('h4',[_vm._v("Quickbooks")]),_c('p',[_vm._v("Send invoices generated in CarrierTMS to Quickbooks. "),_c('a',{attrs:{"href":"#"}},[_vm._v("Read more.")])]),_c('button',{staticClass:"btn btn-default"},[_vm._v("Integrate Quickbooks")])])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('h4',[_c('img',{attrs:{"src":require("@/assets/images/dispatch.png"),"width":"50px","height":"50px"}}),_vm._v(" Dispatching")])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-4"},[_c('div',{staticClass:"panel"},[_c('div',{staticClass:"panel-body text-center"},[_c('h4',[_vm._v("Truckboard")]),_c('p',[_vm._v("Ease load planning and driver scheduling for dispatchers. "),_c('a',{attrs:{"href":"#"}},[_vm._v("Read more.")])]),_c('button',{staticClass:"btn btn-default"},[_vm._v("Go to TruckBoard")])])])]),_c('div',{staticClass:"col-md-4"},[_c('div',{staticClass:"panel"},[_c('div',{staticClass:"panel-body text-center"},[_c('h4',[_vm._v("Assign a dispatcher to a load")]),_c('p',[_vm._v("Choose a dispatcher to manage a load directly. "),_c('a',{attrs:{"href":"#"}},[_vm._v("Read more.")])]),_c('button',{staticClass:"btn btn-default"},[_vm._v("Assign dispatcher")])])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('h4',[_c('img',{attrs:{"src":require("@/assets/images/organization.png"),"width":"50px","height":"50px"}}),_vm._v(" Organization profile")])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-4"},[_c('div',{staticClass:"panel"},[_c('div',{staticClass:"panel-body text-center"},[_c('h4',[_vm._v("Invite users")]),_c('p',[_vm._v("Invite for dispatch, accounting and management roles in your organization. "),_c('a',{attrs:{"href":"#"}},[_vm._v("Read more.")])]),_c('button',{staticClass:"btn btn-default"},[_vm._v("Invite users")])])])]),_c('div',{staticClass:"col-md-4"},[_c('div',{staticClass:"panel"},[_c('div',{staticClass:"panel-body text-center"},[_c('h4',[_vm._v("Bulk importing")]),_c('p',[_vm._v("Import drivers, trucks, trailers, businesses, customers, and loads in bulk. "),_c('a',{attrs:{"href":"#"}},[_vm._v("Read more.")])]),_c('button',{staticClass:"btn btn-default"},[_vm._v("Import bulk")])])])])])
}]

export { render, staticRenderFns }