<template>
	<div>
		<div v-show="loads.length > 0">
			<div class="panel" v-for="load in loads" :key="load.id">
				<div class="panel-body">
					<div class="row">
						<router-link :to="{ name: 'view-load', params: { loadNumber: load.loadNumber }}">
							<div class="col-md-2">
								<b><i><span class="label label-primary">PICKUP</span></i></b>
								<h4>{{ load.pickupAddress.province !== null ? load.pickupAddress.province.name: "Not available" }}, {{ load.pickupAddress.province !== null ? load.pickupAddress.city.name: "Not available" }}</h4>
								<p>{{ load.pickupDate }} <img src="@/assets/images/at.png" height="14" width="14"> {{ load.pickupTime }}</p>
								<p><span class="badge">{{ load.trailerTypeRequired }}</span> required - <span class="badge">{{ load.loadMode }}</span></p>
							</div>
							<div class="col-md-1">
								<br><br><br>
								<img src="@/assets/images/right-arrow.png" height="30" width="30">
							</div>
							<div class="col-md-3">
								<span v-if="load.destinationAddress !== null">
									<b><i><span class="label label-success">DELIVERY</span></i></b> <span class="badge">{{ load.estimatedDistance }} {{ load.estimatedDistanceMeasurement }}</span>
									<span class="badge">{{ load.distance }}{{ load.distanceMeasurement }}</span>
									<h4>{{ load.destinationAddress.province !== null ? load.destinationAddress.province.name: "Not available" }}, {{ load.destinationAddress.province !== null ? load.destinationAddress.city.name: "Not available" }}</h4>
									<p>{{ load.dropOffDate }} <img src="@/assets/images/at.png" height="14" width="14"> {{ load.dropOffTime }}</p>
									<p>{{ load.weight }} {{ load.weightMeasurement }} of {{ load.commodity }}</p>
								</span>
							</div>
						</router-link>
						<div class="col-md-2">
							<br><br>
							<h4>{{ load.currency }}${{ load.price }}</h4>
							<p><span class="badge">{{ load.loadType }} load</span></p>
						</div>
						<div class="col-md-2" v-show="getRoles.includes('CARRIER')">
							<br><br>
							<router-link :to="{ name: 'bid-load', params: { loadNumber: load.loadNumber }}">
								<button class="btn btn-default btn-block">Bid Load <span class="glyphicon glyphicon-ok-circle"></span></button>
							</router-link>
						</div>
						<div class="col-md-2" v-show="getRoles.includes('CARRIER')">
							<br><br>
							<router-link :to="{ name: 'book-load', params: { loadNumber: load.loadNumber }}">
								<button class="btn btn-primary btn-block">Book Load <span class="glyphicon glyphicon-ok-circle"></span></button>
							</router-link>
						</div>
					</div>
				</div>
			</div>
			<ul class="pagination">
				<li class="active"><a href="#">Next</a></li>
				<li><a href="#">1</a></li>
				<li><a href="#">2</a></li>
				<li><a href="#">3</a></li>
				<li><a href="#">Prev</a></li>
			</ul>
		</div>
		<div v-show="loads.length === 0">
			<p>Snap! Looks like there are no return loads nearest</p>
		</div>
	</div>
</template>

<script type="text/javascript">
	export default{
		props: {
			loads: {
				type: Array,
				default: function(){
					return []
				}
			}
		},

		computed: {
			getUser: function() {
		        return this.$store.state.auth.user.user;
		    },

		    getRoles: function() {
		    	let roles = [];
		    	for (var i = 0; i < this.getUser.authorities.length; i++) {
		    		roles.push(this.getUser.authorities[i].authority);
		    	}
		    	return roles;
		    },
		}
	};
</script>