import { GENERIC_SERVICE_URL } from "@/config/GenericServiceConfig.js";
import axios from "axios";

class AreaService {
  findAll() {
    return axios.get(GENERIC_SERVICE_URL + "areas");
  }

  findById(id) {
    return axios.get(GENERIC_SERVICE_URL + "areas/" + id);
  }

  findByCityId(cityId) {
    return axios.get(GENERIC_SERVICE_URL + "areas/findByCityId/" + cityId);
  }

  save(area) {
    return axios.post(GENERIC_SERVICE_URL + "areas", {
      areaId: area.areaId,
      cityId: area.cityId,
      name: area.name,
      description: area.description,
      status: area.status
    });
  }
}

export default new AreaService();
