<template>
  <div>
    <ul class="list-group">
      <ul class="list-group">
        <li class="list-group-item">
          <div v-if="socialMediaAccounts.length === 0">
            Social Media Accounts unavailable
          </div>
          <div v-else>
            <div
              class="list-group"
              v-for="socialMediaAccount in socialMediaAccounts"
              :key="socialMediaAccount.id"
            >
              <li class="list-group-item">
                Social Media Account ID : {{ socialMediaAccount.id }}
              </li>
              <li class="list-group-item">
                Social Media Account Name : {{ socialMediaAccount.name }}
              </li>
              <li class="list-group-item">
                Social Media Account URL : {{ socialMediaAccount.url }}
              </li>
              <li class="list-group-item">
                Date Opened : {{ socialMediaAccount.dateOpened }}
              </li>
            </div>
          </div>
        </li>
      </ul>
    </ul>
  </div>
</template>

<script type="text/javascript">
export default {
  name: "SocialMediaAccountsComponent",
  props: {
    socialMediaAccounts: {
      type: Array,
      default: function() {
        return [];
      }
    }
  },

  data: function() {
    return {};
  }
};
</script>
