export default class User {
  constructor(id, personType,username, email, password, confirmPassword, accountNonExpired, accountNonLocked, credentialsNonExpired,
    enabled, roles, firstName, middleName, lastName, nickname, dateOfBirth, phoneNumbers, homePhoneNumber, workPhoneNumber, faxNumber,
    skypeAddress, gender, salutation, maritalStatus, nationalIdNumber, passportNumber, driversLicenceNumber, nationality, placeOfBirth,
    countryOfBirth, homeAddress, postalAddress, ethnicGroup, religion, digitalSignature, photo, occupation) {
    this.id = id;
    this.username = username;
    this.email = email;
    this.password = password;
    this.confirmPassword = confirmPassword;
    this.accountNonExpired = accountNonExpired;
    this.accountNonLocked = accountNonLocked;
    this.credentialsNonExpired = credentialsNonExpired;
    this.enabled = enabled;
    this.roles = roles;
    this.firstName = firstName;
    this.middleName = middleName;
    this.lastName = lastName;
    this.nickname = nickname;
    this.dateOfBirth = dateOfBirth;
    this.phoneNumbers = phoneNumbers;
    this.homePhoneNumber = homePhoneNumber;
    this.workPhoneNumber = workPhoneNumber;
    this.faxNumber = faxNumber;
    this.skypeAddress = skypeAddress;
    this.gender = gender;
    this.salutation = salutation;
    this.maritalStatus = maritalStatus;
    this.nationalIdNumber = nationalIdNumber;
    this.passportNumber = passportNumber;
    this.driversLicenceNumber = driversLicenceNumber;
    this.nationality = nationality;
    this.placeOfBirth = placeOfBirth;
    this.countryOfBirth = countryOfBirth;
    this.homeAddress = homeAddress;
    this.postalAddress = postalAddress;
    this.ethnicGroup = ethnicGroup;
    this.religion = religion;
    this.digitalSignature = digitalSignature;
    this.photo = photo;
    this.occupation = occupation;
  }
}
