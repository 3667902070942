import { GENERIC_SERVICE_URL } from "@/config/GenericServiceConfig.js";
import axios from "axios";

class ProvinceService {
  findAll() {
    return axios.get(GENERIC_SERVICE_URL + "provinces");
  }

  findById(id) {
    return axios.get(
      GENERIC_SERVICE_URL + "provinces/" + id
    );
  }

  findByCountryId(countryId) {
    return axios.get(
      GENERIC_SERVICE_URL + "provinces/findByCountryId/" + countryId
    );
  }

  save(province) {
    return axios.post(GENERIC_SERVICE_URL + "provinces", {
      provinceId: province.provinceId,
      countryId: province.countryId,
      name: province.name,
      description: province.description,
      status: province.status
    });
  }
}

export default new ProvinceService();
