<template>
	<div>
		<div class="row">
			<div class="col-md-3">
				<router-link :to="{ name: 'invoice-list' }" @click.native="setSelectedComponent('INVOICES')">
					<div class="panel">
						<div class="panel-body">
							<h4 class="pull-right"><span class="label label-default">{{ invoices.length }}</span></h4>
							<p class="text-center">
								<img src="@/assets/images/checkmark.png" alt="Assigned Loads" height="25" width="25" class="img img-rounded img-responsive center-block"/><br>
								Invoices
							</p>					
						</div>
					</div>
				</router-link>
			</div>
			<div class="col-md-3">
				<router-link :to="{ name: 'payments-list' }" @click.native="setSelectedComponent('STATEMENTS')">
					<div class="panel">
						<div class="panel-body">
							<h4 class="pull-right"><span class="label label-primary">{{ payments.length }}</span></h4>
							<p class="text-center">
								<img src="@/assets/images/delivery-truck.png" alt="Assigned Loads" height="25" width="25" class="img img-rounded img-responsive center-block"/><br>
								Payments
							</p>					
						</div>
					</div>
				</router-link>
			</div>
		</div>

		<router-view>
			<Statements v-show="selectedComponent === 'STATEMENTS'"/>
			<Statutory v-show="selectedComponent === 'STATUTORY'"/>
		</router-view>
	</div>
</template>

<script type="text/javascript">
	import Statements from "@/views/invoices/Statements";
	import Statutory from "@/views/invoices/Statutory";
	import LoadService from "@/services/LoadService.js";

	export default{
		data: function(){
			return {
				selectedComponent: JSON.parse(localStorage.getItem('selectedComponent')),
				invoices: [],
				payments: []
			}
		},

		components: {
			Statements,
			Statutory
		},

		mounted(){
			if (this.getRoles.includes('BROKER') || this.getRoles.includes('SHIPPER')) {
				this.findByOwnerUsername();
		    }
		    if (this.getRoles.includes('CARRIER')) {
		    	this.findByCarrierAssociatedUserUsername();
		    }
		},

		computed: {
			getUser: function() {
				return this.$store.state.auth.user.user;
		    },

		    getRoles: function() {
		    	let roles = [];
		    	for (var i = 0; i < this.getUser.authorities.length; i++) {
		    		roles.push(this.getUser.authorities[i].authority);
		    	}
		    	return roles;
		    }
		},

		methods: {
			setSelectedComponent(selectedComponent){
				this.selectedComponent = selectedComponent;
				localStorage.setItem('selectedComponent', JSON.stringify(selectedComponent));
			},

			findByCarrierAssociatedUserUsername: function(){
		        LoadService.findByCarrierAssociatedUserUsername(this.$store.state.auth.user.user.username).then(response => {
		          this.invoices = response.data.body.filter(load => {
		            return load.invoice !== null
		          });
		          this.payments = response.data.body.filter(load => {
		            return load.invoice !== null && load.invoice.payment !== null;
		          });
		        }).catch(error => {
		          console.log(error);
		        });
		    },

		    findByOwnerUsername: function(){
		    	LoadService.findByOwnerUsername(this.$store.state.auth.user.user.username).then(response => {
		    		this.invoices = response.data.body.filter(load => {
		    			return load.invoice !== null
		    		});
		    		this.payments = response.data.body.filter(load => {
		    			return load.invoice !== null && load.invoice.payment !== null;
		    		});
		    	}).catch(error => {
		    		console.log(error);
		    	});
		    }
		}
	}
</script>