<template>
	<div>
		<loading :show="show" :label="label">
	      <div class="processing-row" slot="loading-container">
	        <div class="text-center">
	          <div slot="loading-spinner">
	            <img src="@/assets/images/station-loading.gif" height="100" width="100"/>
	          </div>
	          <p class="text-center" slot="loading-text">{{ label }}</p>
	        </div>
	      </div>
	    </loading>

	    <div v-if="subscription !== null">
	        <div v-if="subscription.subscriptionPlan.subscriptionType === 'BASIC'">
	          <div class="panel">
	            <div class="panel-body">
	              <h4 class="text-danger">Oops, Sorry you need to upgrade your subscription plan to view your performance.</h4>
	              <p>This feature allows you to view performance of your Freight Board operations in the comfort of your home or office!</p>
	              <br>
	              <router-link :to="{ name: 'upgrade-subscription' }">
	                <button class="btn btn-primary">Upgrade Subscription</button>
	              </router-link>
	            </div>
	          </div>
	        </div>
	        <div v-else>
	        	<div class="row">
					<div class="col-md-3">
						<router-link :to="{ name: '' }" @click.native="setSelectedComponent('PERFORMANCE')">
							<div class="panel">
								<div class="panel-body">
									<h4 class="pull-right"><span class="label label-default">&nbsp;</span></h4>
									<p class="text-center">
										<img src="@/assets/images/performanceDashboard.png" alt="Assigned Loads" height="25" width="25" class="img img-rounded img-responsive center-block"/><br>
										Performance
									</p>					
								</div>
							</div>
						</router-link>
					</div>
				</div>
				<Performance v-show="selectedComponent === 'PERFORMANCE'"/>
	        </div>
	    </div>
	</div>
</template>

<script type="text/javascript">
	import Performance from "@/views/performance/Performance";
	import SubscriptionService from "@/services/SubscriptionService.js";
	import loading from "vue-full-loading";

	export default{
		data: function(){
			return {
				label: "Processing request",
				show: false,
				selectedComponent: JSON.parse(localStorage.getItem('selectedComponent')),
				subscription: null
			}
		},

		components: {
			Performance,
			loading
		},

		mounted(){
			this.findByActiveSubscription();
		},

		computed: {
			getUser: function() {
				return this.$store.state.auth.user.user;
		    }
		},

		methods: {
			setSelectedComponent(selectedComponent){
				this.selectedComponent = selectedComponent;
				localStorage.setItem('selectedComponent', JSON.stringify(selectedComponent));
			},

			findByActiveSubscription(){
		        this.show = true;
		        SubscriptionService.findByActiveSubscription(this.getUser.username).then(response => {
		          if (response.data.statusCode === 200) {
		            this.subscription = response.data.body;
		          }
		          this.show = false;
		        }).catch(error => {
		          console.log(error);
		          this.show = false;
		        });
		    },
		}
	};
</script>