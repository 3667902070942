var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"panel"},[_c('div',{staticClass:"panel-body"},[_c('h4',[_vm._v("Load Info")]),_c('hr'),_c('h4',[_vm._v("Unit")]),_vm._m(0),_vm._m(1),_vm._m(2),_vm._m(3),_c('button',{staticClass:"btn btn-success btn-sm pull-right"},[_vm._v("Add additional charge")]),_c('br'),_c('br'),_vm._m(4),_vm._m(5),_c('h4',[_vm._v("Total: $0.00")]),_c('h4',[_vm._v("Rate Confirmation")]),_vm._m(6),_vm._m(7),_c('div',{staticClass:"form-group"},[_c('label',[_vm._v(" ")]),_c('router-link',{attrs:{"to":{ name: 'operations-dashboard' }}},[_c('button',{staticClass:"btn btn-primary"},[_vm._v("Submit")])])],1)])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"form-group"},[_c('label',[_vm._v("Load Number")]),_c('input',{staticClass:"form-control",attrs:{"type":"text","name":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"form-group"},[_c('label',[_vm._v("Dispatcher "),_c('span',{staticClass:"glyphicon glyphicon-info-sign",attrs:{"title":"managers can re-assign loads to other dispatchers"}})]),_c('select',{staticClass:"form-control"},[_c('option',{attrs:{"value":""}},[_vm._v("Select option")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"form-group"},[_c('label',[_vm._v("Load Reference Number (PO Number)")]),_c('input',{staticClass:"form-control",attrs:{"type":"text","name":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"form-group"},[_c('label',[_vm._v("Customer")]),_c('select',{staticClass:"form-control"},[_c('option',{attrs:{"value":""}},[_vm._v("Select option")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('fieldset',[_c('legend',[_vm._v("Accessorial charges")]),_c('p',[_vm._v("You can add accessorial charges, fuel surcharge, etc.")]),_c('div',{staticClass:"row"},[_c('p',[_vm._v("Add accessorial charge to this load.")]),_c('div',{staticClass:"col-md-2"},[_c('div',{staticClass:"form-group"},[_c('label',[_vm._v("Pay Item*")]),_c('select',{staticClass:"form-control"},[_c('option',{attrs:{"value":""}},[_vm._v("Select category")]),_c('option',[_vm._v("Detension")]),_c('option',[_vm._v("Driver assist")]),_c('option',[_vm._v("Flat rate")]),_c('option',[_vm._v("Flat with fuel supercharge")]),_c('option',[_vm._v("Fuel supercharge")]),_c('option',[_vm._v("Layover")]),_c('option',[_vm._v("Lumper")]),_c('option',[_vm._v("Miles")]),_c('option',[_vm._v("Miscellenous deduction")]),_c('option',[_vm._v("Miscellenous payment")]),_c('option',[_vm._v("Redeliver")]),_c('option',[_vm._v("Truck order not used (TONU)")])])])]),_c('div',{staticClass:"col-md-2"},[_c('label',[_vm._v("Description")]),_c('textarea',{staticClass:"form-control",attrs:{"placeholder":"Add a description to this accessorial charge"}})]),_c('div',{staticClass:"col-md-2"},[_c('div',{staticClass:"form-group"},[_c('label',[_vm._v("Amount")]),_c('input',{staticClass:"form-control",attrs:{"type":"text","name":""}})])]),_c('div',{staticClass:"col-md-2"},[_c('div',{staticClass:"form-group"},[_c('label',[_vm._v("Quantity")]),_c('input',{staticClass:"form-control",attrs:{"type":"text","name":""}})])]),_c('div',{staticClass:"col-md-2"},[_c('div',{staticClass:"form-group"},[_c('label',[_vm._v("Document")]),_c('input',{staticClass:"form-control",attrs:{"type":"text","name":""}})])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"form-group"},[_c('label',[_vm._v("Load Rate")]),_c('input',{staticClass:"form-control",attrs:{"type":"text","name":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"form-group"},[_c('label',[_vm._v(" ")]),_c('input',{staticClass:"form-control",attrs:{"type":"file","name":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"form-group"},[_c('label',[_vm._v(" ")]),_c('select',{staticClass:"form-control"},[_c('option',{attrs:{"value":""}},[_vm._v("Select option")]),_c('option',[_vm._v("Scale Ticket")]),_c('option',[_vm._v("BOL")]),_c('option',[_vm._v("Original BOL")]),_c('option',[_vm._v("POD/Signed BOL")]),_c('option',[_vm._v("Load Picture")]),_c('option',[_vm._v("Factoring Agreement")]),_c('option',[_vm._v("Blind BOL")]),_c('option',[_vm._v("Lumper Receipt")]),_c('option',[_vm._v("Miscellenous")]),_c('option',[_vm._v("Other")])])])
}]

export { render, staticRenderFns }