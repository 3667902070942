<template>
	<div>
		<loading :show="show" :label="label">
	      <div class="processing-row" slot="loading-container">
	        <div class="text-center">
	          <div slot="loading-spinner">
	            <img src="@/assets/images/station-loading.gif" height="100" width="100"/>
	          </div>
	          <p class="text-center" slot="loading-text">{{ label }}</p>
	        </div>
	      </div>
	    </loading>

		<div class="panel">
			<div class="panel-body">
				<div class="row">
					<div class="col-md-3"></div>
					<div class="col-md-6">
						<h4 class="text-danger">Subscription Suspended</h4>
						<img src="@/assets/images/info.png" width="100px" height="100px"><br><br>
						 <p>Your subscription period has expired. Your subscription has been suspended because we're still unable to process your payment. <a href="#">Learn more</a></p>
						 <p>You won't be able to use FreightBoard services until you update your payment information.</p>
						 <br>
						
						<h4 class="text-danger" v-if="message !== null">{{ message }}</h4>
						<form class="form" @submit.prevent="renew">
							 <!-- <input type="text" name="subscriptionNumber" id="subscriptionNumber" placeholder="Enter subscription number" class="form-control" v-model="subscriptionNumber" required><br><br> -->
							 <button class="btn btn-primary pull-right" type="submit">MAKE A PAYMENT</button>
						</form>
					</div>
					<div class="col-md-3"></div>
				</div>
			</div>
		</div>
	</div>
</template>

<script type="text/javascript">
	import SubscriptionService from "@/services/SubscriptionService";
	import loading from "vue-full-loading";

	export default{
		components: {
			loading
		},

		data: function(){
			return {
				show: false,
				label: 'Processing request',
				subscriptionNumber: null,
				message: null
			}
		},

		created(){

		},

		computed: {
			currentUser() {
				return this.$store.state.auth.user;
			},
		},

		methods: {
			renew: function(){
				this.show = true;
				SubscriptionService.renew(this.currentUser.user.username).then(response => {
					console.log(response);
					if (response.data.statusCode === 201) {
						this.$router.push("/dashboard");
					}
					this.show = false;
				}).catch(error => {
					if (error.response.status === 500) {
						this.message = error.response.data.message;
					}
					this.show = false;
				});
			}
		}
	};
</script>