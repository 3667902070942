<template>
	<div>
		<div class="row">
			<div class="col-md-3">
				<router-link :to="{ name: 'truck-list' }" @click.native="setSelectedComponent('TRUCKS')">
					<div class="panel">
						<div class="panel-body">
							<h4 class="pull-right"><span class="label label-default" v-if="company !== null">{{ company.trucks.length }}</span></h4>
							<p class="text-center">
								<img src="@/assets/images/checkmark.png" alt="Assigned Loads" height="25" width="25" class="img img-rounded img-responsive center-block"/><br>
								Trucks
							</p>					
						</div>
					</div>
				</router-link>
			</div>
			<div class="col-md-3">
				<router-link :to="{ name: 'trailer-list' }" @click.native="setSelectedComponent('TRAILERS')">
					<div class="panel">
						<div class="panel-body">
							<h4 class="pull-right"><span class="label label-primary" v-if="company !== null">{{ company.trailers.length }}</span></h4>
							<p class="text-center">
								<img src="@/assets/images/delivery-truck.png" alt="Assigned Loads" height="25" width="25" class="img img-rounded img-responsive center-block"/><br>
								Trailers
							</p>					
						</div>
					</div>
				</router-link>
			</div>
			<div class="col-md-3">
				<router-link :to="{ name: 'driver-list' }" @click.native="setSelectedComponent('DRIVERS')">
					<div class="panel">
						<div class="panel-body">
							<h4 class="pull-right"><span class="label label-primary" v-if="company !== null">{{ company.drivers.length }}</span></h4>
							<p class="text-center">
								<img src="@/assets/images/driver.png" alt="Assigned Loads" height="25" width="25" class="img img-rounded img-responsive center-block"/><br>
								Drivers
							</p>					
						</div>
					</div>
				</router-link>
			</div>
			<div class="col-md-3">
				<router-link :to="{ name: 'dispatch' }" @click.native="setSelectedComponent('DRIVERS')">
					<div class="panel">
						<div class="panel-body">
							<h4 class="pull-right"><span class="label label-primary">0</span></h4>
							<p class="text-center">
								<img src="@/assets/images/driver.png" alt="Assigned Loads" height="25" width="25" class="img img-rounded img-responsive center-block"/><br>
								Dispatch
							</p>					
						</div>
					</div>
				</router-link>
			</div>
		</div>

		<router-view>
			<TrucksComponent v-if="selectedComponent === 'TRUCKS'"/>
			<TrailersComponent v-if="selectedComponent === 'TRAILERS'"/>
			<DriversComponent v-if="selectedComponent === 'DRIVERS'"/>
		</router-view>
	</div>
</template>

<script type="text/javascript">
	import TrucksComponent from "@/views/trucks/TrucksComponent";
	import TrailersComponent from "@/views/trucks/TrailersComponent";
	import DriversComponent from "@/views/drivers/DriversComponent";
	import CompanyService from "@/services/CompanyService";

	export default{
		data: function(){
			return {
				selectedComponent: null,
				company: null
			}
		},

		components: {
			TrucksComponent,
			TrailersComponent,
			DriversComponent
		},

		mounted(){
			this.selectedComponent = JSON.parse(localStorage.getItem('selectedComponent'));
			this.findByAssociatedUserUsername();
		},

		methods: {
			setSelectedComponent(selectedComponent){
				this.selectedComponent = selectedComponent;
				localStorage.setItem('selectedComponent', JSON.stringify(selectedComponent));
			},

			findByAssociatedUserUsername: function() {
				this.show = true;
		        CompanyService.findByAssociatedUserUsername(this.$store.state.auth.user.user.username).then(response => {
		          if (response.data.statusCode == 200) {
		            this.company = response.data.body;
		          }
		          this.show = false;
		        }).catch(error => {
		          console.log(error);
		          this.show = false;
		        });
		    }
		}
	}
</script>