<template>
  <div>
    <loading :show="show" :label="label">
      <div class="processing-row" slot="loading-container">
        <div class="text-center">
          <div slot="loading-spinner">
            <img src="@/assets/images/station-loading.gif" height="100" width="100"/>
          </div>
          <p class="text-center" slot="loading-text">{{ label }}</p>
        </div>
      </div>
    </loading>

    <div class="panel">
      <div class="panel-body">
        <h4>Ahoy, Admin</h4>
      </div>
    </div>
  </div>
</template>

<script>
  import loading from "vue-full-loading";

  export default {
    name: "AdminDashboard",
    components: {
      loading
    },
    
    data: function() {
      return {
        show: false,
        label: "Processing request",
      };
    },

    mounted: function() {
  
    },

    computed: {

    }
  };
</script>

