<template>
	<div>
		<loading :show="show" :label="label">
	      <div class="processing-row" slot="loading-container">
	        <div class="text-center">
	          <div slot="loading-spinner">
	            <img src="@/assets/images/station-loading.gif" height="100" width="100"/>
	          </div>
	          <p class="text-center" slot="loading-text">{{ label }}</p>
	        </div>
	      </div>
	    </loading>

		<div v-if="subscription !== null">
	        <div v-if="subscription.subscriptionPlan.subscriptionType === 'BASIC'">
	          <div class="panel">
	            <div class="panel-body">
	              <h4 class="text-danger">Oops, Sorry you need to upgrade your subscription plan to manage your customers & facilities.</h4>
	              <p>Customers and facilities enable you to keep record of your regular customers and view facilities intra and inter state!</p>
	              <br>
	              <router-link :to="{ name: 'upgrade-subscription' }">
	                <button class="btn btn-primary">Upgrade Subscription</button>
	              </router-link>
	            </div>
	          </div>
	        </div>
	        <div v-else>
	        	<div class="row">
					<div class="col-md-2">
						<router-link :to="{ name: 'customer-list' }" @click.native="setSelectedComponent('CUSTOMERS')">
							<div class="panel">
								<div class="panel-body">
									<h4 class="pull-right"><span class="label label-default">{{ customers.length }}</span></h4>
									<p class="text-center">
										<img src="@/assets/images/checkmark.png" alt="Assigned Loads" height="25" width="25" class="img img-rounded img-responsive center-block"/><br>
										List
									</p>					
								</div>
							</div>
						</router-link>
					</div>
					<div class="col-md-2" v-if="this.getRoles.includes('CARRIER')">
						<router-link :to="{ name: 'facilitiy-list' }" @click.native="setSelectedComponent('FACILITIES')">
							<div class="panel">
								<div class="panel-body">
									<h4 class="pull-right"><span class="label label-primary">{{ facilities.length }}</span></h4>
									<p class="text-center">
										<img src="@/assets/images/delivery-truck.png" alt="Assigned Loads" height="25" width="25" class="img img-rounded img-responsive center-block"/><br>
										Facilities
									</p>					
								</div>
							</div>
						</router-link>
					</div>
					<div class="col-md-2" v-if="this.getRoles.includes('BROKER') || this.getRoles.includes('SHIPPER')">
						<router-link :to="{ name: 'carrier-list' }" @click.native="setSelectedComponent('CARRIERS')">
							<div class="panel">
								<div class="panel-body">
									<h4 class="pull-right"><span class="label label-primary">{{ carriers.length }}</span></h4>
									<p class="text-center">
										<img src="@/assets/images/delivery-truck.png" alt="Assigned Loads" height="25" width="25" class="img img-rounded img-responsive center-block"/><br>
										Carriers
									</p>					
								</div>
							</div>
						</router-link>
					</div>
				</div>

				<router-view>
					<Customers v-show="selectedComponent === 'CUSTOMERS'"/>
					<Facilities v-show="selectedComponent === 'FACILITIES'"/>
					<Carriers v-show="selectedComponent === 'CARRIERS'"/>
				</router-view>
	        </div>
	    </div>
	</div>
</template>

<script type="text/javascript">
	import Customers from "@/views/customers/Customers";
	import Facilities from "@/views/customers/Facilities";
	import CompanyCustomerService from "@/services/CompanyCustomerService.js";
	import Carriers from "@/views/customers/Carriers";
	import CompanyService from "@/services/CompanyService.js";
	import SubscriptionService from "@/services/SubscriptionService.js";
	import loading from "vue-full-loading";

	export default{
		data: function(){
			return {
				label: "Processing request",
				show: false,
				selectedComponent: JSON.parse(localStorage.getItem('selectedComponent')),
				customers: [],
				facilities: [],
				carriers: [],
				subscription: null
			}
		},

		components: {
			Customers,
			Facilities,
			Carriers,
			loading
		},

		mounted(){
			this.findByActiveSubscription();
			this.findByCompanyAssociatedUserUsername();
			this.findAllFacilities();
			this.findAllCarriers();
		},

		methods: {
			findByActiveSubscription(){
		        this.show = true;
		        SubscriptionService.findByActiveSubscription(this.getUser.username).then(response => {
		          if (response.data.statusCode === 200) {
		            this.subscription = response.data.body;
		          }
		          this.show = false;
		        }).catch(error => {
		          console.log(error);
		          this.show = false;
		        });
		    },

			setSelectedComponent(selectedComponent){
				this.selectedComponent = selectedComponent;
				localStorage.setItem('selectedComponent', JSON.stringify(selectedComponent));
			},

			findByCompanyAssociatedUserUsername: function() {
		        this.show = true;
		        CompanyCustomerService.findByCompanyAssociatedUserUsername(this.getUser.username).then(response => {
		          this.customers = response.data.body;
		          this.show = false;
		        }).catch(error => {
		          console.log(error);
		          this.show = false;
		        });
		    },

		    findAllFacilities: function() {
		    	this.show = true;
		    	CompanyService.findAll().then(response => {
		    		if (response.data.statusCode === 200) {
		    			this.facilities = response.data.body.filter(company => {
		    				return (company.type === 'BROKER' || company.type === 'SHIPPER') && company.verified
		    				&& company.associatedUser.username !== this.getUser.username;
		    			});
		    		}
		    		this.show = false;
		    	}).catch(error => {
		    		console.log(error);
		    		this.show = false;
		    	});
		    },

		    findAllCarriers: function() {
		    	this.show = true;
		    	CompanyService.findAll().then(response => {
		    		if (response.data.statusCode === 200) {
		    			this.carriers = response.data.body.filter(company => {
		    				return company.type === 'CARRIER' && company.verified 
		    				&& company.associatedUser.username !== this.getUser.username;
		    			});
		    		}
		    		this.show = false;
		    	}).catch(error => {
		    		console.log(error);
		    		this.show = false;
		    	});
		    },
		},

		computed: {
			getUser: function() {
				return this.$store.state.auth.user.user;
        	},

        	getRoles: function() {
		    	let roles = [];
		    	for (var i = 0; i < this.getUser.authorities.length; i++) {
		    		roles.push(this.getUser.authorities[i].authority);
		    	}
		    	return roles;
		    },
		}
	}
</script>