<template>
  <div>
    <loading :show="show" :label="label">
        <div class="processing-row" slot="loading-container">
          <div class="text-center">
            <div slot="loading-spinner">
              <img src="@/assets/images/station-loading.gif" height="100" width="100"/>
            </div>
            <p class="text-center" slot="loading-text">{{ label }}</p>
          </div>
        </div>
      </loading>
    
    <div class="panel">
      <div class="panel-body">
        <div class="row">
          <div class="col-md-11">
            <h4>Trailer Details</h4>
          </div>
          <div class="col-md-1">
            <span v-if="getSource === 'VIEW_TRUCK'">
              <router-link :to="{ name: 'view-truck', params: { truckNumber: getTruckNumber }}">
                <button class="btn btn-primary btn-block">Back</button>
              </router-link>
            </span>
            <span v-else>
              <router-link :to="{ name: 'trailer-list'}">
                <button class="btn btn-primary btn-block">Back</button>
              </router-link>
            </span>
          </div>
        </div>
        <hr>

        <h4>Trailer photos</h4>
        <div class="row" v-if="trailer !== null">
          <div class="col-md-2" v-for="photo in trailer.photos" :key="photo.id">
            <span v-if="activeProfile === 'default' || activeProfile === 'test'">
              <img :src="GENERIC_SERVICE_URL + 'photos/getTrailerPhotos/' + photo.id" class="img img-responsive img-rounded img-view">
            </span>
            <span v-if="activeProfile === 'prod'">
              <img :src="AWS_S3_BUCKET + '/' + photo.fileName" class="img img-responsive img-rounded img-view">
            </span>
          </div>
        </div>
        <br>
        
        <ul class="list-group" v-if="trailer !== null">
          <li class="list-group-item">Trailer number: {{ trailer.trailerNumber }}</li>
          <li class="list-group-item">Registration number: {{ trailer.registrationNumber }}</li>
          <li class="list-group-item">Trailer type: {{ trailer.type }}</li>
          <li class="list-group-item">Make: {{ trailer.make }}</li>
          <li class="list-group-item">Model: {{ trailer.model }}</li>
          <li class="list-group-item">Year: {{ trailer.year }}</li>
          <li class="list-group-item">VIN: {{ trailer.vehicleIdentificationNumber }}</li>
          <li class="list-group-item">Ownership: {{ trailer.ownershipType }}</li>
          <li class="list-group-item">In service date: {{ trailer.inServiceDate }}</li>
          <li class="list-group-item">Load mode: {{ trailer.loadMode }}</li>
          <li class="list-group-item">Desired rate: {{ trailer.desiredRateCurrency }}{{ trailer.desiredRate }} per {{ trailer.desiredRateMeasurement }}</li>
          <li class="list-group-item">Length: {{ trailer.length }} {{ trailer.lengthMeasurement }}</li>
          <li class="list-group-item">Max Load Size: {{ trailer.maxLoadSize }}{{ trailer.maxLoadSizeMeasurement }}</li>
          <li class="list-group-item">Gross Vehicle Mass(GVM): {{ trailer.grossVehicleMass }}{{ trailer.grossVehicleMassMeasurement }}</li>
          <li class="list-group-item">Net Vehicle Mass (NVM): {{ trailer.netVehicleMass }}{{ trailer.netVehicleMassMeasurement }}</li>
          <li class="list-group-item">Max Height Clearance: {{ trailer.maxHeightClearance }}{{ trailer.maxHeightClearanceMeasurement }}</li>
          <li class="list-group-item">Notes: {{ trailer.description }}</li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script type="text/javascript">
  import TrailerService from "@/services/TrailerService";
  import loading from "vue-full-loading";
  import { GENERIC_SERVICE_URL } from "@/config/GenericServiceConfig.js";
  import ActiveProfileService from "@/services/ActiveProfileService";

  export default {
    name: "ViewTrailer",
    components: {
      loading,
    },

    data: function() {
      return {
        trucks: [],
        show: false,
        label: "Processing request",
        trailer: null,
        GENERIC_SERVICE_URL: GENERIC_SERVICE_URL,
        AWS_S3_BUCKET: process.env.VUE_APP_AWS_TRAILER_S3_BUCKET,
        ENV: process.env.NODE_ENV,
        activeProfile: null
      };
    },

    mounted(){
      this.findByTrailerNumber();
      this.getActiveProfile();
    },

    computed: {
      getTrailerNumber(){
        return this.$route.params.trailerNumber;
      },

      getSource(){
        return this.$route.query.source;
      },

      getTruckNumber(){
        return this.$route.query.truckNumber
      }
    },

    methods: {
      findByTrailerNumber() {
        this.show = true;
        TrailerService.findByTrailerNumber(this.getTrailerNumber).then(response => {
          this.trailer = response.data.body;
          this.show = false;
        }).catch(error => {
          this.show = false;
          console.log(error);
        });
      },

      getActiveProfile() {
        this.show = true;
        ActiveProfileService.getActiveProfile().then(response => {
          this.activeProfile = response.data.body;
          this.show = false;
        }).catch(error => {
          this.show = false;
          console.log(error);
        });
      },
    }
  };
</script>

<style type="text/css">
  .img-view {
    width: 100%;
    height: 200px;
    object-fit: cover;
};
</style>