<template>
	<div>
		<loading :show="show" :label="label">
	      <div class="processing-row" slot="loading-container">
	        <div class="text-center">
	          <div slot="loading-spinner">
	            <img src="@/assets/images/station-loading.gif" height="100" width="100"/>
	          </div>
	          <p class="text-center" slot="loading-text">{{ label }}</p>
	        </div>
	      </div>
	    </loading>

	    <div class="panel">
	    	<img src="@/assets/logo.png" width="95" height="65" class="img img-responsive img-rounded"/>        
    	</div>

	    <div class="panel">
	    	<div class="panel-body">
	    		<router-link :to="{ name: 'login' }">
					<button class="btn btn-success pull-right">Proceed to login</button>
				</router-link>

		        <div class="row" v-if="paymentResponse !== null">
					<div class="col-md-4"></div>
					<div class="col-md-4">
						<fieldset>
							<legend><h4 class="text-center text-primary">FreightBoard Subscription Payment</h4></legend>
							<div class="list-group">
								<li class="list-group-item">
									<p class="text-center" v-show="paymentResponse.status === 'PAID'">
								<img src="@/assets/images/paymentSuccess.png" height="180" width="180">
							</p>
							<p class="text-center" v-show="paymentResponse.status !== 'PAID'">
								<img src="@/assets/images/paymentFail.png" height="190" width="230">
							</p>
								</li>
								<li class="list-group-item"><h4>Amount: <span class="pull-right">{{ paymentResponse.currency }}{{ paymentResponse.amount }}</span></h4></li>
								<li class="list-group-item"><h4>Status: <span class="pull-right">{{ paymentResponse.status }}</span></h4></li>
								<li class="list-group-item"><h4>Reference Number: <span class="pull-right">{{ paymentResponse.debitReference }}</span></h4></li>
								<li class="list-group-item"><h4>Description: <span class="pull-right">{{ paymentResponse.description }}</span></h4></li>
								<li class="list-group-item"><h4>Date: <span class="pull-right">{{ paymentResponse.createdAt }}</span></h4></li>
							</div>
						</fieldset>
					</div>
					<div class="col-md-4"></div>
				</div>
	    	</div>
	    </div>
	</div>
</template>

<script type="text/javascript">
	import PaymentService from "@/services/PaymentService";
	import loading from "vue-full-loading";
	import SubscriptionService from "@/services/SubscriptionService.js";
	import Subscription from "@/models/Subscription.js";

	export default {
		components: {
			loading
		},
		name: 'TestPayment',
		data: function(){
			return {
				show: false,
				label: "Processing Payment",
				gateway: null,
				merchantReference: null,
				paymentResponse: null,
				hash: null,
				username: JSON.parse(localStorage.getItem("username")),
				subscriptionPlanNumber: localStorage.getItem("subscriptionPlanNumber"),
				subscription: new Subscription,
			}
		},

		mounted(){
			console.log(JSON.parse(localStorage.getItem('username')));
			this.gateway = this.$route.query.gateway;
			this.merchantReference = this.$route.query.merchantReference;
			this.hash = this.$route.query.hash;
			this.receivePaymentUpdate(this.gateway, this.merchantReference, this.hash);
		},

		computed: {
			getUser: function() {
				return this.$store.state.auth.user.user;
			},
		},

		methods: {
			receivePaymentUpdate: function(){
				this.show = true;
				PaymentService.receivePaymentUpdate(this.gateway, this.merchantReference, this.hash).then(response => {
					if (response.status === 200) {
						this.paymentResponse = response.data.body;
						if (this.paymentResponse.status === 'PAID') {
							this.subscribe();
						}else{
							alert('Snap! Subscription payment failed.');
						}
					}
					this.show = false;
				}).catch(error => {
					console.log(error);
					this.show = true;
				});
			},

			subscribe: function() {
		        this.show = true;
		        this.subscription.associatedUserUsername = this.username;
		        this.subscription.subscriptionPlanNumber = this.subscriptionPlanNumber;
		        SubscriptionService.save(this.subscription).then(response => {
		          if (response.data.statusCode == 422) {
		            this.setNotificationData(response);
		          }
		          if (response.data.statusCode === 400) {
		            this.setNotificationData(response);
		          }
		          if (response.data.statusCode == 201) {
		            console.log('Yay, subscription created successfully');
		            localStorage.removeItem("companyType");
					localStorage.removeItem("username");
					localStorage.removeItem("subscriptionPlanNumber");
		          }
		          this.show = false;
		        }).catch(error => {
		        	console.log(error);
		        	this.show = false;
		        });
		    },
		}
	};
</script>