<template>
	<div>
		<loading :show="show" :label="label">
	      <div class="processing-row" slot="loading-container">
	        <div class="text-center">
	          <div slot="loading-spinner">
	            <img src="@/assets/images/station-loading.gif" height="100" width="100"/>
	          </div>
	          <p class="text-center" slot="loading-text">{{ label }}</p>
	        </div>
	      </div>
	    </loading>
    
		<br>
		<form class="form" @submit.prevent="save">
			<fieldset>
				<legend>Load Information</legend>
				<div class="row">
					<div class="col-md-3">
						<div class="form-group">
							<label>Load Mode</label>
							<select class="form-control" name="loadMode" id="loadMode" v-model="load.loadMode">
								<option value="" disabled>Select option</option>
								<option value="FTL">Full truckload</option>
								<option value="LTL">Less than truckload</option>
								<option value="FCL">Full Container Load</option>
							</select>
						</div>
					</div>
					<div class="col-md-3">
						<div class="form-group">
							<label>Expiry Date</label>
							<input type="date" class="form-control" name="expiryDate" id="expiryDate" v-model="load.expiryDate"/>
						</div>
					</div>
				</div>
			</fieldset>

			<fieldset>
				<legend>Pickup and Delivery</legend>
				<div class="row">
					<div class="col-md-3">
						<div class="form-group">
							<label>Province</label>
							<select class="form-control" v-model="pickUpAddress.provinceId" @change="getPickupCities">
								<option value="" disabled>Select option</option>
								<option v-for="province in provinces" v-bind:key="province.id" :value="province.id">{{ province.name }}</option>
							</select>
						</div>
					</div>
					<div class="col-md-3">
						<div class="form-group">
							<label>City</label>
							<select class="form-control" v-model="pickUpAddress.cityId">
								<option value="" disabled>Select option</option>
								<option v-for="city in pickupCities" v-bind:key="city.id" :value="city.id">{{ city.name }}</option>
							</select>
						</div>
					</div>
					<div class="col-md-3">
						<div class="form-group">
							<label>Pickup Date</label>
							<input type="date" class="form-control" name="pickupDate" id="pickupDate" v-model="load.pickupDate"/>
						</div>
					</div>
					<div class="col-md-3">
						<div class="form-group">
							<label>Pickup Time</label>
							<input type="date" class="form-control" name="pickupTime" id="pickupTime" v-model="load.pickupTime"/>
						</div>
					</div>
				</div>
			</fieldset>

			<fieldset>
				<legend>Drop Off and Delivery</legend>
				<div class="row">
		        	<div class="col-md-3">
						<div class="form-group">
							<label>Drop Off Date</label>
							<input type="date" class="form-control" name="dropOffDate" id="dropOffDate" v-model="load.dropOffDate"/>
						</div>
					</div>
					<div class="col-md-3">
						<div class="form-group">
							<label>Drop Off Time</label>
							<input type="date" class="form-control" name="dropOffTime" id="dropOffTime" v-model="load.dropOffTime"/>
						</div>
					</div>
		        </div>

				<button class="btn btn-success btn-sm pull-right" type="button" @click="addDropOffAddresses">Add Drop Off Address</button><br>

		        <div v-if="dropOffAddressesFields.length > 0">
		        	<div class="row" v-for="(dropOffAddressField, dropOffAddressIndex) in dropOffAddressesFields" :key="dropOffAddressField.id">
						<div class="col-md-3">
							<div class="form-group">
								<label>Province</label>
								<select class="form-control" v-model="dropOffAddressField.provinceId" @change="getPickupCities">
									<option value="" disabled>Select option</option>
									<option v-for="province in provinces" v-bind:key="province.id" :value="province.id">{{ province.name }}</option>
								</select>
							</div>
						</div>
						<div class="col-md-3">
							<div class="form-group">
								<label>City</label>
								<select class="form-control" v-model="dropOffAddressField.cityId">
									<option value="" disabled>Select option</option>
									<option v-for="city in pickupCities" v-bind:key="city.id" :value="city.id">{{ city.name }}</option>
								</select>
							</div>
						</div>
						<br>
						<div class="col-md-3">
							<div class="form-group" v-show="dropOffAddressesFields.length > 1">
								<label>&nbsp;</label>
								<button class="btn btn-danger btn-sm pull-right" type="button" @click="dropOffAddressesFields.splice(dropOffAddressIndex, 1)">Delete Drop Off Address</button><br>
							</div>
						</div>
					</div>
		        </div>
			</fieldset>

			<div class="row">
				<div class="col-md-6">
					<div class="form-group">
						<label>Distance</label>
						<input type="number" name="distance" id="distance" class="form-control" placeholder="Enter distance" v-model="load.distance">
					</div>
				</div>
				<div class="col-md-6">
					<div class="form-group">
						<label>Distance Measurement</label>
						<select class="form-control" v-model="load.distanceMeasurement">
							<option value="" disabled>Select option</option>
							<option value="KM">Kilometers</option>
							<option value="MILES">Miles</option>
							<option value="METERS">Meters</option>
						</select>
					</div>
				</div>
			</div>

			<div class="row">
				<div class="col-md-6">
					<div class="form-group">
						<label>Estimated Delivery Time</label>
						<input type="number" name="estimatedDeliveryTime" id="estimatedDeliveryTime" class="form-control" placeholder="Enter average stop time" v-model="load.estimatedDeliveryTime">
					</div>
				</div>
				<div class="col-md-6">
					<div class="form-group">
						<label>Estimated Delivery Time Measurement</label>
						<select class="form-control" v-model="load.estimatedDeliveryTimeMeasurement">
							<option value="" disabled>Select option</option>
							<option value="HR">Hours</option>
							<option value="MIN">Miniutes</option>
							<option value="SEC">Seconds</option>
						</select>
					</div>
				</div>
			</div>

			<h4><i>EQUIPMENT TYPE</i></h4>
			<div class="form-group">
				<label>Trailer Required</label>
				<select class="form-control" v-model="load.trailerTypeRequired">
					<option value="" disabled>Select option</option>
					<option value="FLATBED">Flatbed</option>
					<option value="VAN">Van</option>
					<option value="REEFER">Reefer</option>
					<option value="STEP_DECK">Step Deck</option>
					<option value="POWER_ONLY">Power Only</option>
					<option value="AUTO_CARRIER">Auto Carrier</option>
					<option value="B_TRAIN">B Train</option>
					<option value="BOX_TRUCK">Box Truck</option>
					<option value="CONESTOGA">Consetoga</option>
					<option value="CONTAINERS">Containers</option>
					<option value="DOUBLE_DROP">Double Drop</option>
					<option value="DRY_BULK">Dry Bulk</option>
					<option value="DUMP_TRAILER">Dump Trailer</option>
					<option value="HOPPER_BOTTOM">Hooper Bottom</option>
					<option value="HOTSHOT">Hotshot</option>
					<option value="LOWBOY">Lowboy</option>
					<option value="TANKER">Tanker</option>
				</select>
			</div>

			<div class="form-group">
				<label>Commodity / Description</label>
				<textarea type="text" name="commodity" id="commodity" class="form-control" placeholder="Enter commodity e.g 44,315 lbs of OSB" v-model="load.commodity" rows="5"></textarea>
			</div>

			<div class="row">
				<div class="col-md-6">
					<div class="form-group">
						<label>Weight</label>
						<input type="number" name="estimatedDeliveryTime" id="estimatedDeliveryTime" class="form-control" placeholder="Enter Estimated Delivery Time" v-model="load.weight">
					</div>
				</div>
				<div class="col-md-6">
					<div class="form-group">
						<label>Weight Measurement</label>
						<select class="form-control" v-model="load.weightMeasurement">
							<option value="" disabled>Select option</option>
							<option value="KG">Kilogram</option>
							<option value="T">Tonne</option>
							<option value="GT">Gigatonne</option>
							<option value="CU">Cubic</option>
						</select>
					</div>
				</div>
			</div>

			<fieldset>
				<legend>Price and Offers</legend>
				<input type="checkbox" name="" v-model="load.bookNowEnabled"> Enable Book Now<br>
				<p>Allow carriers to book your freight instantly (price required).</p>
				<br>

				<input type="checkbox" name="" v-model="load.collectLoadOffers"> Collect Load Offers
				<br>
				
				<div class="row">
					<div class="col-md-6">
						<div class="form-group">
							<label>Price</label>
							<input type="number" name="estimatedDeliveryTime" id="estimatedDeliveryTime" class="form-control" placeholder="$ Target Price" v-model="load.price">
						</div>
					</div>
					<div class="col-md-6">
						<div class="form-group">
							<label>Currency</label>
							<select class="form-control" v-model="load.currency">
								<option value="" disabled>Select option</option>
								<option value="USD">USD</option>
								<option value="ZWL">ZWL</option>
								<option value="ZAR">ZAR</option>
								<option value="POUND">POUND</option>
								<option value="KWACHA">KWACHA</option>
								<option value="METICAL">METICAL</option>
								<option value="PULA">PULA</option>
							</select>
						</div>
					</div>
				</div>
			</fieldset>


			

			<div class="form-group">
				<label>Broker</label>
				<select class="form-control" v-model="load.broker">
					<option value="" disabled>Select option</option>
				</select>
			</div>

			<div class="row">
				<div class="col-md-6">
					<div class="form-group">
						<label>Estimated Driving Time</label>
						<input type="number" name="estimatedDrivingTime" id="estimatedDrivingTime" class="form-control" placeholder="Enter estimated Driving Time" v-model="load.estimatedDrivingTime">
					</div>
				</div>
				<div class="col-md-6">
					<div class="form-group">
						<label>Estimated Driving Time Measurement</label>
						<select class="form-control" v-model="load.estimatedDrivingTimeMeasurement">
							<option value="" disabled>Select option</option>
							<option value="HR">Hours</option>
							<option value="MIN">Miniutes</option>
							<option value="SEC">Seconds</option>
						</select>
					</div>
				</div>
			</div>

			<fieldset>
				<legend>Contact Information For Carrier</legend>
				<input type="checkbox" v-model="load.copyFromMyAccount"> Copy From My Account <br><br>
				<div v-show="!load.copyFromMyAccount">
					<div class="row">
						<div class="col-md-3">
							<div class="form-group">
								<label>First Name</label>
								<input type="text" class="form-control" name="firstName" id="firstName" v-model="contactInformationForCarrier.firstName"/>
							</div>
						</div>
						<div class="col-md-3">
							<div class="form-group">
								<label>Last Name</label>
								<input type="text" class="form-control" name="lastName" id="lastName" v-model="contactInformationForCarrier.lastName"/>
							</div>
						</div>
					</div>

					<div class="row">
						<div>
			                <button class="btn btn-success btn-sm pull-right" @click="addPhoneNumberFields(index)" type="button">Add more <span class="glyphicon glyphicon-plus"></span></button><br>
			                <div v-show="contactInformationForCarrier.phoneNumbers.length > 0">
			                  <div v-for="(phoneNumberField, phoneNumberIndex) in contactInformationForCarrier.phoneNumbers" :key="phoneNumberIndex">
			                    <div class="row">
			                      <div class="col-md-4">
			                        <div class="form-group">
			                          <label for="phoneNumbers">Country Code</label>
			                          <select class="form-control" v-model="phoneNumberField.zipCodeId">
			                            <option value="" disabled>Select option</option>
			                            <option v-for="zipCode in zipCodes" :key="zipCode.id" :value="zipCode.id">+{{ zipCode.code }}</option>
			                          </select>
			                        </div>
			                      </div>
			                      <div class="col-md-6">
			                        <div class="form-group">
			                          <label for="phoneNumbers">Phone Number</label>
			                          <input type="number" v-model="phoneNumberField.phoneNumber" name="phoneNumber" id="phoneNumber" class="form-control" placeholder="Enter Phone Number Without Zero prefix e.g. 7712345678..." maxlength="9" oninput="javascript: if (this.value.length > this.maxLength) { return this.value = this.value.slice(0, this.maxLength); }"/>
			                        </div>
			                      </div>
			                      <div class="col-md-2">
			                        <div class="form-group" v-show="contactInformationForCarrier.phoneNumbers.length > 1">
			                          <label>&nbsp;</label><br>
			                          <button class="btn btn-danger btn-sm pull-left" @click="contactInformationForCarrier.phoneNumbers.splice(phoneNumberIndex, 1)" type="button">Remove <span class="glyphicon glyphicon-minus"></span></button><br>
			                        </div>
			                      </div>
			                    </div>                
			                  </div>
			                </div>
			              </div>
						<div class="col-md-3">
							<div class="form-group">
								<label>Email</label>
								<input type="text" class="form-control" name="email" id="email" v-model="contactInformationForCarrier.email"/>
							</div>
						</div>
					</div>
				</div>
			</fieldset>

			<input type="checkbox" name="" v-model="load.setToPrivateLoad"> Set to Private Load <br>
			<p><i>Only whitelisted carriers can see this load.</i></p>

			<div class="row">
				<div class="col-md-10"></div>
				<div class="col-md-2">
					<div class="form-group">
						<label>&nbsp;</label>
						<button class="btn btn-success btn-block" type="submit">Post Load</button>
					</div>
				</div>
			</div>
		</form>
		<br><br>
	</div>
</template>

<script type="text/javascript">
	import loading from "vue-full-loading";
	import LoadService from "@/services/LoadService";
	import CityService from "@/services/CityService";
	import ProvinceService from "@/services/ProvinceService";
	import Load from "@/models/Load";
	import ZipCodeService from "@/services/ZipCodeService.js";

	export default{
		name: 'NewLoad',
		components: {
			loading,
		},

		data: function(){
			return {
				label: "Processing request",
				show: false,
				provinces: [],
				pickupCities: [],
				deliveryCities: [],
				provinceId: null,
				load: new Load,
				pickUpAddress: {
			        id: null,
			        zipCodeId: null,
			        countryId: null,
			        provinceId: null,
			        cityId: null,
			        areaId: null,
			        physicalAddress: null,
			        sameAsPostalAddress: false
			    },
			    deliveryAddress: {
			        id: null,
			        zipCodeId: null,
			        countryId: null,
			        provinceId: null,
			        cityId: null,
			        areaId: null,
			        physicalAddress: null,
			        sameAsPostalAddress: false
			    },
			    contactInformationForCarrier: {
			    	firstName: null,
			    	lastName: null,
			    	email: null,
			    	phoneNumbers: [{
				    	id: null,
				    	zipCodeId: null,
				    	phoneNumber: null
				    }],
			    },
			    dropOffAddressesFields: [{
			    	id: null,
            		zipCodeId: null,
            		countryId: null,
            		provinceId: null,
            		cityId: null,
            		areaId: null,
            		physicalAddress: null,
            		sameAsPostalAddress: false
			    }],
			    zipCodes: []
			}
		},

		created(){
			this.getProvinces();
			this.getZipCodes();
		},

		methods: {
			addPhoneNumberField: function(){
				this.contactInformationForCarrier.phoneNumbers.push({
					id: null,
					zipCodeId: null,
		        	phoneNumber: null
		        });
			},

			addDropOffAddresses: function(){
				this.dropOffAddressesFields.push({
					id: null,
            		zipCodeId: null,
            		countryId: null,
            		provinceId: null,
            		cityId: null,
            		areaId: null,
            		physicalAddress: null,
            		sameAsPostalAddress: false
				});
			},

			getProvinces: function(){
				ProvinceService.findAll().then(response => {
					this.provinces = response.data.body;
		        }).catch(error => {
		        	console.log(error);
		        });
			},

			getPickupCities: function() {
		      CityService.findByProvinceId(this.pickUpAddress.provinceId).then(response => {
		      	this.pickupCities = response.data.body;
		      }).catch(error => {
		      	console.log(error);
		      });
		    },

		    getDeliveryCities: function() {
		      CityService.findByProvinceId(this.deliveryAddress.provinceId).then(response => {
		      	this.deliveryCities = response.data.body;
		      }).catch(error => {
		      	console.log(error);
		      });
		    },

			save: function(){
				console.log('load', this.load);
				this.show = true;
				this.load.pickUpAddress = this.pickUpAddress;
				this.load.dropOffAddresses = this.dropOffAddressesFields;
				this.load.owner = {
					username: this.$store.state.auth.user.user.username
				};
				this.load.contactInformationForCarrier = this.contactInformationForCarrier;
				LoadService.save(this.load).then(response => {
					if (response.data.statusCode === 201) {
						this.$router.push({ name: 'loads/new/contactInformationForCarrier', params: { loadNumber: response.data.body.loadNumber }});
					}
					this.show = false;
				}).catch(error => {
					console.log(error);
					this.show = false;
				});
			},

			getZipCodes: function() {
		        ZipCodeService.findAll().then(response => {
		        	this.zipCodes = response.data.body;
		        }).catch(error => {
		        	console.log(error);
		        });
		    },
		}
	};
</script>