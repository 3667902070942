import { LOAD_SERVICE_URL } from "@/config/LoadServiceConfig";
import axios from "axios";

class LoadService {
  findAll() { 
    return axios.get(LOAD_SERVICE_URL + "loads"); 
  }

  findByOwnerUsername(username){
    return axios.get(LOAD_SERVICE_URL + "loads/findByOwnerUsername/" + username);
  }

  findReturnLoads(province){
    return axios.get(LOAD_SERVICE_URL + "loads/findReturnLoads/" + province);
  }

  findByCarrierContactPersonUsername(username){
    return axios.get(LOAD_SERVICE_URL + "loads/findByCarrierContactPersonUsername/" + username);
  }

  findByCarrierAssociatedUserUsername(username){
    return axios.get(LOAD_SERVICE_URL + "loads/findByCarrierAssociatedUserUsername/" + username);
  }

  findById(id){ 
    return axios.get(LOAD_SERVICE_URL + "loads/" + id); 
  }

  findByLoadNumber(loadNumber){ 
    return axios.get(LOAD_SERVICE_URL + "loads/findByLoadNumber/" + loadNumber); 
  }

  save(load) {
    return axios.post(LOAD_SERVICE_URL + "loads", {
      id: load.id,
      loadType: load.loadType,
      loadNumber: load.loadNumber,
      loadMode: load.loadMode,
      expiryDate: load.expiryDate,
      pickupAddress: load.pickupAddress,
      destinationAddress: load.destinationAddress,
      pickupDate: load.pickupDate,
      pickupTime: load.pickupTime,
      dropOffAddresses: load.dropOffAddresses,
      dropOffDate: load.dropOffDate,
      dropOffTime: load.dropOffTime,
      estimatedDistance: load.estimatedDistance,
      estimatedDistanceMeasurement: load.estimatedDistanceMeasurement,
      estimatedLoadingTime: load.estimatedLoadingTime,
      estimatedLoadingTimeMeasurement: load.estimatedLoadingTimeMeasurement,
      estimatedDeliveryTime: load.estimatedDeliveryTime,
      estimatedDeliveryTimeMeasurement: load.estimatedDeliveryTimeMeasurement,
      trailerTypeRequired: load.trailerTypeRequired,
      commodity: load.commodity,
      weight: load.weight,
      weightMeasurement: load.weightMeasurement,
      price: load.price,
      currency: load.currency,
      carrierId: load.carrierId,
      marketAveragePrice: load.marketAveragePrice,
      estimatedDrivingTime: load.estimatedDrivingTime,
      estimatedDrivingTimeMeasurement: load.estimatedDrivingTimeMeasurement,
      owner: load.owner,
      bookNowEnabled: load.bookNowEnabled,
      collectLoadOffers: load.collectLoadOffers,
      contactInformationForCarrier: load.contactInformationForCarrier,
      copyFromMyAccount: load.copyFromMyAccount,
      setToPrivateLoad: load.setToPrivateLoad,
      bookings: load.bookings,
      bids: load.bids,
      payment: load.payment,
      status: load.status,
      deliveryComments: load.deliveryComments,
      assignedTruckNumber: load.assignedTruckNumber
    });
  }

  release(load) {
    return axios.post(LOAD_SERVICE_URL + "loads/release", {
      loadNumber: load.loadNumber
    });
  }

  collected(load) {
    return axios.post(LOAD_SERVICE_URL + "loads/collected", {
      loadNumber: load.loadNumber,
    });
  }

  carrierConfirmedDelivery(load) {
    return axios.post(LOAD_SERVICE_URL + "loads/carrierConfirmedDelivery", {
      loadNumber: load.loadNumber
    });
  }

  confirmDelivery(load) {
    return axios.post(LOAD_SERVICE_URL + "loads/confirmDelivery", {
      loadNumber: load.loadNumber,
      status: load.status,
      deliveryComments: load.deliveryComments
    });
  }

  checkCarrierAlreadyBookedLoad(username, loadNumber){ 
    return axios.get(LOAD_SERVICE_URL + "loads/checkCarrierAlreadyBookedLoad/" + username + "/" + loadNumber); 
  }

  checkCarrierAlreadyBidLoad(username, loadNumber){ 
    return axios.get(LOAD_SERVICE_URL + "loads/checkCarrierAlreadyBidLoad/" + username + "/" + loadNumber); 
  }

  searchByLoadAlert(loadalerts){ 
    console.log(loadalerts);
    return axios.get(LOAD_SERVICE_URL + "loads/searchByLoadAlert", loadalerts); 
  }

  findByLoadLoadNumberWhereBidIsAwarded(loadNumber){ 
    return axios.get(LOAD_SERVICE_URL + "loads/findByLoadLoadNumberWhereBidIsAwarded/" + loadNumber); 
  }

  publish(loadNumber) {
    return axios.post(LOAD_SERVICE_URL + "loads/publish/" + loadNumber);
  }
}

export default new LoadService;
