<template>
	<div>
		<h4>Bank accounts</h4>
		<br>

		<div v-if="bankAccounts !== null && bankAccounts.length > 0">
			<ul v-for="(bankAccount, index) in bankAccounts" :key="bankAccount.id">
				<span class="text-danger">Account number {{ index + 1 }}</span><br>
				<li class="list-group-item">Account number: {{ bankAccount.accountNumber }}</li>
				<li class="list-group-item">Account currency: {{ bankAccount.accountCurrency }}</li>
				<li class="list-group-item">Bank: {{ bankAccount.financialInstitution !== null ? bankAccount.financialInstitution.name: '' }}</li>
				<li class="list-group-item">Branch: {{ bankAccount.branch }}</li>
				<li class="list-group-item">Branch code: {{ bankAccount.branchCode }}</li>
				<br>
			</ul>
		</div>
	</div>
</template>

<script type="text/javascript">
	export default{
		props: {
			bankAccounts: {
				type: Object,
				default: null
			}
		}
	}
</script>