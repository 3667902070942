export default class Invoice {
  constructor(
    invoiceNumber,
    existingCustomer,
    customerId,
    customer,
    products,
    invoiceDate,
    dueDate,
    summaryDescription,
    dateOfSupply,
    amountOfIndividualGoods,
    subTotal,
    currencyId,
    balanceDue,
    purchaseOrderAvailable,
    purchaseOrderId,
    paymentDetails,
    invoiceType,
    ourCompanyName,
    ourCompanyAddress
  ) {
    this.invoiceNumber = invoiceNumber;
    this.existingCustomer = existingCustomer;
    this.customerId = customerId;
    this.customer = customer;
    this.products = products;
    this.invoiceDate = invoiceDate;
    this.dueDate = dueDate;
    this.summaryDescription = summaryDescription;
    this.dateOfSupply = dateOfSupply;
    this.amountOfIndividualGoods = amountOfIndividualGoods;
    this.subTotal = subTotal;
    this.currencyId = currencyId;
    this.balanceDue = balanceDue;
    this.purchaseOrderAvailable = purchaseOrderAvailable;
    this.purchaseOrderId = purchaseOrderId;
    this.paymentDetails = paymentDetails;
    this.invoiceType = invoiceType;
    this.ourCompanyName = ourCompanyName;
    this.ourCompanyAddress = ourCompanyAddress;
  }
}
