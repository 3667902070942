<template>
  <div>
    <loading :show="show" :label="label">
      <div class="processing-row" slot="loading-container">
        <div class="text-center">
          <div slot="loading-spinner">
            <img src="@/assets/images/station-loading.gif" height="100" width="100"/>
          </div>
          <p class="text-center" slot="loading-text">{{ label }}</p>
        </div>
      </div>
    </loading>

    <div v-if="company !== null">
      <div class="panel" v-show="company.type === 'CARRIER'">
        <div class="panel-body">
          <div class="row">
            <div class="col-md-10">
              <p>Post your driver</p>
            </div>
            <div class="col-md-2">
              <span v-if="company.carrierType === 'OWN_OPERATOR'">
                <span v-if="company.trucks.length < 1">
                  <router-link to="new-driver" replace>
                    <button class="btn btn-success pull-right">New driver <span class="glyphicon glyphicon-plus"></span></button>
                  </router-link>
                </span>
              </span>
              <span v-if="company.carrierType === 'FLEET_OWNER'">
                <router-link to="new-driver" replace>
                  <button class="btn btn-success pull-right">New driver <span class="glyphicon glyphicon-plus"></span></button>
                </router-link>
              </span>
            </div>
          </div>
          </div>
        </div>
    </div>

    <div class="panel">
      <div class="panel-body">
        <form class="form">
          <div class="row">
            <div class="col-md-2">
                <div class="form-group">
                  <label>Registration Number</label>
                  <input type="text" name="registrationNumber" class="form-control" v-model="registrationNumber" placeholder="Enter registration number">
                </div>
              </div>
              <div class="col-md-2">
                <div class="form-group">
                   <label>Truck Number</label>
                  <input type="text" name="truckNumber" class="form-control" v-model="truckNumber" placeholder="Enter truck number">
                </div>
              </div>
              <div class="col-md-2">
                <div class="form-group">
                  <label>Trailer Type</label>
                  <select class="form-control" v-model="trailerType" required>
                    <option value="" disabled>Select option</option>
                    <option value="FLATBED">Flatbed</option>
                    <option value="VAN">Van</option>
                    <option value="REEFER">Reefer</option>
                    <option value="STEP_DECK">Step Deck</option>
                    <option value="POWER_ONLY">Power Only</option>
                    <option value="AUTO_CARRIER">Auto Carrier</option>
                    <option value="B_TRAIN">B Train</option>
                    <option value="BOX_TRUCK">Box Truck</option>
                    <option value="CONESTOGA">Consetoga</option>
                    <option value="CONTAINERS">Containers</option>
                    <option value="DOUBLE_DROP">Double Drop</option>
                    <option value="DRY_BULK">Dry Bulk</option>
                    <option value="DUMP_TRAILER">Dump Trailer</option>
                    <option value="HOPPER_BOTTOM">Hooper Bottom</option>
                    <option value="HOTSHOT">Hotshot</option>
                    <option value="LOWBOY">Lowboy</option>
                    <option value="TANKER">Tanker</option>
                  </select>
                </div>
              </div>
             <div class="col-md-2">
                <div class="form-group">
                   <label>Make</label>
                  <input type="text" name="make" class="form-control" v-model="make" placeholder="Enter make">
                </div>
              </div>
             <div class="col-md-2">
                <div class="form-group">
                   <label>Model</label>
                  <input type="text" name="model" class="form-control" v-model="model" placeholder="Enter model">
                </div>
              </div>
             <div class="col-md-2">
                <div class="form-group">
                  <label>Year</label>
                  <select class="form-control" v-model="year">
                    <option v-for="year in years" :value="year" :key="year">{{ year }}</option>
                  </select>
                </div>
              </div>
             <div class="col-md-2">
                <div class="form-group">
                 <label>VIN</label>
                <input type="text" name="vehicleIdentificationNumber" class="form-control" v-model="vehicleIdentificationNumber" placeholder="Enter VIN">
              </div>
            </div>
            <div class="col-md-8"></div>
            <div class="col-md-2">
              <div class="form-group">
                <label>&nbsp;</label><br>
                <button class="btn btn-block" type="button" @click="clearFilter()" style="background-color: #000000; color: #ffffff;">Clear filter</button>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
    <div class="panel">
      <div class="panel-body">
        <table class="table table-responsive table-bordered table-hover" v-if="company !== null">
          <thead class="bg-primary">
            <th>ID</th>
            <th>Name</th>
            <th>Allocated Truck</th>
            <th>Actions</th>
          </thead>
          <tbody>
            <tr v-for="driver in paginatedDriverList" :key="driver.id">
              <td>{{ driver.id }}</td>
              <td>{{ driver.firstName }} {{ driver.lastName }}</td>
              <td>{{ driver.truck }}</td>
              <td>
                <router-link :to="{ name: 'view-driver', params: { id: driver.id }}">
                  <button class="btn btn-primary col-4">View <span class="glyphicon glyphicon-info-sign"></span></button>
                </router-link>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <div class="row" v-if="totalPages > 1">
      <div class="col-md-7"></div>
      <div class="col-md-3 pull-right">
        <div class="panel">
          <div class="panel-body">
            <button class="btn btn-primary glyphicon glyphicon-menu-left" :disabled="currentPage === 1" @click="prevPage"></button>
            &nbsp;<span>Page {{ currentPage }} of {{ totalPages }}</span>&nbsp;
            <button class="btn btn-primary glyphicon glyphicon-menu-right" :disabled="currentPage === totalPages" @click="nextPage"></button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script type="text/javascript">
  import loading from "vue-full-loading";
  import CompanyService from "@/services/CompanyService";
  import CityService from "@/services/CityService";

  export default {
    name: "DriverList",
    components: {
      loading
    },

    data: function() {
      return {
        trucks: [],
        show: false,
        label: "Processing request",
        company: null,
        drivers: [],
        cities: [],
        currentPage: JSON.parse(localStorage.getItem('currentPage')) !== null ? JSON.parse(localStorage.getItem('currentPage')): 1,
        itemsPerPage: 3,
        registrationNumber: null,
        truckNumber: null,
        trailerType: null,
        make: null,
        model: null,
        year: null,
        vehicleIdentificationNumber: null
      };
    },

    created(){
      this.registrationNumber = JSON.parse(localStorage.getItem('registrationNumber'));
      this.truckNumber = JSON.parse(localStorage.getItem('truckNumber'));
      this.trailerType = JSON.parse(localStorage.getItem('trailerType'));
      this.make = JSON.parse(localStorage.getItem('make'));
      this.model = JSON.parse(localStorage.getItem('model'));
      this.year = JSON.parse(localStorage.getItem('year'));
      this.vehicleIdentificationNumber = JSON.parse(localStorage.getItem('vehicleIdentificationNumber'));
      this.findByAssociatedUserUsername();
    },

    computed: {
      getTruckNumber(){
        return this.$route.query.truckNumber;
      },
      totalPages(){
          return Math.ceil(this.filteredDrivers.length / this.itemsPerPage);
        },

        filteredDrivers(){
          let filteredDrivers;
          if ((this.registrationNumber !== null && this.registrationNumber !== undefined) || 
            (this.truckNumber !== null && this.truckNumber !== undefined) ||
            (this.trailerType !== null && this.trailerType !== undefined) || 
            (this.make !== null && this.make !== undefined) || 
            (this.model !== null && this.model !== undefined) || 
            (this.year !== null && this.year !== undefined) || 
            (this.vehicleIdentificationNumber !== null && this.vehicleIdentificationNumber !== undefined)) {

            localStorage.setItem('registrationNumber', JSON.stringify(this.registrationNumber));
            localStorage.setItem('truckNumber', JSON.stringify(this.truckNumber));
            localStorage.setItem('trailerType', JSON.stringify(this.trailerType));
            localStorage.setItem('make', JSON.stringify(this.make));
            localStorage.setItem('model', JSON.stringify(this.model));
            localStorage.setItem('year', JSON.stringify(this.year));
            localStorage.setItem('vehicleIdentificationNumber', JSON.stringify(this.vehicleIdentificationNumber));

            filteredDrivers = this.drivers.filter(truck => {
              return truck.registrationNumber === this.registrationNumber || 
                truck.truckNumber === this.truckNumber || 
                truck.trailerType === this.trailerType || 
                truck.make === this.make || 
                truck.model === this.model || 
                truck.year === this.year || 
                truck.vehicleIdentificationNumber === this.vehicleIdentificationNumber
            });
          }else{
            filteredDrivers = this.drivers;
          }
          return filteredDrivers;
        },

        paginatedDriverList(){
          const startIndex = (this.currentPage - 1) * this.itemsPerPage;
          return this.filteredDrivers.slice(startIndex, startIndex + this.itemsPerPage);
      }
    },

    methods: {
      findAllCities: function(){
        CityService.findAll().then(response => {
          this.cities = response.data.body;
        }).catch(error => {
          console.log(error);
        });
      },

      findByAssociatedUserUsername: function() {
        this.show = true;
        CompanyService.findByAssociatedUserUsername(this.$store.state.auth.user.user.username).then(response => {
          this.company = response.data.body;
          if (this.company !== null) {
            this.drivers = this.company.drivers;
          }
          this.show = false;
        }).catch(error => {
          console.log(error);
          this.show = false;
        });
      },

      prevPage(){
          if (this.currentPage > 1) {
            const currentPage = --this.currentPage;
            localStorage.setItem('currentPage', JSON.stringify(currentPage));
          }
        },

        nextPage(){
          if (this.currentPage < this.totalPages) {
            const currentPage = ++this.currentPage;
            localStorage.setItem('currentPage', JSON.stringify(currentPage));
          }
        },

        clearFilter(){
          localStorage.removeItem('registrationNumber');
          localStorage.removeItem('truckNumber');
          localStorage.removeItem('trailerType');
          localStorage.removeItem('make');
          localStorage.removeItem('model');
          localStorage.removeItem('year');
          localStorage.removeItem('vehicleIdentificationNumber');
          localStorage.removeItem('currentPage');
          window.location.reload();
      },

      years: function() {
          const year = new Date().getFullYear();
          const years = Array.from(
            { length: year - 1900 },
            (value, index) => 1901 + index
          );
          return years;
        },
    }
  };
</script>