<template>
  <div class="google-map" :id="mapName"></div>
</template>

<script>
	import * as VueGoogleMaps from 'vue2-google-maps';


export default {
  name: 'MapLoadDensity',
  props: {
    markerCoordinates: {
      type: Array,
      default: function(){
        return []
      }
    }
  },

  data: function () {
    return {
      mapName: 'google-map',
      // markerCoordinates: [{
      //   latitude: -18.9758,
      //   longitude: 32.6691
      // }, 
      // {
      //   latitude: -17.8216,
      //   longitude: 31.0492
      // }, {
      //   latitude: -18.9624,
      //   longitude: 32.6472
      // }],
      // markerCoordinates: [ { latitude: -18.9758, longitude: 32.6691 }, { latitude: -17.7802, longitude: 31.0119 }, { latitude: -19.4669, longitude: 29.8064 }, { latitude: -18.9758, longitude: 32.6691 }, { latitude: -18.1334, longitude: 30.1443 }, { latitude: -20.3164, longitude: 30.0552 }, { latitude: -13.1321, longitude: 27.8327 } ],
      map: null,
      bounds: null,
      markers: []
    }
  },

  created(){
  },

  computed: {
  	google: VueGoogleMaps.gmapApi,
  },

  mounted: function () {
  	this.$gmapApiPromiseLazy().then(() => {
  		this.bounds = new this.google.maps.LatLngBounds();
	    const element = document.getElementById(this.mapName)
	    const mapCentre = this.markerCoordinates[0]
	    const options = {
	      center: new this.google.maps.LatLng(mapCentre.latitude, mapCentre.longitude)
	    }
	    this.map = new this.google.maps.Map(element, options);
	    this.markerCoordinates.forEach((coord) => {
	      const position = new this.google.maps.LatLng(coord.latitude, coord.longitude);
	      const marker = new this.google.maps.Marker({ 
	        position,
	        map: this.map
	      });
	    this.markers.push(marker);
	    this.map.fitBounds(this.bounds.extend(position));
	    });
  	});
  }
};
</script>
<style scoped>
.google-map {
  width: 100%;
  height: 600px;
  margin: 0 auto;
  background: gray;
}
</style>