import Vue from "vue";
import VueRouter from "vue-router";

import Login from "@/views/auth/Login.vue";
import ForgotPassword from "@/views/auth/ForgotPassword.vue";
import ChangePassword from "@/views/auth/ChangePassword.vue";
import SubscriptionSuspended from "@/views/auth/SubscriptionSuspended.vue";
import CheckSubscription from "@/views/auth/CheckSubscription";
import SubscriptionExpired from "@/views/auth/SubscriptionExpired.vue";

import DashboardNavigation from "@/components/DashboardNavigation.vue";
import Settings from "@/views/administration/Settings";

import Dashboard from "@/views/dashboard/Dashboard.vue";
import HelpCenter from "@/views/help-center/HelpCenter.vue";
import NotFound from "@/components/NotFound.vue";

import NewBusinessDocuments from "@/views/attachments/NewBusinessDocuments.vue";

import PaymentList from "@/views/payments/PaymentList.vue";
import ViewPayment from "@/views/payments/ViewPayment.vue";

import CompanyList from "@/views/companies/CompanyList";
import NewCompany from "@/views/companies/NewCompany";
import ViewCompany from "@/views/companies/ViewCompany";

import AddressComponent from "@/views/locations/AddressComponent.vue";
import PostalAddressComponent from "@/views/locations/PostalAddressComponent.vue";
import NewPayment from "@/views/payments/NewPayment.vue";
import Chart from "@/views/charts/Chart.vue";

import SearchLoads from "@/views/loads/SearchLoads"
import ViewLoad from "@/views/loads/ViewLoad"

import UpdateLoadDeliveryStatus from "@/views/loads/UpdateLoadDeliveryStatus";

import TruckList from "@/views/trucks/TruckList";
import NewTruck from "@/views/trucks/NewTruck";
import ViewTruck from "@/views/trucks/ViewTruck";
import SearchTrucks from "@/views/trucks/SearchTrucks";
import EnableELDTracking from "@/views/trucks/EnableELDTracking";
import TruckRegularRoute from "@/views/trucks/TruckRegularRoute";
import AllocateTrailer from "@/views/trucks/AllocateTrailer";
import AllocateDriver from "@/views/trucks/AllocateDriver";

import TrailerList from "@/views/trailers/TrailerList";
import NewTrailer from "@/views/trailers/NewTrailer";
import ViewTrailer from "@/views/trailers/ViewTrailer";
import NewTrailerPictures from "@/views/trailers/NewTrailerPictures";

import CheckProfile from "@/views/profile/CheckProfile";
import NewBusinessProfile from "@/views/profile/NewBusinessProfile";

import TmsIntegration from "@/views/tms/TmsIntegration";

import ApiIntegration from "@/views/apiIntegration/ApiIntegration";

import LoadDensity from "@/views/loads/LoadDensity";
import LoadAlertLoads from "@/views/loads/LoadAlertLoads";

import MyLoadList from "@/views/loads/MyLoadList";
import CarrierMyLoadList from "@/views/loads/CarrierMyLoadList";

import BusinessProfile from "@/views/profile/BusinessProfile";

import NewLoadInformation from "@/views/loads/NewLoadInformation";
import NewLoadTiming from "@/views/loads/NewLoadTiming";
import NewContactInformationForCarrier from "@/views/loads/NewContactInformationForCarrier";
import NewOtherLoadDetails from "@/views/loads/NewOtherLoadDetails";
import NewLoadPriceAndOffers from "@/views/loads/NewLoadPriceAndOffers";

import TestPayment from "@/views/payments/TestPayment"

import OTP from "@/views/auth/OTP";
import PaymentUpdate from "@/views/payments/PaymentUpdate.vue";
import PaymentResponse from "@/views/payments/PaymentResponse.vue";
import MakePayment from "@/views/payments/MakePayment.vue";
import ProofOfPayment from "@/views/payments/ProofOfPayment.vue";

import SubscriptionList from "@/views/subscriptions/SubscriptionList.vue";
import NewSubscription from "@/views/subscriptions/NewSubscription.vue";
import MySubscription from "@/views/subscriptions/MySubscription.vue";
import SubscriptionPaymentResponse from "@/views/payments/SubscriptionPaymentResponse.vue";
import ViewSubscription from "@/views/subscriptions/ViewSubscription.vue";

import TestComponent from "@/views/TestComponent.vue";


import MyLoadPayments from "@/views/payments/MyLoadPayments.vue";

import VerifyCompany from "@/views/companies/VerifyCompany.vue";

import NewTruckPictures from "@/views/trucks/NewTruckPictures";
import UpgradeSubscription from "@/views/subscriptions/UpgradeSubscription";
import DowngradeSubscription from "@/views/subscriptions/DowngradeSubscription";
import CancelSubscription from "@/views/subscriptions/CancelSubscription";


import OperationsDashboard from "@/views/dashboard/OperationsDashboard";
import CustomersDashboard from "@/views/dashboard/CustomersDashboard";
import FleetDashboard from "@/views/dashboard/FleetDashboard";
import AccountingDashboard from "@/views/dashboard/AccountingDashboard";
import ReportingDashboard from "@/views/dashboard/ReportingDashboard";
import SettingsDashboard from "@/views/dashboard/SettingsDashboard";
import GeneralSettings from "@/views/settings/GeneralSettings";
import CarrierPaymentsDashboard from "@/views/dashboard/CarrierPaymentsDashboard";

import ShipmentsDashboard from "@/views/dashboard/ShipmentsDashboard";

import LoadProfile from "@/views/operations/loads/LoadProfile";
import LoadInformation from "@/views/operations/loads/LoadInformation";
import NewCustomer from "@/views/customers/NewCustomer";
import CustomersList from "@/views/customers/CustomersList";
import NewCustomerAddress from "@/views/customers/NewCustomerAddress";
import NewCustomerPostalAddress from "@/views/customers/NewCustomerPostalAddress";
import ViewCustomer from "@/views/customers/ViewCustomer";
import NewCustomerContactPeople from "@/views/customers/NewCustomerContactPeople";


import FacilityList from "@/views/facilities/FacilityList";
import Carriers from "@/views/facilities/Carriers";

import Dispatch from "@/views/dispatch/Dispatch";

import DriverList from "@/views/drivers/DriverList";
import NewDriver from "@/views/drivers/NewDriver";
import NewDriverAddress from "@/views/drivers/NewDriverAddress";
import NewDriverPostalAddress from "@/views/drivers/NewDriverPostalAddress";
import ViewDriver from "@/views/drivers/ViewDriver";
import NewDriverDocuments from "@/views/drivers/NewDriverDocuments";
import NewDriverAccounting from "@/views/drivers/NewDriverAccounting";
import HoursOfService from "@/views/drivers/HoursOfService";

import InvoiceList from "@/views/invoices/InvoiceList";
import ViewInvoice from "@/views/invoices/ViewInvoice";

import NewAdditionalCharge from "@/views/additionalCharges/NewAdditionalCharge";
import UserProfile from "@/views/users/UserProfile";

Vue.use(VueRouter);

const routes = [
  {
    path: "*",
    component: NotFound
  },

  {
    path: "/",
    name: "Login",
    component: Login
  },

  {
    path: "/otp",
    name: "otp",
    component: OTP
  },

  {
    path: "/check-subscription",
    name: "check-subscription",
    component: CheckSubscription
  },

  {
    path: "/password-reset",
    name: "password-reset",
    component: ChangePassword
  },

  {
    path: "/new-business-profile",
    name: "new-business-profile",
    component: NewBusinessProfile
  },

  {
    path: "/new-business-profile/address/:uuid",
    name: "new-business-profile/address",
    component: AddressComponent
  },
  
  {
    path: "/new-business-profile/postalAddress/:uuid",
    name: "new-business-profile/postalAddress",
    component: PostalAddressComponent
  },

  {
    path: "/new-business-profile/business-documents/:uuid",
    name: "new-business-profile/business-documents",
    component: NewBusinessDocuments
  },

  {
    path: "/new-business-profile/subscriptions/:uuid",
    name: "new-business-profile/subscriptions",
    component: NewSubscription
  },

  {
    path: "/companies/subscriptions/paymentResponse/:companyId",
    name: "companies/subscriptions/paymentResponse",
    component: SubscriptionPaymentResponse
  },

  {
    path: "/init/abstract-bean-factory-initializer-response",
    name: "init/abstract-bean-factory-initializer-response",
    component: SubscriptionPaymentResponse
  },
  
  {
    path: "/login",
    name: "login",
    component: Login
  },

  {
    path: "/forgotPassword",
    name: "forgotPassword",
    component: ForgotPassword
  },

   {
    path: "/subscription-expired",
    name: "subscription-expired",
    component: SubscriptionExpired
  },

  {
    path: "/subscription-suspended",
    name: "subscription-suspended",
    component: SubscriptionSuspended
  },

  {
    path: "/dashboardNavigation",
    name: "dashboardNavigation",
    component: DashboardNavigation,
    children: [
      {
        path: "/userProfile",
        name: "userProfile",
        component: UserProfile
      },

      {
        path: "/operations-dashboard",
        name: "operations-dashboard",
        component: OperationsDashboard
      },

      {
        path: "/load-profile",
        name: "load-profile",
        component: LoadProfile
      },

      {
        path: "/load-information",
        name: "load-information",
        component: LoadInformation
      },

      {
        path: "/customers-dashboard",
        name: "customers-dashboard",
        component: CustomersDashboard,
        children: [
          {
            path: "/new-customer",
            name: "new-customer",
            component: NewCustomer
          },

          {
            path: "/customer-list",
            name: "customer-list",
            component: CustomersList
          },

          {
            path: "/new-customer-address/:id",
            name: "new-customer-address",
            component: NewCustomerAddress
          },

          {
            path: "/new-customer-postal-address/:id",
            name: "new-customer-postal-address",
            component: NewCustomerPostalAddress
          },

          {
            path: "/view-customer/:id",
            name: "view-customer",
            component: ViewCustomer
          },

          {
            path: "/new-customer-contact-people/:id",
            name: "new-customer-contact-people",
            component: NewCustomerContactPeople
          },

          {
            path: "/facilitiy-list",
            name: "facilitiy-list",
            component: FacilityList
          },

          {
            path: "/carrier-list",
            name: "carrier-list",
            component: Carriers
          }
        ]
      },

      {
        path: "/fleet-dashboard",
        name: "fleet-dashboard",
        component: FleetDashboard,
        children: [
          {
            path: "/truck-list",
            name: "truck-list",
            component: TruckList
          },

            {
            path: "/new-truck",
            name: "new-truck",
            component: NewTruck
          },

          {
            path: "/truck-pictures/:truckNumber",
            name: "truck-pictures",
            component: NewTruckPictures
          }, 

          {
            path: "/view-truck/:truckNumber",
            name: "view-truck",
            component: ViewTruck
          },

          {
            path: "/allocate-driver/:truckNumber",
            name: "allocate-driver",
            component: AllocateDriver
          },

          {
            path: "/enable-eld-tracking",
            name: "enable-eld-tracking",
            component: EnableELDTracking
          },

          {
            path: "/allocate-trailer/:trailerNumber/:truckNumber",
            name: "allocate-trailer",
            component: AllocateTrailer
          },

          {
            path: "/regular-route/:truckNumber",
            name: "regular-route",
            component: TruckRegularRoute
          },

          {
            path: "/trailer-list",
            name: "trailer-list",
            component: TrailerList
          },

          {
            path: "/new-trailer",
            name: "new-trailer",
            component: NewTrailer
          },

          {
            path: "/trailer-pictures/:trailerNumber",
            name: "trailer-pictures",
            component: NewTrailerPictures
          }, 

          {
            path: "/view-trailer/:trailerNumber",
            name: "view-trailer",
            component: ViewTrailer
          },

          {
            path: "/driver-list",
            name: "driver-list",
            component: DriverList
          },

          {
            path: "/new-driver",
            name: "new-driver",
            component: NewDriver
          },

          {
            path: "/new-driver-address/:id",
            name: "new-driver-address",
            component: NewDriverAddress
          },

          {
            path: "/new-driver-postal-address/:id",
            name: "new-driver-postal-address",
            component: NewDriverPostalAddress
          },

          {
            path: "/view-driver/:id",
            name: "view-driver",
            component: ViewDriver
          },

          {
            path: "/new-driver-documents",
            name: "new-driver-documents",
            component: NewDriverDocuments
          },

          {
            path: "/new-driver-accounting",
            name: "new-driver-accounting",
            component: NewDriverAccounting
          },

          {
            path: "/new-driver-hours-of-service",
            name: "new-driver-hours-of-service",
            component: HoursOfService
          },

          {
            path: "/dispatch",
            name: "dispatch",
            component: Dispatch
          },

          {
            path: "/new-additional-charge",
            name: "new-additional-charge",
            component: NewAdditionalCharge
          }
        ]
      },

      {
        path: "/accounting-dashboard",
        name: "accounting-dashboard",
        component: AccountingDashboard,
        children: [
          {
            path: "/invoice-list",
            name: "invoice-list",
            component: InvoiceList
          },

          {
            path: "/payments-list",
            name: "payments-list",
            component: MyLoadPayments
          },

          {
            path: "/view-invoice/:invoiceNumber",
            name: "view-invoice",
            component: ViewInvoice
          },
        ]
      },

      {
        path: "/reporting-dashboard",
        name: "reporting-dashboard",
        component: ReportingDashboard
      },

      {
        path: "/settings-dashboard",
        name: "settings-dashboard",
        component: SettingsDashboard,
        children: [
          {
            path: "/general-settings",
            name: "general-settings",
            component: GeneralSettings,
          }
        ]
      },

      {
        path: "/carrier-my-payments",
        name: "carrier-my-payments",
        component: CarrierPaymentsDashboard
      },

      {
        path: "/shipments",
        name: "shipments",
        component: ShipmentsDashboard
      },

      {
        path: "/upgrade-subscription",
        name: "upgrade-subscription",
        component: UpgradeSubscription
      },

      {
        path: "/downgrade-subscription",
        name: "downgrade-subscription",
        component: DowngradeSubscription
      },

      {
        path: "/cancel-subscription",
        name: "cancel-subscription",
        component: CancelSubscription
      },

      {
        path: "/testComponent",
        name: "testComponent",
        component: TestComponent
      },

      {
        path: "/subscriptions",
        name: "subscriptions",
        component: SubscriptionList
      },

      {
        path: "/subscriptions/view/:subscriptionNumber",
        name: "subscriptions/view",
        component: ViewSubscription
      },

      {
        path: "/my-subscription",
        name: "my-subscription",
        component: MySubscription
      },

      {
        path: "/payments/paymentUpdate",
        name: "payments/paymentUpdate",
        component: PaymentUpdate
      },

      {
        path: "/post-init/abstract-singleton-proxy-factory-response",
        name: "post-init/abstract-singleton-proxy-factory-response",
        component: PaymentResponse
      },

      {
        path: "/payments/makePayment",
        name: "payments/makePayment",
        component: MakePayment
      },

      {
        path: "/payments/proofOfPayment",
        name: "payments/proofOfPayment",
        component: ProofOfPayment
      },

      {
        path: "/business-profile",
        name: "business-profile",
        component: BusinessProfile
      },

      {
        path: "/profile/check",
        name: "profile/check",
        component: CheckProfile
      },

      {
        path: "/shipper/testPayment",
        name: "shipper/testPayment",
        component: TestPayment
      },

      {
        path: "/new-load-basic-details",
        name: "new-load-basic-details",
        component: NewLoadInformation
      },

      {
        path: "/new-load-timing/:loadNumber",
        name: "new-load-timing",
        component: NewLoadTiming
      },

      {
        path: "/new-load-price-and-offers/:loadNumber",
        name: "new-load-price-and-offers",
        component: NewLoadPriceAndOffers
      },

      {
        path: "/new-load-contact-information-for-carrier/:loadNumber",
        name: "new-load-contact-information-for-carrier",
        component: NewContactInformationForCarrier
      },

      {
        path: "/loads/new/otherLoadDetails/:loadNumber",
        name: "loads/new/otherLoadDetails",
        component: NewOtherLoadDetails
      },

      {
        path: "/search-loads",
        name: "search-loads",
        component: SearchLoads
      },

      {
        path: "/carrier/loads/my-loads",
        name: "carrier/loads/my-loads",
        component: CarrierMyLoadList
      },

      {
        path: "/carrier/loads/load-alerts/view/:loadAlertNumber",
        name: "carrier/loads/load-alerts/view",
        component: LoadAlertLoads
      },

      {
        path: "/load-density",
        name: "load-density",
        component: LoadDensity
      },

      {
        path: "/my-loads",
        name: "my-loads",
        component: MyLoadList
      },

      {
        path: "/broker/loads/tms-integration",
        name: "broker/loads/tms-integration",
        component: TmsIntegration
      },

      {
        path: "/broker/loads/api-integration",
        name: "broker/loads/api-integration",
        component: ApiIntegration
      },

      {
        path: "/view-load/:loadNumber",
        name: "view-load",
        component: ViewLoad
      },

      {
        path: "/loads/update-status/:loadNumber/:bookingNumber",
        name: "loads/update-status",
        component: UpdateLoadDeliveryStatus
      },

      {
        path: "/payments",
        name: "payments",
        component: PaymentList
      },

      {
        path: "/search-trucks",
        name: "search-trucks",
        component: SearchTrucks
      },

      {
        path: "/charts",
        name: "charts",
        component: Chart
      },

      {
        path: "/dashboard",
        name: "dashboard",
        component: Dashboard
      },

      {
        path: "/proposals/viewProofOfPayment/:proposalId",
        name: "proposals/viewProofOfPayment",
        component: ViewPayment
      },

      {
        path: "/proposals/payments/:proposalId",
        name: "proposals/payments",
        component: NewPayment
      },

      {
        path: "/view-payment/:invoiceNumber",
        name: "view-payment",
        component: ViewPayment
      },

      {
        path: "/companies",
        name: "companies",
        component: CompanyList
      },
      {
        path: "/companies/new",
        name: "companies/new",
        component: NewCompany
      },
      
      {
        path: "/companies/view/:uuid",
        name: "companies/view",
        component: ViewCompany
      },

      {
        path: "/companies/verify/:uuid",
        name: "companies/verify",
        component: VerifyCompany
      },

      {
        path: "/settings",
        name: "settings",
        component: Settings
      },
      
      {
        path: "/help-center",
        name: "help-center",
        component: HelpCenter
      },

      {
        path: "/payments/new",
        name: "payments/new",
        component: NewPayment
      }
    ]
  }
];

const router = new VueRouter({
  // mode: "history", // # on routes :: may leave it there
  base: process.env.BASE_URL, // change the base url to app later on
  routes
});

router.beforeEach((to, from, next) => {
  const publicPages = [ 
    "login", "otp", "forgotPassword", "profile/check", "new-business-profile",
    "new-business-profile/address", "new-business-profile/postalAddress", "new-business-profile/business-documents",
    "new-business-profile/bank-accounts", "new-business-profile/subscriptions", 
    "init/abstract-bean-factory-initializer-response", "password-reset", "subscription-suspended"
  ];
  const authorizedPages = !publicPages.includes(to.name);
  const loggedIn = JSON.parse(localStorage.getItem("user"));
  if (authorizedPages && !loggedIn) {
    next({
      path: '/login',
      query: {
        redirect: to.fullPath
      }
    });
  } else {
    next();
  }
});

export default router;
