export default class Driver {
  constructor(id, carrierUsername, personType, driverNumber, salutation, firstName, lastName, email, phoneNumbers, 
  	address, postalAddress, description, status) {
  	this.id = id;
    this.carrierUsername = carrierUsername;
    this.personType = personType;
    this.driverNumber = driverNumber;
    this.salutation = salutation;
    this.firstName = firstName;
    this.lastName = lastName;
    this.email = email;
    this.phoneNumbers = phoneNumbers;
    this.address = address;
    this.postalAddress = postalAddress;
    this.description = description;
    this.status = status;
  }
}
