import axios from "axios";
import authHeader from "@/services/AuthHeaderService.js";
import { AUTH_API_URL } from "@/config/AuthServiceConfig.js";
import { REGISTRATION_SERVICE_URL } from "@/config/RegistrationServiceConfig.js";

class UserService {

  findAll() {
    return axios.get(AUTH_API_URL + "users", { headers: authHeader() });
  }

  findByEmail(email) {
    return axios.get(AUTH_API_URL + "users/findByEmail/" + email, {
      headers: authHeader()
    });
  }

  findByUsername(username) {
    return axios.get(AUTH_API_URL + "users/findByUsername/" + username, {
      headers: authHeader()
    });
  }

  findById(id) {
    return axios.get(AUTH_API_URL + "users/findById/" + id, {
      headers: authHeader()
    });
  }

  register(user) {
    return axios.post(REGISTRATION_SERVICE_URL + "users/register", {
      personType: user.personType,
      tenantId: user.tenantId,
      username: user.username,
      email: user.email,
      password: user.password,
      confirmPassword: user.confirmPassword,
      accountNonExpired: user.accountNonExpired,
      accountNonLocked: user.accountNonLocked,
      credentialsNonExpired: user.credentialsNonExpired,
      enabled: user.enabled,
      roles: user.roles,
      firstName: user.firstName,
      middleName: user.middleName,
      lastName: user.lastName,
      nickname: user.nickname,
      dateOfBirth: user.dateOfBirth,
      phoneNumbers: user.phoneNumbers,
      faxNumber: user.faxNumber,
      skypeAddress: user.skypeAddress,
      gender: user.gender,
      salutation: user.salutation,
      maritalStatus: user.maritalStatus,
      nationalIdNumber: user.nationalIdNumber,
      passportNumber: user.passportNumber,
      driversLicenceNumber: user.driversLicenceNumber,
      nationality: user.nationality,
      placeOfBirth: user.placeOfBirth,
      countryOfBirth: user.countryOfBirth,
      homeAddress: user.homeAddress,
      ethnicGroup: user.ethnicGroup,
      religion: user.religion,
      digitalSignature: user.digitalSignature,
      photo: user.photo,
      occupation: user.occupation
    });
  }

  saveDigitalSignature(formData){
    return axios.post(AUTH_API_URL + "users/saveDigitalSignature", formData, { headers: authHeader() });
  }

  savePhoto(formData){
    return axios.post(AUTH_API_URL + "users/savePhoto", formData, { headers: authHeader() });
  }
}

export default new UserService();
