<template>
  <div>
    <loading :show="show" :label="label">
      <div class="processing-row" slot="loading-container">
        <div class="text-center">
          <div slot="loading-spinner">
            <img src="@/assets/images/station-loading.gif" height="100" width="100"/>
          </div>
          <p class="text-center" slot="loading-text">{{ label }}</p>
        </div>
      </div>
    </loading>

    <div class="panel">
      <img src="@/assets/logo.png" width="95" height="65" class="img img-responsive img-rounded"/>        
    </div>
    
    <div class="panel">
      <div class="panel-body">
        <h4>Business Address</h4>
        <p class="error-message text-center" v-if="message != null">{{ message }}</p>
        <form class="form" @submit.prevent="save">
          <div class="form-group">
            <label>City</label>
            <select class="form-control" v-model="physicalAddress.cityId" required>
              <option value="">Select City</option>
              <option v-for="city in cities" v-bind:key="city.id" v-bind:value="city.id">{{ city.name }}</option>
            </select>
          </div>
            
          <div class="form-group">
            <label>Physical Address</label>
            <textarea rows="5" class="form-control" v-model="physicalAddress.physicalAddress" placeholder="Enter Physical Address" required></textarea>
          </div>

          <div class="form-group">
              <input type="checkbox" v-model="physicalAddress.sameAsPostalAddress"/> Tick here if the address is the same as postal address
          </div>
          <br/>

          <div class="row">
            <div class="col-md-2">
              <router-link :to="{ name: 'newBusinessProfile' }">
                <!-- <button class="btn btn-default btn-block">Back</button> -->
              </router-link>
            </div>
            <div class="col-md-8"></div>
            <div class="col-md-2">
              <button class="btn btn-success btn-block pull-right" type="submit">Submit</button>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script type="text/javascript">
  import CompanyService from "@/services/CompanyService.js";
  import CityService from "@/services/CityService.js";
  import Company from "@/models/Company.js";
  import loading from "vue-full-loading";

  export default {
    components: {
      loading
    },
    name: "AddressComponent",
    data: function() {
      return {
        show: false,
        label: "Processing request...",
        cities: [],
        errorMessage: null,
        errors: null,
        physicalAddress: {
          zipCodeId: null,
          countryId: null,
          provinceId: null,
          cityId: null,
          areaId: null,
          physicalAddress: null,
          sameAsPostalAddress: false
        },
        company: new Company,
        message: null
      };
    },
    computed: {
      getUuid() {
        return this.$route.params.uuid;
      }
    },

    created() {
      console.log(JSON.parse(localStorage.getItem('username')));
      this.findAllCities();
    },

    methods: {
      findAllCities: function() {
        CityService.findAll().then(response => {
          this.cities = response.data.body;
        }).catch(error => {
          console.log(error);
        });
      },

      save: function() {
        this.show = true;
        this.company.uuid = this.getUuid;
        this.company.physicalAddress = this.physicalAddress;
        CompanyService.save(this.company).then(response => {
          if (response.data.statusCode === 422) {
            this.message = response.data.message;
          }
          if (response.data.statusCode === 400) {
            this.message = response.data.message;
          }
          if (response.data.statusCode === 500) {
            this.message = response.data.message;
          }
          if (response.data.statusCode === 201) {
            if (this.physicalAddress.sameAsPostalAddress) {
              this.$router.push({ name: "new-business-profile/business-documents", params: { uuid: this.getUuid }});
            }else{
              this.$router.push({ name: "new-business-profile/postalAddress", params: { uuid: this.getUuid }});
            }
          }
          this.show = false;
        }).catch(error => {
          if (error.response.status === 500) {
            this.message = error.response.data.message;
          }
          this.show = false;
        });
      }
    }
  };
</script>
