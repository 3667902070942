<template>
	<div>
		<loading :show="show" :label="label">
	      <div class="processing-row" slot="loading-container">
	        <div class="text-center">
	          <div slot="loading-spinner">
	            <img src="@/assets/images/station-loading.gif" height="100" width="100"/>
	          </div>
	          <p class="text-center" slot="loading-text">{{ label }}</p>
	        </div>
	      </div>
	    </loading>

	    <div class="panel">
	    	<div class="panel-body">
	    		<router-link :to="{ name: 'trailer-list'}">
	    			<button class="btn btn-default pull-right">Cancel</button>
              	</router-link>
              	<br><br><br>

              	<fieldset v-if="carrierDrivers.length > 0">
              		<h4>Are you sure you want to allocate truck number <span class="badge">{{ getTruckNumber }}</span> to driver <span class="badge">{{ getTruckNumber }}</span></h4>
              		<br>
              		<form class="form" @submit.prevent="allocateTruck">
              			<div class="col-md-3">
              				<label>Choose driver</label>
              				<select class="form-control" v-model="driver.driverNumber" required>
              					<option value="">Select option</option>
              					<option v-for="carrierDriver in carrierDrivers" :key="carrierDriver.id" :value="carrierDriver.driver.driverNumber">{{ carrierDriver.driver.driverNumber }} - {{ carrierDriver.driver.firstName }} {{ carrierDriver.driver.lastName }} - {{ carrierDriver.driver.email }}</option>
              				</select>
              			</div>
						<div class="row">
							<div class="col-md-10"></div>
							<div class="col-md-2">
								<div class="form-group">
									<label>&nbsp;</label>
									<button class="btn btn-primary btn-block">Yes allocate</button>
								</div>
							</div>
						</div>
					</form>
				</fieldset>
				<span v-if="carrierDrivers.length === 0">No drivers found. <router-link :to="{ name: 'new-driver' }">Please add driver here</router-link></span>
	    	</div>
	    </div>
	</div>
</template>

<script type="text/javascript">
	import loading from "vue-full-loading";
	import DriverService from "@/services/DriverService";
	import Driver from "@/models/Driver";
	import CarrierDriverService from "@/services/CarrierDriverService";

	export default{
		name: 'AllocateTrailer',
		components: {
			loading,
		},

		data: function(){
			return {
				label: "Processing request",
				show: false,
				driver: new Driver,
				carrierDrivers: []
			}
		},

		created(){
			this.findByCarrierAssociatedUserUsername();
		},

		computed: {
			getTruckNumber: function() {
				return this.$route.params.truckNumber;
			},

			getTrailerNumber: function() {
				return this.$route.params.trailerNumber;
			},

			getUser: function() {
				return this.$store.state.auth.user.user;
			}
		},

		methods: {
			findByCarrierAssociatedUserUsername: function(){
				this.show = true;
				CarrierDriverService.findByCarrierAssociatedUserUsername(this.getUser.username).then(response => {
					if (response.data.statusCode === 200) {
						this.carrierDrivers = response.data.body;
					}
					this.show = false;
				}).catch(error => {
					console.log(error);
					this.show = false;
				});
			},

			allocateTruck: function(){
				this.show = true;
				DriverService.allocateTruck(this.driver.driverNumber, this.getTruckNumber).then(response => {
					if (response.data.statusCode === 201) {
						this.$router.push({ name: 'truck-list' });
					}
					this.show = false;
				}).catch(error => {
					console.log(error);
					this.show = false;
				});
			}
		}
	};
</script>