<template>
  <div>
  	<loading :show="show" :label="label">
      <div class="processing-row" slot="loading-container">
        <div class="text-center">
          <div slot="loading-spinner">
            <img src="@/assets/images/LucecoPLC-Loading.gif" height="100" width="100"/>
          </div>
          <p class="text-center" slot="loading-text">{{ label }}</p>
        </div>
      </div>
    </loading>

  	<div class="panel">
  		<div class="panel-body">
  			<div class="row" v-if="subscription !== null">
  				<div class="col-md-10">
  					<h4>Active subscription</h4>
  				</div>
  				<div class="col-md-2">
  					<span v-if="subscription.subscriptionPlan.subscriptionType !== 'PREMIUM'">
  						<router-link :to="{ name: 'upgrade-subscription' }">
  							<button class="btn btn-primary btn-block">Upgrade</button>
  						</router-link>
  					</span>
  				</div>
  			</div>
  			<br>
		  	<div v-if="subscription !== null">
		  		<div class="list-group">
			  		<li class="list-group-item"><h4>Subscription number: {{ subscription.subscriptionNumber }}</h4></li>
			  		<li class="list-group-item"><h4>Subscription details</h4>
			  			<div class="list-group">
			  				<li class="list-group-item">Name: {{ subscription.subscriptionPlan.name }}</li>
			  				<li class="list-group-item">Subscription type: {{ subscription.subscriptionPlan.subscriptionType }}</li>
			  				<li class="list-group-item">Subscription price {{ subscription.subscriptionPlan.currency }}{{ subscription.subscriptionPlan.price }}</li>
			  			</div>
			  		</li>
			  		<li class="list-group-item">Start Date: {{ subscription.startDate }}</li>
			  		<li class="list-group-item">Expires on: {{ subscription.endDate }}</li>
			  		<li class="list-group-item">Status  
			  			<span class="label label-success" v-show="!subscription.expired">Active</span>
			  			<span class="label label-default" v-show="subscription.expired">Expired</span>
			  		</li>
			  		<li class="list-group-item">
			  			Subscription invoice <a :href="PDF_API_URL + 'pdf/generateInvoice/' + subscription.invoice.id " target="_blank" class="btn btn-primary">View Invoice</a>
			  		</li>
			  	</div>
		  	</div>
		  	<div v-else>
		  		<h4 class="text-danger">No subscription available</h4>
		  	</div>
  		</div>
  	</div>
  </div>
</template>

<script type="text/javascript">
	import loading from "vue-full-loading";
	import CompanyService from "@/services/CompanyService";
	import SubscriptionService from "@/services/SubscriptionService";
	import { PDF_API_URL } from "@/config/PdfServiceConfig.js";

	export default {
	  name: "MySubscription",
	  components: {
	  	loading
	  },

	  data: function() {
	    return {
	    	show: false,
        	label: "Processing request",
        	company: null,
        	subscription: null,
        	PDF_API_URL: PDF_API_URL,
	    };
	  },

	  computed: {
	    getZipCodeId() {
	      return this.$route.params.zipCodeId;
	    },

	    currentUser() {
	    	return this.$store.state.auth.user;
		},
	  },

	  created: function() {
	    //this.findByAssociatedUserUsername();
	    this.findByActiveSubscription();
	  },

	  methods: {
	    findByAssociatedUserUsername: function() {
	        this.show = true;
	        CompanyService.findByAssociatedUserUsername(this.$store.state.auth.user.user.username).then(response => {
	        	if (response.data.statusCode == 200) {
	        		this.company = response.data.body;
	        		this.subscription = this.company.subscription;
	        	}
	        	this.show = false;
	        }).catch(error => {
	        	console.log(error);
	        	this.show = false;
	        });
	    },

	    findByActiveSubscription: function(){
	        this.show = true;
	        SubscriptionService.findByActiveSubscription(this.currentUser.user.username).then(response => {
	        	if (response.data.statusCode === 200) {
	        		this.subscription = response.data.body;
	        	}
	          this.show = false;
	        }).catch(error => {
	          if (error.response.status === 500) {
	            this.message = error.response.data.message;
	          }
	          this.show = false;
	        });
	    },
	  }
	};
</script>
