<template>
  <div> 
    <ul class="list-group">
      <ul class="list-group">
        <li class="list-group-item">
          <div v-if="directors.length !== 0">
            <ul class="list-group" v-for="(director, index) in directors" :key="director.id">
              <h4>Director Number {{ index + 1 }}</h4>
              <li class="list-group-item">Person ID: {{ director.uuid }}</li>
              <li class="list-group-item">First Name: {{ director.firstName }}</li>
              <li class="list-group-item">Middle name: {{ director.middleName }}</li>
              <li class="list-group-item">Last Name: {{ director.lastName }}</li>
              <li class="list-group-item">Nickname: {{ director.nickname }}</li>
              <li class="list-group-item">Email: {{ director.email}}</li>
              <li class="list-group-item">Date of birth: {{ director.dateOfBirth }}</li>
              <li class="list-group-item">
                Phone Number(s)
                <ul class="list-group" v-for="phoneNumber in director.phoneNumbers" :key="phoneNumber.id">
                  <li class="list-group-item">{{ phoneNumber.phoneNumber }}</li>
                </ul>
              </li>
              <li class="list-group-item">Gender: {{ director.gender }}</li>
              <li class="list-group-item">Salutation: {{ director.salutation }}</li>
              <li class="list-group-item">Fax Number: {{ director.faxNumber }}</li>
              <li class="list-group-item">Skype Address: {{ director.skypeAddress }}</li>
              <li class="list-group-item">Occupation: {{ director.occupation === null ? "": director.occupation.name }}</li>
              <li class="list-group-item">National ID Number: {{ director.nationalIdNumber }}</li>
              <li class="list-group-item">Passport Number: {{ director.passportNumber }}</li>
              <li class="list-group-item">Drivers Licence Number: {{ director.driversLicenceNumber }}</li>
              <li class="list-group-item">Nationality: {{ director.nationality }}</li>
              <li class="list-group-item">Home Address: {{ director.homeAddress === null ? "": director.homeAddress.physicalAddress }}</li>
              <li class="list-group-item">Postal Address: {{ director.postalAddress === null ? "": director.postalAddress.physicalAddress }}</li>
              <li class="list-group-item">Marital status: {{ director.maritalStatus }}</li>
              <li class="list-group-item">Place of birth: {{ director.placeOfBirth }}</li>
              <li class="list-group-item">Country of birth: {{ director.countryOfBirth }}</li>
              <li class="list-group-item">Ethnic Group: {{ director.ethnicGroup }}</li>
              <li class="list-group-item">Photo: {{ director.photo }}</li>
              <li class="list-group-item">Digital Signature: {{ director.digitalSignature }}</li>
            </ul>
          </div>
        </li>
      </ul>
    </ul>
  </div>
</template>

<script type="text/javascript">
  export default{
    name: "DirectorsComponent",
    data: function(){
      return {

      }
    },

    props: {
      directors: {
        type: Array,
        default: function(){
          return [];
        }
      }
    }
  }  
</script>