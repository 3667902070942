<template>
  <div class="login-background">
    <loading :show="show" :label="label">
      <div class="processing-row" slot="loading-container">
        <div class="text-center">
          <div slot="loading-spinner">
            <img src="@/assets/images/LucecoPLC-Loading.gif" height="100" width="100"/>
          </div>
          <p class="text-center" slot="loading-text">{{ label }}</p>
        </div>
      </div>
    </loading>


   <div class="panel">
      <img src="@/assets/logo.png" width="260" height="80" class="img img-responsive img-rounded"/>  
    </div>

    <br><br><br><br><br><br><br><br>
    <div class="row">
      <div class="col-md-3"></div>
      <div class="col-md-6">
        <h3 class="text-center" style="color: #ffffff;">Sign in to Nelspruit Freight TMS</h3>
        <p class="text-center" style="color: #ffffff;">The new horizon of freight and shipment management solution</p>
        <div class="panel">
          <div class="panel-header"><div class="header-title"></div></div>
          <div class="panel-body">
            <p class="error-message">{{ message }}</p>
            <form class="form" @submit.prevent="login">
              <div class="form-group">
                <div class="input-group">
                  <div class="input-group-addon"><span class="glyphicon glyphicon-user"></span></div>
                  <input type="text" v-model="user.username" placeholder="Enter username" class="form-control" autocomplete="on" required/>
                </div>
              </div>
              <br>
              <div class="form-group">
                <div class="input-group">
                  <div class="input-group-addon"><span class="glyphicon glyphicon-lock"></span></div>
                  <input type="password" v-model="user.password" placeholder="Enter password" class="form-control" autocomplete="on" required/>
                </div>
              </div>

              <div class="form-group">
                <label>&nbsp;</label>
                <button class="btn btn-success btn-block"><b>Login</b></button>
              </div>
              <p>Use your Nelspruit Freight credentials to login to TMS. We have Single Sign On synced your account. <span class="pull-right"><router-link to="forgotPassword"><img src="@/assets/images/reset-password.png" height="30" width="30"> Forgot password?</router-link></span></p>
            </form>
          </div>
        </div>
      </div>
      <div class="col-md-3"></div>
    </div>
    <br><br><br><br><br><br><br><br><br><br><br><br><br>
  </div>
</template>

<script type="text/javascript">
  import loading from "vue-full-loading";
  import User from "@/models/User.js";

  export default {
    components: {
      loading
    },

    data() {
      return {
        user: new User(),
        show: false,
        label: "Signing in. Please wait...",
        errorz: [],
        message: null,
        path: "/dashboard",
        errorMessage: null
      };
    },

    computed: {
      loggedIn() {
        return this.$store.state.auth.status.loggedIn;
      }
    },

    mounted() {
      if (this.loggedIn) {
        if (this.$route.path != this.path) {
          this.$router.replace(this.$route.query.redirect || '/dashboard');
        }
      }
    },

    methods: {
      login() {
        this.show = true;
        this.$store.dispatch("auth/login", this.user).then(response => {
          console.log("Login successful: ", response.data);
          this.show = false;
          this.$router.replace(this.$route.query.redirect || 'check-subscription');
        }).catch(error => {
          console.log("Login failed: ", error.response);
          if (error.response.status === 401) {
            this.message = error.response.data.error_description;
          }

          if (error.response.status === 400) {
            this.message = error.response.data.error_description;
          }
          if (error.response.status === 500) {
            this.message = "Login failed. Invalid username or password.";
          }
          this.show = false;
        });
      }
    }
  };
</script>

<style type="text/css" scoped>
  .processing-row {
    display: flex;
    justify-content: center;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
  }

  .error-message {
    color: red;
  }

  .login-background {
    background-image: url("~@/assets/images/carrierTMSDasboard.png");
    position: absolute;
    padding: 5px;
  }
</style>
