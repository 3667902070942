<template>
  <div>
    <AdminDashboard v-show="getRoles.includes('ADMIN')"/>
    <BrokerDashboard v-show="getRoles.includes('BROKER')"/>
    <ShipperDashboard v-show="getRoles.includes('SHIPPER')"/>
    <CarrierDashboard v-show="getRoles.includes('CARRIER')"/>
  </div>
</template>

<script type="text/javascript">
  import AdminDashboard from "@/views/dashboard/AdminDashboard";
  import ShipperDashboard from "@/views/dashboard/ShipperDashboard";
  import BrokerDashboard from "@/views/dashboard/BrokerDashboard";
  import CarrierDashboard from "@/views/dashboard/CarrierDashboard";

  export default{
    name: 'Dashboard',
    components: {
      AdminDashboard,
      ShipperDashboard,
      BrokerDashboard,
      CarrierDashboard
    },

    data: function(){
      return {

      }
    },

    created(){

    },

    computed: {
      loggedIn() {
        return this.$store.state.auth.status.loggedIn;
      },

      getUser: function() {
        return this.$store.state.auth.user.user;
      },

      getRoles: function() {
        let roles = [];
        for (var i = 0; i < this.getUser.authorities.length; i++) {
          roles.push(this.getUser.authorities[i].authority);
        }
        return roles;
      },
    },

    methods: {

    }
  };
</script>
