<template>
	<div>
		<loading :show="show" :label="label">
	      <div class="processing-row" slot="loading-container">
	        <div class="text-center">
	          <div slot="loading-spinner">
	            <img src="@/assets/images/station-loading.gif" height="100" width="100"/>
	          </div>
	          <p class="text-center" slot="loading-text">{{ label }}</p>
	        </div>
	      </div>
	    </loading>

	    <div class="panel">
	    	<div class="panel-body">
	    		<router-link :to="{ name: 'trailer-list'}">
	    			<button class="btn btn-default pull-right">Cancel</button>
              	</router-link>
              	<br><br><br>

              	<fieldset>
              		<h4>Are you sure you want to allocate trailer number <span class="badge">{{ getTrailerNumber }}</span> to truck number <span class="badge">{{ getTruckNumber }}</span></h4>
              		<br>
              		<form class="form" @submit.prevent="allocateTrailer">
						<div class="row">
							<div class="col-md-10"></div>
							<div class="col-md-2">
								<div class="form-group">
									<label>&nbsp;</label>
									<button class="btn btn-primary btn-block">Yes allocate</button>
								</div>
							</div>
						</div>
					</form>
				</fieldset>
	    	</div>
	    </div>
	</div>
</template>

<script type="text/javascript">
	import loading from "vue-full-loading";
	import TruckService from "@/services/TruckService";
	import Truck from "@/models/Truck";

	export default{
		name: 'AllocateTrailer',
		components: {
			loading,
		},

		data: function(){
			return {
				label: "Processing request",
				show: false,
				truck: new Truck
			}
		},

		created(){

		},

		computed: {
			getTruckNumber: function() {
				return this.$route.params.truckNumber;
			},

			getTrailerNumber: function() {
				return this.$route.params.trailerNumber;
			}
		},

		methods: {
			allocateTrailer: function(){
				this.show = true;
				TruckService.allocateTrailer(this.getTruckNumber, this.getTrailerNumber).then(response => {
					if (response.data.statusCode === 201) {
						this.$router.push({ name: 'truck-list', params: { truckNumber: response.data.body.truckNumber }});
					}
					this.show = false;
				}).catch(error => {
					console.log(error);
					this.show = false;
				});
			}
		}
	};
</script>