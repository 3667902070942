<template>
  <div> 
    <ul class="list-group">
      <ul class="list-group">
        <li class="list-group-item">
          <div v-if="shareholders.length !== 0">
            <ul class="list-group" v-for="(shareholder, index) in shareholders" :key="shareholder.id">
              <h4>Shareholder Number {{ index + 1 }}</h4>
              <li class="list-group-item">Person ID: {{ shareholder.uuid }}</li>
              <li class="list-group-item">First Name: {{ shareholder.firstName }}</li>
              <li class="list-group-item">Middle name: {{ shareholder.middleName }}</li>
              <li class="list-group-item">Last Name: {{ shareholder.lastName }}</li>
              <li class="list-group-item">Nickname: {{ shareholder.nickname }}</li>
              <li class="list-group-item">Email: {{ shareholder.email}}</li>
              <li class="list-group-item">Date of birth: {{ shareholder.dateOfBirth }}</li>
              <li class="list-group-item">
                Phone Number(s)
                <ul class="list-group" v-for="phoneNumber in shareholder.phoneNumbers" :key="phoneNumber.id">
                  <li class="list-group-item">{{ phoneNumber.phoneNumber }}</li>
                </ul>
              </li>
              <li class="list-group-item">Gender: {{ shareholder.gender }}</li>
              <li class="list-group-item">Salutation: {{ shareholder.salutation }}</li>
              <li class="list-group-item">Fax Number: {{ shareholder.faxNumber }}</li>
              <li class="list-group-item">Skype Address: {{ shareholder.skypeAddress }}</li>
              <li class="list-group-item">Occupation: {{ shareholder.occupation === null ? "": shareholder.occupation.name }}</li>
              <li class="list-group-item">National ID Number: {{ shareholder.nationalIdNumber }}</li>
              <li class="list-group-item">Passport Number: {{ shareholder.passportNumber }}</li>
              <li class="list-group-item">Drivers Licence Number: {{ shareholder.driversLicenceNumber }}</li>
              <li class="list-group-item">Nationality: {{ shareholder.nationality }}</li>
              <li class="list-group-item">Home Address: {{ shareholder.homeAddress === null ? "": shareholder.homeAddress.physicalAddress }}</li>
              <li class="list-group-item">Postal Address: {{ shareholder.postalAddress === null ? "": shareholder.postalAddress.physicalAddress }}</li>
              <li class="list-group-item">Marital status: {{ shareholder.maritalStatus }}</li>
              <li class="list-group-item">Place of birth: {{ shareholder.placeOfBirth }}</li>
              <li class="list-group-item">Country of birth: {{ shareholder.countryOfBirth }}</li>
              <li class="list-group-item">Ethnic Group: {{ shareholder.ethnicGroup }}</li>
              <li class="list-group-item">Photo: {{ shareholder.photo }}</li>
              <li class="list-group-item">Digital Signature: {{ shareholder.digitalSignature }}</li>
              <li class="list-group-item">Shareholding Information: {{ shareholder.shareholdingInformation === null ? "": shareholder.shareholdingInformation.percentageShareholding }} percent</li>
            </ul>
          </div>
        </li>
      </ul>
    </ul>
  </div>
</template>

<script type="text/javascript">
  export default{
    name: "ShareholdersComponent",
    data: function(){
      return {

      }
    },

    props: {
      shareholders: {
        type: Array,
        default: function(){
          return [];
        }
      }
    }
  }  
</script>