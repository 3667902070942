<template>
  <div>
    <h4>All Payments</h4>
    <br>

    <div class="panel">
      <div class="panel-body">
        <form class="form">
          <div class="row">
            <div class="col-md-3">
              <div class="form-group">
                <label>Payment Type</label>
                <select class="form-control" v-model="paymentType">
                  <option value="" disabled>Select payment type</option>
                  <option value="ALL">All payments</option>
                  <option value="ONLINE_PAYMENT">Online payments</option>
                  <option value="BANK">Bank payments</option>
                  <option value="CASH">Cash payments</option>
                </select>
              </div>
            </div>

            <div class="col-md-2">
              <div class="form-group">
                <label>Currency</label>
                <select class="form-control">
                  <option value="" disabled>Select currency</option>
                  <option value="ZWL">ZWL</option>
                  <option value="USD">USD</option>
                </select>
              </div>
            </div>
            <div class="col-md-2">
              <label>From</label>
              <input type="date" name="dateFrom" id="dateFrom" class="form-control">
            </div>
            <div class="col-md-2">
              <label>To Date</label>
              <input type="date" name="dateFrom" id="dateFrom" class="form-control">
            </div>
            <div class="col-md-2" v-show="getRoles.includes('ADMIN')">
              <label>&nbsp;</label><br>
              <router-link :to="{ name: 'payments/releasePayments' }">
                <button class="btn btn-primary">Release Authorized Payments</button>
              </router-link>
            </div>
          </div>
        </form>

        <table class="table table-bordered table-hover table-striped table-responsive">
          <thead class="bg-primary">
            <th>ID</th>
            <th>Invoice</th>
            <th>Load Number</th>
            <th>Reference Number</th>
            <th>Debit Reference</th>
            <th>Amount</th>
            <th>Channel</th>
            <th>Load Status</th>
            <th>Status</th>
            <th>Action</th>
          </thead>
          <tbody>
            <tr v-for="load in loads" :key="load.id">
              <td>{{ load.payment.id }}</td>
              <td>
                <router-link :to="{ name: 'view-invoice', params: { invoiceNumber: load.invoice.invoiceNumber }}">
                    {{ load.invoice.invoiceNumber }}
                </router-link>
              </td>
              <td>
                <router-link :to="{ name: 'view-load', params: { loadNumber: load.loadNumber }}">
                    {{ load.loadNumber }}
                </router-link>
              </td>
              <td>{{ load.payment.referenceNumber }}</td>
              <td>{{ load.payment.debitReference }}</td>
              <td>{{ load.payment.currency }}{{ load.payment.amount }}</td>
              <td>{{ load.payment.paymentMethod }}</td>
              <td>
                <span class="label label-primary" v-show="load.payment.status === 'ACTIVE'">{{ load.payment.status }}</span>
                <span class="label label-success" v-show="load.payment.status === 'PAID'">{{ load.payment.status }}</span>
                <span class="label label-success" v-show="load.payment.status === 'AUTHORIZED'">{{ load.payment.status }}</span>
                <span class="label label-success" v-show="load.payment.status === 'RELEASED'">{{ load.payment.status }}</span>
              </td>
              <td>{{ load.status }}</td>
              <td>
                <span>
                  <router-link :to="{ name: 'payments/view', params: { referenceNumber: load.payment.referenceNumber }}">
                    <button class="btn btn-primary btn-sm"><span class="glyphicon glyphicon-info-sign"></span> View</button>
                  </router-link>
                </span>&nbsp;
                <span v-show="load.payment.status === 'ACTIVE'">
                  <router-link :to="{ name: 'payments/view', params: { referenceNumber: load.payment.referenceNumber }}">
                    <button class="btn btn-success btn-sm"><span class="glyphicon glyphicon-info-sign"></span> Release</button>
                  </router-link>
                </span>
                <span v-show="load.payment.status === 'PAID' && load.status === 'DELIVERY_CONFIRMED' && getRoles.includes('ADMIN')">
                  <router-link :to="{ name: 'payments/authorize-payment', params: { referenceNumber: load.payment.referenceNumber, loadNumber: load.loadNumber }}">
                    <button class="btn btn-success btn-sm"><span class="glyphicon glyphicon-info-sign"></span> Authorize Payment</button>
                  </router-link>
                </span>
                <span v-show="load.payment.status === 'PAID' && load.status === 'DELIVERED_WITH_DEFECTS' && getRoles.includes('ADMIN')">
                  <router-link :to="{ name: 'payments/authorize-payment', params: { referenceNumber: load.payment.referenceNumber, loadNumber: load.loadNumber }}">
                    <button class="btn btn-success btn-sm"><span class="glyphicon glyphicon-info-sign"></span> Withhold Payment</button>
                  </router-link>
                </span>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script type="text/javascript">
  import LoadService from "@/services/LoadService.js";

  export default {
    name: "PaymentList",
    data: function() {
      return {
        paymentType: null,
        loads: [],
        filterPayments: []
      };
    },

    created: function() {
      this.findByCarrierContactPersonUsername();
    },

    computed: {
      getUser: function() {
        return this.$store.state.auth.user.user;
      },

      getRoles: function() {
        let roles = [];
        for (var i = 0; i < this.getUser.authorities.length; i++) {
          roles.push(this.getUser.authorities[i].authority);
        }
        return roles;
      },
    },

    methods: {
      findByCarrierContactPersonUsername: function() {
        LoadService.findByCarrierContactPersonUsername(this.$store.state.auth.user.user.username).then(response => {
          this.loads = response.data.body.filter(load => {
            return load.invoice !== null && load.invoice.payment !== null;;
          });
        }).catch(error => {
          console.log(error);
        });
      }
    }
  };
</script>
