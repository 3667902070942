<template>
	<div>
		<loading :show="show" :label="label">
			<div class="processing-row" slot="loading-container">
				<div class="text-center">
					<div slot="loading-spinner">
						<img src="@/assets/images/station-loading.gif" height="100" width="100"/>
					</div>
					<p class="text-center" slot="loading-text">{{ label }}</p>
				</div>
			</div>
	    </loading>

		<div class="panel">
			<div class="panel-body">
				<div class="row">
					<div class="col-md-4">
						<h4>New additional {{ getNarration }}</h4>
					</div>
					<div class="col-md-7"></div>
					<div class="col-md-1">
						<router-link :to="{ name: getNarration === 'trailers' ? 'trailer-list': 'truck-list' }">
							<button class="btn btn-danger btn-block">Close</button>
						</router-link>
					</div>
				</div>
				<br><br>
            	<div class="row">
            		<div class="col-md-3"></div>
            		<div class="col-md-6">
            			<form class="form">
            				<div class="form-group">
		                		<label>Number of {{ getNarration }}</label>
		                		<input type="text" name="totalItems" v-model="additionalCharge.totalItems" :placeholder="'Enter number of additional ' + getNarration" class="form-control">
		                	</div>
		                	<div class="row">
		                		<div class="col-md-9">
		                			<label>&nbsp;</label>
		                			<p v-if="additionalCharge.totalItems > 0" style="color: #000000;">Total {{ VUE_APP_TRAILER_ADDITIONAL_CHARGE_CURRENCY }}{{ additionalCharge.totalItems * VUE_APP_TRAILER_ADDITIONAL_CHARGE  }} of {{ additionalCharge.totalItems }} {{ getNarration }}</p>
		                		</div>
		                		<div class="col-md-3">
		                			<div class="form-group">
				                		<label>&nbsp;</label>
				                		<button class="btn btn-primary btn-block">Pay</button>
				                	</div>
		                		</div>
		                	</div>
            			</form>
            		</div>
            		<div class="col-md-3"></div>
            	</div>
			</div>
		</div>
	</div>
</template>

<script type="text/javascript">
	import loading from "vue-full-loading";
	import AdditionalChargeService from "@/services/AdditionalChargeService";
	import AdditionalCharge from "@/models/AdditionalCharge";

	export default {
		name: "NewAdditionalCharge",
		components: {
			loading
		},

		data: function() {
			return {
				show: false,
				label: "Processing request",
				additionalCharge: new AdditionalCharge,
				VUE_APP_TRAILER_ADDITIONAL_CHARGE_CURRENCY: process.env.VUE_APP_TRAILER_ADDITIONAL_CHARGE_CURRENCY,
				VUE_APP_TRUCK_ADDITIONAL_CHARGE: process.env.VUE_APP_TRUCK_ADDITIONAL_CHARGE,
				VUE_APP_TRAILER_ADDITIONAL_CHARGE: process.env.VUE_APP_TRAILER_ADDITIONAL_CHARGE,
			};
		},

		created(){},

		computed: {
			getNarration(){
				return this.$route.query.narration;
			}
		},

		methods: {
			save: function() {
				this.show = true;
				this.additionalCharge = this.getUser.username;
				AdditionalChargeService.save(this.additionalCharge).then(response => {
					if (response.data.statusCode === 201) {
						if (this.getNarration === 'trailers') {
							this.$router.push({ name: 'trailer-list' });
						}
						if (this.getNarration === 'trucks') {
							this.$router.push({ name: 'truck-list' });
						}
					}
					this.show = false;
				}).catch(error => {
					console.log(error);
					this.show = false;
				});
			}
		}
	};
</script>