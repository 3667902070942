<template>
	<div>
		<zingchart :data="lineChart" :height="300" :width="600"/>

		<select class="form-control">
			<option>Select month</option>
			<option>Jan</option>
		</select>
		<zingchart :data="barChart" :height="300" :width="600"/>

		<div>
			<h4><u>KEY</u></h4>
			<div class="btn-group">
			  <button type="button" class="btn btn-success">Active</button>
			  <button type="button" class="btn btn-warning">Submitted to ECGC</button>
			  <button type="button" class="btn btn-primary">Under Review</button>
			  <button type="button" class="btn btn-danger">Rejected</button>
			</div>
		</div>
		<zingchart :data="pieChart" :height="300" :width="600"/>
	</div>
</template>

<script type="text/javascript">
	export default {
	  data: function() {
	    return {
			lineChart: {
					type: 'line',
					series: [{
						values: [13,23,14,14,19,17,18,29,19,19]
					}],

					title: {
			        fontColor: "#8e99a9",
			        text: 'Applications Frequencey per day',
			        align: "left",
			        offsetX: 10,
			        fontFamily: "Open Sans",
			        fontSize: 25
			      },
			},

			barChart: {
					type: 'bar',
					series: [{
						values: [13,23,14,14,19,17,18,29,19,19]
					}],
					title: {
				        fontColor: "#8e99a9",
				        text: 'Monthly Applications per day',
				        align: "left",
				        offsetX: 10,
				        fontFamily: "Open Sans",
				        fontSize: 25
				      },
			},

			pieChart: {
					type: 'pie',
			      // plot: {
			      //   borderColor: "#2B313B",
			      //   borderWidth: 5,
			      //   // slice: 90,
			      //   valueBox: {
			      //     placement: 'out',
			      //     text: '%t\n%npv%',
			      //     fontFamily: "Open Sans"
			      //   },
			      //   tooltip: {
			      //     fontSize: '18',
			      //     fontFamily: "Open Sans",
			      //     padding: "5 10",
			      //     text: "%npv%"
			      //   },
			      //   animation: {
			      //     effect: 2,
			      //     method: 5,
			      //     speed: 900,
			      //     sequence: 1,
			      //     delay: 300
			      //   }
			      // },
			      // source: {
			      //   text: 'gs.statcounter.com',
			      //   fontColor: "#8e99a9",
			      //   fontFamily: "Open Sans"
			      // },
			      title: {
			        fontColor: "#8e99a9",
			        text: 'Applications Statistics',
			        align: "left",
			        offsetX: 10,
			        fontFamily: "Open Sans",
			        fontSize: 25
			      },
			      // subtitle: {
			      //   offsetX: 10,
			      //   offsetY: 10,
			      //   fontColor: "#8e99a9",
			      //   fontFamily: "Open Sans",
			      //   fontSize: "16",
			      //   text: 'May 2016',
			      //   align: "left"
			      // },
			      // plotarea: {
			      //   margin: "20 0 0 0"
			      // },
			      series: [{
			          values: [20],
			          text: "Active",
			          backgroundColor: '#50ADF5',
			        },
			        {
			          values: [20],
			          text: "Submitted",
			          backgroundColor: '#FF7965',
			          // detached: true
			        },
			        {
			          values: [20],
			          text: 'Under Review',
			          backgroundColor: '#FFCB45',
			          // detached: true
			        },
			        {
			          text: 'Rejected',
			          values: [20],
			          backgroundColor: '#6877e5'
			        },

			        {
			          text: 'Archived',
			          values: [20],
			          backgroundColor: '#6FB07F'
			        }
			      ]
			    }
	    }
	  }
	};
</script>