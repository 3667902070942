import { GENERIC_SERVICE_URL } from "@/config/GenericServiceConfig.js";
import axios from "axios";

class DriverService {
    findAll() {
        return axios.get(GENERIC_SERVICE_URL + "drivers");
    }

    findById(id) {
        return axios.get(GENERIC_SERVICE_URL + "drivers/" + id);
    }

    save(driver) {
        return axios.post(GENERIC_SERVICE_URL + "drivers", {
            id: driver.id,
            carrierUsername: driver.carrierUsername,
            personType: driver.personType,
            driverNumber: driver.driverNumber,
            salutation: driver.salutation,
            firstName: driver.firstName,
            lastName: driver.lastName,
            email: driver.email,
            phoneNumbers: driver.phoneNumbers,
            address: driver.address,
            postalAddress: driver.postalAddress,
            description: driver.description,
            status: driver.status
        });
    }

    findByDriverNumber(driverNumber) {
        return axios.get(GENERIC_SERVICE_URL + "drivers/findByDriverNumber/" + driverNumber);
    }

    allocateTruck(driverNumber, truckNumber) {
        return axios.post(GENERIC_SERVICE_URL + "drivers/allocateTruck/" + driverNumber + "/" + truckNumber);
    }
}
export default new DriverService;
