import { GENERIC_SERVICE_URL } from "@/config/GenericServiceConfig.js";
import axios from "axios";

class CustomerService {
  save(customer) {
    return axios.post(GENERIC_SERVICE_URL + "customers" + "/", {
        id: customer.id,
        uuid: customer.uuid,
        companyType: customer.companyType,
        name: customer.name,
        shortName: customer.shortName,
        businessPartnerNumber: customer.businessPartnerNumber,
        phoneNumbers: customer.phoneNumbers,
        email: customer.email,
        faxNumber: customer.faxNumber,
        websiteUrl: customer.websiteUrl,
        businessSectorId: customer.businessSectorId,
        businessCategories: customer.businessCategories,
        organizationType: customer.organizationType,
        establishedYear: customer.establishedYear,
        numberOfEmployees: customer.numberOfEmployees,
        physicalAddress: customer.physicalAddress,
        postalAddress: customer.postalAddress,
        associatedUser: customer.associatedUser,
        rmtNumber: customer.rmtNumber,
        carrierType: customer.carrierType,
        customsAgentPermitNumber: customer.customsAgentPermitNumber,
        shareholders: customer.shareholders,
        attachments: customer.attachments,
        otherCreditFacilities: customer.otherCreditFacilities,
        financialHistories: customer.financialHistories,
        bankAccounts: customer.bankAccounts,
        contactPeople: customer.contactPeople,
        associatedUserUsername: customer.associatedUserUsername
      }
    );
  }

  findAll() {
    return axios.get(GENERIC_SERVICE_URL + "customers");
  }

  findById(id) {
    return axios.get(GENERIC_SERVICE_URL + "customers/" + id);
  }

  findByUuid(uuid) {
    return axios.get(GENERIC_SERVICE_URL + "customers/findByUuid/" + uuid);
  }

  findByAssociatedUserUsername(userId) {
    return axios.get(GENERIC_SERVICE_URL + "customers/findByAssociatedUserUsername/" + userId);
  }

  verify(uuid) {
    return axios.post(GENERIC_SERVICE_URL + "customers/verify/" + uuid);
  }

  findPremiumCarriers() {
    return axios.get(GENERIC_SERVICE_URL + "customers/findPremiumCarriers");
  }
}
export default new CustomerService;
