<template>
  <div>
    <div class="row">
        <div class="col-md-3" v-for="menu in getMenu" :key="menu.link">
          <router-link :to="{ name: menu.link }">
            <div class="panel">
              <div class="panel-body text-center">
                <img :src="'@/assets/images/' + menu.icon + imageExtension" width="30" height="30">
                <h4>{{ menu.name }}</h4>
              </div>
            </div>
          </router-link>
        </div>
    </div>
  </div>
</template>

<script type="text/javascript">
  export default{
    data: function(){
      return {
        imageExtension: 'png'
      }
    },

    computed: {
      getMenu(){
        let menu = [
          {
            name: "Roles",
            link: "roles",
            icon: "user"
          },

          {
            name: "Zip Codes",
            link: "zipCodes",
            icon: "configuration"
          },

          {
            name: "Provinces",
            link: "provinces",
            icon: "users"
          },

          {
            name: "Cities",
            link: "cities",
            icon: "users"
          },

          {
            name: "Payments",
            link: "payments",
            icon: "users"
          },

          {
            name: "Areas",
            link: "areas",
            icon: "users"
          },

          {
            name: "Business Sector",
            link: "businessSectors",
            icon: "users"
          },

          {
            name: "Business Category",
            link: "businessCategories",
            icon: "users"
          },

          {
            name: "Exchange Rates",
            link: "exchangeRates",
            icon: "users"
          }
        ];
      return menu;
      }
    }
  }
</script>

<style type="text/css" scoped>
.dropdown:hover .dropdown-menu {
  display: block;
}
</style>
