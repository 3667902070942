import { GENERIC_SERVICE_URL } from "@/config/GenericServiceConfig.js";
import axios from "axios";

class CompanyService {
  save(company) {
    return axios.post(GENERIC_SERVICE_URL + "companies" + "/", {
        id: company.id,
        uuid: company.uuid,
        companyType: company.companyType,
        name: company.name,
        shortName: company.shortName,
        businessPartnerNumber: company.businessPartnerNumber,
        phoneNumbers: company.phoneNumbers,
        email: company.email,
        faxNumber: company.faxNumber,
        websiteUrl: company.websiteUrl,
        businessSectorId: company.businessSectorId,
        businessCategories: company.businessCategories,
        organizationType: company.organizationType,
        establishedYear: company.establishedYear,
        numberOfEmployees: company.numberOfEmployees,
        physicalAddress: company.physicalAddress,
        postalAddress: company.postalAddress,
        associatedUser: company.associatedUser,
        rmtNumber: company.rmtNumber,
        carrierType: company.carrierType,
        customsAgentPermitNumber: company.customsAgentPermitNumber,
        shareholders: company.shareholders,
        attachments: company.attachments,
        otherCreditFacilities: company.otherCreditFacilities,
        financialHistories: company.financialHistories,
        bankAccounts: company.bankAccounts
      }
    );
  }

  findAll() {
    return axios.get(GENERIC_SERVICE_URL + "companies");
  }

  findById(id) {
    return axios.get(GENERIC_SERVICE_URL + "companies/" + id);
  }

  findByUuid(uuid) {
    return axios.get(GENERIC_SERVICE_URL + "companies/findByUuid/" + uuid);
  }

  findByAssociatedUserUsername(userId) {
    return axios.get(GENERIC_SERVICE_URL + "companies/findByAssociatedUserUsername/" + userId);
  }

  verify(uuid) {
    return axios.post(GENERIC_SERVICE_URL + "companies/verify/" + uuid);
  }

  findPremiumCarriers() {
    return axios.get(GENERIC_SERVICE_URL + "companies/findPremiumCarriers");
  }
}
export default new CompanyService();
