<template>
	<div>
		<table class="table table-bordered table-hover table-responsive">
			<thead>
				<th>&nbsp;&nbsp;<input type="checkbox"></th>
				<th>Age</th>
				<th>Pick Up</th>
				<th>DH-P</th>
				<th>Date</th>
				<th>Drop Off</th>
				<th>Distance</th>
				<th>Trailer</th>
				<th>Weight</th>
				<th>Broker</th>
				<th>DTP/CS</th>
				<th>Market Average</th>
				<th>Price</th>
			</thead>
			<tbody>
				<tr v-for="load in loads" :key="load.id">
					<td><input type="checkbox"></td>
					<td>1h</td>
					<td>{{ load.pickupAddress !== null ? load.pickupAddress.province.name: '' }}, {{ load.pickupAddress !== null ? load.pickupAddress.city.name: '' }}</td>
					<td>{{ load.dhp }}</td>
					<td>{{ load.createdAt }}</td>
					<td>{{ load.dropOffDate }}</td>
					<td>{{ load.estimatedDistance }} {{ load.estimatedDistanceMeasurement }}</td>
					<td>{{ load.weight }} {{ load.weightMeasurement }}</td>
				</tr>
			</tbody>
		</table>
	</div>
</template>

<script type="text/javascript">
	export default{
		components: {

		},

		props: {
			loads: {
				type: Array,
				default: function(){
					return []
				}
			}
		},

		data: function(){
			return {
				show: false,
				label: 'Processing request'
			}
		},

		mounted(){

		},

		methods: {}
	}
</script>