import { GENERIC_SERVICE_URL } from "@/config/GenericServiceConfig.js";
import axios from "axios";

class CarrierTruckService {
    findAll() {
        return axios.get(GENERIC_SERVICE_URL + "carrierTrucks");
    }

    findById(id) {
        return axios.get(GENERIC_SERVICE_URL + "carrierTrucks/" + id);
    }

    save(truck) {
        return axios.post(GENERIC_SERVICE_URL + "carrierTrucks", {
            id: truck.id,
            carrierUsername: truck.carrierUsername,
            truckNumber: truck.truckNumber,
            registrationNumber: truck.registrationNumber,
            trailerType: truck.trailerType,
            regularPickUpAddress: truck.regularPickUpAddress,
            regularDropOffAddress: truck.regularDropOffAddress,
            recurringType: truck.recurringType,
            desiredRate: truck.desiredRate,
            desiredRateCurrency: truck.desiredRateCurrency,
            desiredRateMeasurement: truck.desiredRateMeasurement,
            estimatedDeliveryTimeMeasurement: truck.estimatedDeliveryTimeMeasurement,
            length: truck.length,
            lengthMeasurement: truck.lengthMeasurement,
            maxLoadSize: truck.maxLoadSize,
            maxLoadSizeMeasurement: truck.maxLoadSizeMeasurement,
            grossVehicleMass: truck.grossVehicleMass,
            grossVehicleMassMeasurement: truck.grossVehicleMassMeasurement,
            netVehicleMass: truck.netVehicleMass,
            netVehicleMassMeasurement: truck.netVehicleMassMeasurement,
            maxHeightClearance: truck.maxHeightClearance,
            maxHeightClearanceMeasurement: truck.maxHeightClearanceMeasurement,
            loadMode: truck.loadMode
        });
    }

    findByTruckNumber(truckNumber) {
        return axios.get(GENERIC_SERVICE_URL + "carrierTrucks/findByTruckNumber/" + truckNumber);
    }

    searchTrucks(truck) {
        return axios.post(GENERIC_SERVICE_URL + "carrierTrucks/searchTrucks", {
            id: truck.id,
            carrierUsername: truck.carrierUsername,
            truckNumber: truck.truckNumber,
            registrationNumber: truck.registrationNumber,
            trailerType: truck.trailerType,
            regularPickUpAddress: truck.regularPickUpAddress,
            regularDropOffAddress: truck.regularDropOffAddress,
            recurringType: truck.recurringType,
            desiredRate: truck.desiredRate,
            desiredRateCurrency: truck.desiredRateCurrency,
            desiredRateMeasurement: truck.desiredRateMeasurement,
            estimatedDeliveryTimeMeasurement: truck.estimatedDeliveryTimeMeasurement,
            length: truck.length,
            lengthMeasurement: truck.lengthMeasurement,
            maxLoadSize: truck.maxLoadSize,
            maxLoadSizeMeasurement: truck.maxLoadSizeMeasurement,
            grossVehicleMass: truck.grossVehicleMass,
            grossVehicleMassMeasurement: truck.grossVehicleMassMeasurement,
            netVehicleMass: truck.netVehicleMass,
            netVehicleMassMeasurement: truck.netVehicleMassMeasurement,
            maxHeightClearance: truck.maxHeightClearance,
            maxHeightClearanceMeasurement: truck.maxHeightClearanceMeasurement,
            loadMode: truck.loadMode,
            truckType: truck.truckType
        });
    }
}
export default new CarrierTruckService;
