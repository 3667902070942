<template>
	<div>
		<loading :show="show" :label="label">
	      <div class="processing-row" slot="loading-container">
	        <div class="text-center">
	          <div slot="loading-spinner">
	            <img src="@/assets/images/station-loading.gif" height="100" width="100"/>
	          </div>
	          <p class="text-center" slot="loading-text">{{ label }}</p>
	        </div>
	      </div>
	    </loading>

	    <div class="panel">
	    	<div class="panel-body">
	    		<p>Manage all the loads in the system, keeping track of them from dispatching to delivery</p>
			    <div class="row">
					<div class="col-md-1">
						<button class="btn btn-default btn-sm btn-block" @click="filterShipments('ALL')">All <span class="badge">{{ allShipments }}</span></button>
					</div>
					<div class="col-md-2">
						<button class="btn btn-primary btn-sm btn-block" @click="filterShipments('BOOKED')">Booked <span class="badge">{{ bookedShipments }}</span></button>
					</div>
					<div class="col-md-2">
						<button class="btn btn-warning btn-sm btn-block" @click="filterShipments('SCHEDULED')">Scheduled <span class="badge">{{ scheduledShipments }}</span></button>
					</div>
					<div class="col-md-1">
						<button class="btn btn-default btn-sm btn-block" @click="filterShipments('ENROUTE')">Enroute <span class="badge">{{ enrouteShipments }}</span></button>
					</div>
					<div class="col-md-2">
						<button class="btn btn-warning btn-sm btn-block" @click="filterShipments('DELIVERY')">Delivery <span class="badge">{{ deliveryShipments }}</span></button>
					</div>
					<div class="col-md-2">
						<button class="btn btn-success btn-sm btn-block" @click="filterShipments('DELIVERED')">Delivered <span class="badge">{{ deliveredShipments }}</span></button>
					</div>
					<div class="col-md-2">
						<button class="btn btn-danger btn-sm btn-block" @click="filterShipments('CANCELLED')">Cancelled <span class="badge">{{ cancelledShipments }}</span></button>
					</div>
				</div>
	    	</div>
	    </div>

	    <div class="panel">
	    	<div class="panel-body">
	    		<h4>{{ shipmentStatus !== null ? shipmentStatus: 'All' }} Shipments</h4>
	    		<table class="table table-responsive table-bordered">
			    	<thead class="bg-primary">
				        <th>Load Number</th>
				        <th>Booking Number</th>
				        <th>Load Price</th>
				        <th>Pickup</th>
				        <th>Drop Off</th>
				        <th>Assigned Truck</th>
				        <th>Load Status</th>
				      </thead>
				      <tbody>
				        <tr v-for="loadBooking in filteredShipments" :key="loadBooking.id">
				        	<td>{{ loadBooking.load.loadNumber }}</td>
				        	<td>{{ loadBooking.booking.bookingNumber }}</td>
				        	<td>{{ loadBooking.load.currency }}{{ loadBooking.load.price }}</td>
				          	<td>
				          		{{ loadBooking.load.pickupAddress !== null ? loadBooking.load.pickupAddress.city.name + ' ' + loadBooking.load.pickupAddress.province.name: '' }}
				          	</td>
				          	<td>
				          		{{ loadBooking.load.dropOffAddress !== null ? loadBooking.load.dropOffAddress.city.name + ' ' + loadBooking.load.dropOffAddress.province.name: '' }}
				          	</td>
				          	<td>
				          		<span v-if="loadBooking.booking.assignedTruck !== null">
				          			{{ loadBooking.booking.assignedTruck.registrationNumber }}
				          		</span>
				          	</td>
				          	<td>{{ loadBooking.load.status }}</td>
				          </tr>
				      </tbody>
				  </table>
	    	</div>
	    </div>    
	</div>
</template>

<script type="text/javascript">
	import loading from "vue-full-loading";
	import BookingService from "@/services/BookingService.js";
	import LoadBookingService from "@/services/LoadBookingService.js";
	import LoadService from "@/services/LoadService.js";

	export default{
		name: 'Shipments',
		components: {
			loading,
		},

		data: function(){
			return {
				label: "Processing request",
				show: false,
			    bookings: [],
			    loadBookings: [],
			    shipmentStatus: null,
			    filteredShipments: [],
			}
		},

		mounted(){
			if (this.getRoles.includes('BROKER') || this.getRoles.includes('SHIPPER')) {
				this.findByLoadOwnerUsername();
			}
			if (this.getRoles.includes('CARRIER')) {
				this.findByBookingCarrierAssociatedUserUsername();
		    }
		},

		computed: {
			getUser: function() {
				return this.$store.state.auth.user.user;
		    },

		    getRoles: function() {
		    	let roles = [];
		    	for (var i = 0; i < this.getUser.authorities.length; i++) {
		    		roles.push(this.getUser.authorities[i].authority);
		    	}
		    	return roles;
		    },

		    allShipments(){
		    // 	return this.loadBookings.filter(loadBooking => {
		    // 		return (loadBooking.booking.status === 'BOOKED' && loadBooking.load.status === 'BOOKED' && !loadBooking.load.released) ||
		    // 		(loadBooking.booking.status === 'BOOKED' && loadBooking.load.status === 'BOOKED' && !loadBooking.load.released) || 
		    // 		(loadBooking.booking.status === 'BOOKED' && loadBooking.load.released && loadBooking.load.collected && !loadBooking.load.delivered && !loadBooking.load.confirmedDelivery) || 
		    // 		(loadBooking.booking.status === 'BOOKED' & loadBooking.load.released && loadBooking.load.collected && loadBooking.load.delivered && !loadBooking.load.confirmedDelivery || loadBooking.load.status === 'DELIVERED_WITH_DEFECTS') || 
						// (loadBooking.booking.status === 'BOOKED' && loadBooking.load.released && loadBooking.load.collected && loadBooking.load.delivered && loadBooking.load.confirmedDelivery) || (loadBooking.booking.status === 'CANCELLED')
		    // 	}).length;

		    return this.loadBookings.filter(loadBooking => {
		    		return (loadBooking.booking.status === 'BOOKED' && !loadBooking.load.released) || (loadBooking.booking.status === 'BOOKED' && loadBooking.load.status === 'BOOKED' && loadBooking.load.released && !loadBooking.load.collected) || (loadBooking.booking.status === 'BOOKED' && loadBooking.load.released && loadBooking.load.collected && !loadBooking.load.delivered && !loadBooking.load.confirmedDelivery) || (loadBooking.booking.status === 'BOOKED' && loadBooking.load.released && loadBooking.load.collected && loadBooking.load.delivered && !loadBooking.load.confirmedDelivery || loadBooking.load.status === 'DELIVERED_WITH_DEFECTS') || (loadBooking.booking.status === 'BOOKED' && loadBooking.load.released && loadBooking.load.collected && loadBooking.load.delivered && loadBooking.load.confirmedDelivery && loadBooking.load.status === 'DELIVERY_CONFIRMED') || (loadBooking.booking.status === 'CANCELLED');
		    	}).length;
		    },

		    bookedShipments(){
		    	return this.loadBookings.filter(loadBooking => {
		    		return loadBooking.booking.status === 'BOOKED' && !loadBooking.load.released;
		    	}).length;
		    },

		    scheduledShipments(){
		    	return this.loadBookings.filter(loadBooking => {
		    		return loadBooking.booking.status === 'BOOKED' && loadBooking.load.status === 'BOOKED' && loadBooking.load.released && !loadBooking.load.collected;
		    	}).length;
		    },

		    enrouteShipments(){
		    	return this.loadBookings.filter(loadBooking => {
		    		return loadBooking.booking.status === 'BOOKED' && loadBooking.load.released && loadBooking.load.collected && !loadBooking.load.delivered && !loadBooking.load.confirmedDelivery;
		    	}).length;
		    },

		    deliveryShipments(){
		    	return this.loadBookings.filter(loadBooking => {
		    		return loadBooking.booking.status === 'BOOKED' && loadBooking.load.released && loadBooking.load.collected && loadBooking.load.delivered && !loadBooking.load.confirmedDelivery || loadBooking.load.status === 'DELIVERED_WITH_DEFECTS';
		    	}).length;
		    },

		    deliveredShipments(){
		    	return this.loadBookings.filter(loadBooking => {
		    		return loadBooking.booking.status === 'BOOKED' && loadBooking.load.released && loadBooking.load.collected && loadBooking.load.delivered && loadBooking.load.confirmedDelivery && loadBooking.load.status === 'DELIVERY_CONFIRMED';
		    	}).length;
		    },

		    cancelledShipments(){
		    	return this.loadBookings.filter(loadBooking => {
		    		return loadBooking.booking.status === 'CANCELLED';
		    	}).length;
		    }
		},

		methods: {
			findByCarrierAssociatedUserUsername: function() {
				this.show = true;
		        BookingService.findByCarrierAssociatedUserUsername(this.getUser.username).then(response => {
		        	this.bookings = response.data.body;
		        	this.show = false;
		        }).catch(error => {
		        	console.log(error);
		        	this.show = false;
		        });
		    },

		    findByBookingCarrierAssociatedUserUsername: function() {
				this.show = true;
		        LoadBookingService.findByBookingCarrierAssociatedUserUsername(this.getUser.username).then(response => {
		        	this.loadBookings = response.data.body;
		        	this.filteredShipments = this.loadBookings;
		        	var shipmentStatus = JSON.parse(localStorage.getItem('shipmentStatus')); 
					if (shipmentStatus !== null) {
						this.filterShipments(shipmentStatus);
					}
		        	this.show = false;
		        }).catch(error => {
		        	console.log(error);
		        	this.show = false;
		        });
		    },

		    findByLoadOwnerUsername: function() {
				this.show = true;
		        LoadBookingService.findByLoadOwnerUsername(this.getUser.username).then(response => {
		        	this.loadBookings = response.data.body;
		        	this.filteredShipments = this.loadBookings;
		        	var shipmentStatus = JSON.parse(localStorage.getItem('shipmentStatus')); 
					if (shipmentStatus !== null) {
						this.filterShipments(shipmentStatus);
					}
		        	this.show = false;
		        }).catch(error => {
		        	console.log(error);
		        	this.show = false;
		        });
		    },

		    collected: function(load){
				this.show = true;
				LoadService.collected(load).then(response => {
					if (response.data.statusCode === 201){
						this.findByBookingCarrierAssociatedUserUsername();
					}
				this.show = false;
				}).catch(error => {
					console.log(error);
					this.show = false;
				});
			},

			carrierConfirmedDelivery: function(load){
				this.show = true;
				LoadService.carrierConfirmedDelivery(load).then(response => {
					if (response.data.statusCode === 201){
						this.findByBookingCarrierAssociatedUserUsername();
					}
				this.show = false;
				}).catch(error => {
					console.log(error);
					this.show = false;
				});
			},

			filterShipments(status){
				localStorage.setItem('shipmentStatus', JSON.stringify(status));
				if (this.loadBookings !== null) {
					if (status === 'ALL') {
						this.shipmentStatus = 'All';
						this.filteredShipments = this.loadBookings.filter(loadBooking => {
							return (loadBooking.booking.status === 'BOOKED' && !loadBooking.load.released) || (loadBooking.booking.status === 'BOOKED' && loadBooking.load.status === 'BOOKED' && loadBooking.load.released && !loadBooking.load.collected) || (loadBooking.booking.status === 'BOOKED' && loadBooking.load.released && loadBooking.load.collected && !loadBooking.load.delivered && !loadBooking.load.confirmedDelivery) || (loadBooking.booking.status === 'BOOKED' && loadBooking.load.released && loadBooking.load.collected && loadBooking.load.delivered && !loadBooking.load.confirmedDelivery || loadBooking.load.status === 'DELIVERED_WITH_DEFECTS') || (loadBooking.booking.status === 'BOOKED' && loadBooking.load.released && loadBooking.load.collected && loadBooking.load.delivered && loadBooking.load.confirmedDelivery && loadBooking.load.status === 'DELIVERY_CONFIRMED') || (loadBooking.booking.status === 'CANCELLED');
						});
					}
					if (status === 'BOOKED') {
						this.shipmentStatus = 'Booked';
						this.filteredShipments = this.loadBookings.filter(loadBooking => {
							return loadBooking.booking.status === 'BOOKED' && !loadBooking.load.released;
						});
					}
					if (status === 'SCHEDULED') {
						this.shipmentStatus = 'Scheduled';
						this.filteredShipments = this.loadBookings.filter(loadBooking => {
							return loadBooking.booking.status === 'BOOKED' && loadBooking.load.status === 'BOOKED' && loadBooking.load.released && !loadBooking.load.collected
						});
					}
					if (status === 'ENROUTE') {
						this.shipmentStatus = 'Enroute';
						this.filteredShipments = this.loadBookings.filter(loadBooking => {
							return loadBooking.booking.status === 'BOOKED' && loadBooking.load.released && loadBooking.load.collected && !loadBooking.load.delivered && !loadBooking.load.confirmedDelivery;
						});
					}
					if (status === 'DELIVERY') {
						this.shipmentStatus = 'Delivery';
						this.filteredShipments = this.loadBookings.filter(loadBooking => {
							return loadBooking.booking.status === 'BOOKED' && loadBooking.load.released && loadBooking.load.collected && loadBooking.load.delivered && !loadBooking.load.confirmedDelivery || loadBooking.load.status === 'DELIVERED_WITH_DEFECTS';
						});
					}
					if (status === 'DELIVERED') {
						this.shipmentStatus = 'Delivered';
						this.filteredShipments = this.loadBookings.filter(loadBooking => {
							return loadBooking.booking.status === 'BOOKED' && loadBooking.load.released && loadBooking.load.collected && loadBooking.load.delivered && loadBooking.load.confirmedDelivery && loadBooking.load.status === 'DELIVERY_CONFIRMED';
						});
					}
					if (status === 'CANCELLED') {
						this.shipmentStatus = 'Cancelled';
						this.filteredShipments = this.loadBookings.filter(loadBooking => {
							return loadBooking.booking.status === 'CANCELLED';
						});
					}
				}
			}
		}
	};
</script>