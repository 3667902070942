<template>
	<div>
		<loading :show="show" :label="label">
	      <div class="processing-row" slot="loading-container">
	        <div class="text-center">
	          <div slot="loading-spinner">
	            <img src="@/assets/images/station-loading.gif" height="100" width="100"/>
	          </div>
	          <p class="text-center" slot="loading-text">{{ label }}</p>
	        </div>
	      </div>
	    </loading>
	    
		<div class="row" v-show="load.pickupAddress !== null">
			<div class="col-md-6">
				<div class="panel">
					<div class="panel-body">
						<div class="row">
						   	<div class="col-md-6">
						   		<span v-if="getRoles.includes('SHIPPER') || getRoles.includes('BROKER')">
						   			<router-link :to="{ name: 'my-loads' }">
						   				<button class="btn btn-primary">Back</button>
						   			</router-link>
						   		</span>

						   		<span v-if="getRoles.includes('CARRIER')">
								   	<router-link :to="{ name: 'search-loads' }">
								   		<button class="btn btn-primary">Back</button>
								    </router-link>
								</span>
								<br>
							</div>
							<div class="col-md-6">
								<div class="row">
									<div class="col-md-2" v-show="getRoles.includes('SHIPPER') || getRoles.includes('BROKER')">
										<router-link :to="{ name: 'my-bookings' }">
											<div v-if="load.bookings">
												<h4>
													<span class="label label-primary">{{ load.bookings !== null ? load.bookings.length: 0 }} Bookings</span>
												</h4>
											</div>
										</router-link>
									</div>
									<div class="col-md-2"></div>
									<div class="col-md-2" v-show="getRoles.includes('SHIPPER') || getRoles.includes('BROKER')">
										<router-link :to="{ name: 'my-bookings' }">
											<div v-if="load.bids">
												<h4>
													<span class="label label-warning">{{ load.bids !== null ? load.bids.length: 0 }} Bids</span>
												</h4>
											</div>
										</router-link>
									</div>
								</div>
							</div>
						</div>
						<br><br>

						<h4>Load Information</h4>
						<div class="row">
							<div class="col-md-6" v-if="load !== null">
								<span class="label label-primary">PICKUP</span>
								<h4>{{ load.pickupAddress.province !== null ? load.pickupAddress.province.name: "Not available" }}, {{ load.pickupAddress.city !== null ? load.pickupAddress.city.name: "Not available" }}</h4>
								<p>FACILITY ADDRESS</p>
								<p>{{ load.pickupAddress.physicalAddress !== null ? load.pickupAddress.physicalAddress: "Not available" }}</p>
							</div>
							<div class="col-md-2">
								<img src="@/assets/images/right-arrow.png" height="30" width="30">
							</div>
							<div class="col-md-4">
								<h4>PICKUP DATE</h4>
								<p>{{ load.pickupDate }} <img src="@/assets/images/at.png" height="14" width="14"> {{ load.pickupTime }}</p>
								<p>AVG Loading TIME - {{ load.estimatedLoadingTime }}{{ load.estimatedLoadingTimeMeasurement }}</p>
							</div>
						</div>
						<hr>

						<div class="row">
							<div class="col-md-6" v-if="load.destinationAddress !== null">
								<span class="label label-warning">DELIVERY</span>
								<h4>{{ load.destinationAddress.province !== null ? load.destinationAddress.province.name: "Not available" }}, {{ load.destinationAddress.city !== null ? load.destinationAddress.city.name: "Not available" }}</h4>
								<p>FACILITY ADDRESS</p>
								<p>{{ load.destinationAddress.physicalAddress !== null ? load.destinationAddress.physicalAddress: "Not available" }}</p>
								<p>AVG Delivery TIME - {{ load.estimatedDeliveryTime }}{{ load.estimatedDeliveryTimeMeasurement }}</p>
							</div>
							<div class="col-md-2">
								<img src="@/assets/images/right-arrow.png" height="30" width="30">
							</div>
							<div class="col-md-4">
								<p>DELIVERY DATE</p>
								<p>{{ load.dropOffDate }} <img src="@/assets/images/at.png" height="14" width="14"> {{ load.dropOffTime }}</p>
							</div>
						</div>
						<hr>

						<div class="row">
							<div class="col-md-6">
								<h4>EQUIPMENT TYPE REQUIRED</h4>
								<p> Trailer type <span class="badge">{{ load.trailerTypeRequired }}</span></p>

								<h4>COMMODITY</h4>
								<p>{{ load.commodity }}</p>
							</div>
							<div class="col-md-6">
								<h4>MODE</h4>
								<p><span class="badge">{{ load.loadMode }}</span></p>
							</div>
						</div>
					</div>
				</div>

				<div class="panel">
					<div class="panel-body">
						<div class="row">
							<div class="col-md-2">
								<h4>{{ load.currency }}${{ load.price }}</h4>
								<p><span class="badge">{{ load.loadType }} load</span></p>
								<p>Weight: {{ load.weight }}{{ load.weightMeasurement }}</p>
							</div>
							<div class="col-md-2">
								<h4>DISTANCE <span class="badge">{{ load.estimatedDistance/1000 }} km</span></h4>
							</div>
							<div class="col-md-2">
								<!-- <span class="glyphicon glyphicon-envelop">Message</span> -->
							</div>
							<div class="col-md-2" v-show="getRoles.includes('CARRIER') && !carrierAlreadyBidLoad && !carrierAlreadyBookedLoad">
								<router-link :to="{ name: 'bid-load', params: { loadNumber: load.loadNumber }}">
									<button class="btn btn-default">Bid Load <span class="glyphicon glyphicon-ok-circle"></span></button>
								</router-link>
							</div>
							<div class="col-md-2" v-show="getRoles.includes('CARRIER') && carrierAlreadyBidLoad">
								<p><span class="label label-warning">Already bid</span></p>
							</div>
							<div class="col-md-1"></div>
							<div class="col-md-2" v-show="getRoles.includes('CARRIER') && !carrierAlreadyBidLoad && !carrierAlreadyBookedLoad">
								<router-link :to="{ name: 'book-load', params: { loadNumber: load.loadNumber }}">
									<button class="btn btn-primary">Book Load <span class="glyphicon glyphicon-ok-circle"></span></button>
								</router-link>
							</div>
							<div class="col-md-2" v-show="getRoles.includes('CARRIER') && carrierAlreadyBookedLoad">
								<p><span class="label label-warning">Already booked</span></p>
							</div>
						</div>
					</div>
				</div>
			</div>

			<div class="col-md-6">
				<div class="panel">
					<div class="panel-body">
						<!-- <p><a href="#">I want to be notified about loads in this lane</a></p> -->
						<p>Map View of load destination from source</p>
						<ShowDistanceComponent
							:pickupAddressLatitude="load.pickupAddress.latitude"
							:pickupAddressLongitude="load.pickupAddress.longitude"
							:destinationAddressLatitude="load.destinationAddress.latitude"
							:destinationAddressLongitude="load.destinationAddress.longitude"/>
					</div>
				</div>
			</div>
		</div>
		
		<div v-if="getRoles.includes('CARRIER')">
			<div class="panel">
				<div class="panel-body">
					<div class="row">
						<div class="col-md-6">
							<h4>Suggested back haul within the delivery vicinity of this load</h4>
						</div>
						<div class="col-md-3">
							<h4>Showing loads with:</h4>
						</div>
						<div class="col-md-3">
							<select class="form-control" @change="findReturnLoads(load.destinationAddress.city.name, load.loadNumber)">
								<option value="">Select option</option>
								<!-- <option>Similar pickup</option> -->
								<!-- <option>Similar lane</option> -->
								<!-- <option>Backhaul</option> -->
								<option>From delivery</option>
								<!-- <option>Similar delivery</option> -->
								<!-- <option>Next loads</option> -->
							</select>
						</div>
					</div>
				</div>
			</div>
			<BackHaulComponent :loads="backHaul"/>
		</div>
	</div>
</template>

<script type="text/javascript">
	import loading from "vue-full-loading";
	import LoadService from "@/services/LoadService";
	import Load from "@/models/Load";
	import Bid from "@/models/Bid";
	import Booking from "@/models/Booking";
	import ShowDistanceComponent from "@/views/distance/ShowDistanceComponent";
	import BackHaulComponent from "@/views/loads/BackHaulComponent";

	export default{
		name: 'ViewLoad',
		components: {
			loading,
			ShowDistanceComponent,
			BackHaulComponent
		},

		data: function(){
			return {
				label: "Processing request",
				show: false,
				load: new Load,
				bid: new Bid,
				booking: new Booking,
				backHaul: [],
				carrierAlreadyBookedLoad: false,
				carrierAlreadyBidLoad: false
			}
		},

		mounted(){
			this.getLoadByLoadNumber();
			this.checkCarrierAlreadyBookedLoad();
			this.checkCarrierAlreadyBidLoad();
		},

		computed: {
			getLoadNumber: function(){
				return this.$route.params.loadNumber;
			},

			getUser: function() {
		        return this.$store.state.auth.user.user;
		    },

		    getRoles: function() {
		    	let roles = [];
		    	for (var i = 0; i < this.getUser.authorities.length; i++) {
		    		roles.push(this.getUser.authorities[i].authority);
		    	}
		    	return roles;
		    },
		},

		methods: {
			getLoadByLoadNumber: function(){
				this.show = true;
				LoadService.findByLoadNumber(this.getLoadNumber).then(response => {
					this.load = response.data.body;
					this.show = false;
		        }).catch(error => {
		        	console.log(error);
		        	this.show = false;
		        });
			},

			findReturnLoads: function(city, loadNumber){
				this.show = true;
				LoadService.findReturnLoads(city).then(response => {
					this.backHaul = response.data.body.filter(load => {
						return load.destinationAddress !== null && load.loadNumber !== loadNumber;
					});
					this.show = false;
		        }).catch(error => {
		        	console.log(error);
		        	this.show = false;
		        });
			},

			checkCarrierAlreadyBookedLoad: function(){
				this.show = true;
				LoadService.checkCarrierAlreadyBookedLoad(this.getUser.username, this.getLoadNumber).then(response => {
					this.carrierAlreadyBookedLoad = response.data.body;
					this.show = false;
		        }).catch(error => {
		        	console.log(error);
		        	this.show = false;
		        });
			},

			checkCarrierAlreadyBidLoad: function(){
				this.show = true;
				LoadService.checkCarrierAlreadyBidLoad(this.getUser.username, this.getLoadNumber).then(response => {
					this.carrierAlreadyBidLoad = response.data.body;
					this.show = false;
		        }).catch(error => {
		        	console.log(error);
		        	this.show = false;
		        });
			},
		}
	};
</script>