<template>
	<div>
		<div class="panel">
			<div class="panel-body">
				<div class="row">
					<div class="col-md-4"></div>
					<div class="col-md-4">
						<h4 class="text-danger" v-show="errorMessage !== null">{{ errorMessage }}</h4>
						<br>
						<form @submit.prevent="verifyOTP">
							<div class="form-group">
								<h4>Enter OTP sent to your email or phone to proceed</h4>
								<input type="number" name="otp" id="otp" v-model="otpCode" class="form-control" placeholder="Enter OTP" required>
							</div>
							<div class="form-group">
								<label>&nbsp;</label>
								<button class="btn btn-success pull-right" type="submit">Submit</button>
							</div>
						</form>
					</div>
					<div class="col-md-4"></div>
				</div>
			</div>
		</div>
	</div>
</template>

<script type="text/javascript">
	import OneTimePasswordService from "@/services/OneTimePasswordService";
	import SubscriptionService from "@/services/SubscriptionService";

	export default{
		data: function(){
			return {
				otpCode: null,
				errorMessage: null
			}
		},

		created(){

		},

		computed: {
			currentUser() {
				return this.$store.state.auth.user;
			},

			loggedIn() {
		        return this.$store.state.auth.status.loggedIn;
		    },
		},

		methods: {
			verifyOTP: function(){
				OneTimePasswordService.OTPExpired(this.otpCode).then(response => {
					console.log(response);
					if (response.data.body === true) {
						this.errorMessage = "Oops! OTP Expired. Please request new OTP";
					}else{
          				this.findByActiveSubscription();
					}
				}).catch(error => {
					this.errorMessage = error.response.data.body;
					if (error.response.status === 500) {
						this.errorMessage = error.response.data.message;
					}
				});
			},

			findByActiveSubscription: function(){
		        this.show = true;
		        SubscriptionService.findByActiveSubscription(this.currentUser.user.username).then(response => {
		        	if (!response.data.body) {
		        		this.$router.push({ name: 'subscription-suspended'});
		        	}else{
		        		this.$router.push({ name: 'dashboard'});
		        	}
		          this.show = false;
		        }).catch(error => {
		          if (error.response.status === 500) {
		            this.message = error.response.data.message;
		          }
		          this.show = false;
		        });
		    },
		}
	};
</script>