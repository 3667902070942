<template>
  <div>
    <loading :show="show" :label="label">
      <div class="processing-row" slot="loading-container">
        <div class="text-center">
          <div slot="loading-spinner">
            <img src="@/assets/images/station-loading.gif" height="100" width="100"/>
          </div>
          <p class="text-center" slot="loading-text">{{ label }}</p>
        </div>
      </div>
    </loading>

     <div class="row">
      <div class="col-md-10"></div>
      <div class="col-md-1">
        <button class="btn btn-primary btn-block" @click="enableEdit">Edit <span class="glyphicon glyphicon-edit"></span></button>
      </div>
      <div class="col-md-1">
        <button class="btn btn-success btn-block btn-sm" @click="addContactPersonFields" type="button">Add contact <span class="glyphicon glyphicon-plus"></span></button>
      </div>
    </div>
    <br>

    <form class="form" @submit.prevent="update">
      <div v-show="company.associatedUser !== null">
        <div v-for="(contactPersonField, index) in company.associatedUser" :key="index">
          <div class="form-group">
            <label for="salutation">Salutation</label>
            <select v-model="contactPersonField.salutation" class="form-control" :disabled="isDisabled">
              <option value="">Select salutation</option>
              <option value="MR">Mr</option>
              <option value="MRS">Mrs</option>
              <option value="MISS">Miss</option>
              <option value="MS">Ms</option>
              <option value="DOCTOR">Doc</option>
              <option value="PROFESSOR">Prof</option>
              <option value="SIR">Sir</option>
            </select>
          </div>

          <div class="form-group">
            <label for="firstName">First Name</label>
            <input type="text" v-model="contactPersonField.firstName" id="firstName" class="form-control" placeholder="Enter First Name" :disabled="isDisabled"/>
          </div>

          <div class="form-group">
            <label for="lastName">Last Name</label>
            <input type="text" v-model="contactPersonField.lastName" id="lastName" class="form-control" placeholder="Enter Last Name" :disabled="isDisabled"/>
          </div>

          <div class="form-group">
            <label for="email">Email</label>
            <input type="email" v-model="contactPersonField.email" id="email" class="form-control" placeholder="Enter Email" :disabled="isDisabled"/>
          </div>

          <div>
            <span v-show="isDisabled === false">
              <button class="btn btn-success btn-sm pull-right" @click="addPhoneNumberFields(index)" type="button">Add Phone Number <span class="glyphicon glyphicon-plus"></span></button>
            </span><br><br>

            <div v-show="contactPersonField.phoneNumbers.length > 0">
              <div v-for="(phoneNumberField, phoneNumberIndex) in contactPersonField.phoneNumbers" :key="phoneNumberIndex">
                <div class="row">
                  <div class="col-md-5">
                     <div class="form-group" v-show="isDisabled">
                      <label>Country Code</label>
                      <input type="text" name="zipCodeId" id="zipCodeId" class="form-control" :placeholder="phoneNumberField.zipCode !== null ? phoneNumberField.zipCode.code: 'Not available'" :disabled="isDisabled">
                    </div>
                    <div class="form-group" v-show="!isDisabled">
                      <label for="phoneNumbers">Country Code</label>
                      <select class="form-control" v-model="phoneNumberField.zipCodeId" :disabled="isDisabled">
                        <option value="" disabled>Select option</option>
                        <option v-for="zipCode in zipCodes" :key="zipCode.id" :value="zipCode.id">+{{ zipCode.code }}</option>
                      </select>
                    </div>
                  </div>
                  <div class="col-md-6">
                    <div class="form-group">
                      <label for="phoneNumbers">Phone Number</label>
                      <input type="number" v-model="phoneNumberField.phoneNumber" name="phoneNumber" id="phoneNumber" class="form-control" placeholder="Enter phone number without zero prefix e.g. 7712345678" maxlength="9" oninput="javascript: if (this.value.length > this.maxLength) { return this.value = this.value.slice(0, this.maxLength); }" :disabled="isDisabled"/>
                    </div>
                  </div>
                  <div class="col-md-1">
                    <div class="form-group" v-show="contactPersonField.phoneNumbers.length > 1">
                      <label>&nbsp;</label><br>
                      <span v-show="isDisabled === false">
                        <button class="btn btn-danger btn-sm pull-left" @click="contactPersonField.phoneNumbers.splice(phoneNumberIndex, 1)" type="button">Remove <span class="glyphicon glyphicon-minus"></span></button>
                      </span><br>
                    </div>
                  </div>
                </div>                
              </div>
            </div>
          </div>
        </div>
        <br>
      </div>
      <br>

      <div class="row">
        <div class="col-md-10"></div>
        <div class="col-md-2">
          <button class="btn btn-warning btn-block pull-right" type="submit">Update</button>
        </div>
      </div>
    </form>
  </div>
</template>

<script type="text/javascript">
  import ZipCodeService from "@/services/ZipCodeService.js";
  import CompanyService from "@/services/CompanyService.js";
  import loading from "vue-full-loading";

  export default {
    name: "ContactPersonComponent",
    components: {
      loading
    },

    props: {
      company: {
        type: Object,
        default: null
      }
    },

    data: function() {
      return {
        isDisabled: true,
        label: "Processing request...",
        show: false,
        zipCodes: [],
      };
    },

    created(){
      this.getZipCodes();
    },

    methods: {
      enableEdit: function(){
        if (this.isDisabled){
          this.isDisabled = false;
        }
      },

      update: function(){
        this.show = true;
        this.company.companyId = this.getCompanyId;
        console.log(this.company.contactPeople[0].phoneNumbers);
        CompanyService.save(this.company).then(response => {
          if (response.data.statusCode == 201){
            this.show = false;
            this.isDisabled = true;
            this.$router.push({ name: 'companies' });
          }}).catch(error => {
            console.log(error);
        });
      },

      findById: function() {
        CompanyService.findById(this.getCompanyId).then(response => {
          if (response.data.statusCode == 200) {
            this.company = response.data.body;
          }
        }).catch(error => {
          console.log(error);
        });
      },

      getZipCodes: function() {
        return ZipCodeService.findAll().then(response => {
          this.zipCodes = response.data.body;
        }).catch(error => {
          console.log(error);
        });
      },

      addContactPersonFields: function(){
        this.isDisabled = false;
        this.company.contactPeople.push({
          id: null,
          personType: "PERSON",
          firstName: null,
          lastName: null,
          email: null,
          salutation: null,
          phoneNumbers: [{
            id: null,
            zipCodeId: null,
            phoneNumber: null
          }],
        });
      },

      addPhoneNumberFields: function(index){
        this.company.contactPeople[index].phoneNumbers.push({
          id: null,
          phoneNumber: null,
          zipCodeId: null
        });
      },
    },

    computed: {
      getCompanyId: function(){
        return this.$route.params.companyId;
      },
    }
  };
</script>
