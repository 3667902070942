export default class Trailer{
	constructor(id, carrierUsername, trailerNumber, registrationNumber, type, inServiceDate, make, model, year, ownershipType,
		regularPickUpAddress, regularDropOffAddress, recurringType, desiredRate, currency, 
		desiredRateMeasurement, length, lengthMeasurement, maxLoadSize, maxLoadSizeMeasurement, grossVehicleMass, 
		grossVehicleMassMeasurement, netVehicleMass, netVehicleMassMeasurement, maxHeightClearance, 
		maxHeightClearanceMeasurement, loadMode, vehicleIdentificationNumber, description){
		this.id = id;
		this.carrierUsername = carrierUsername;
		this.trailerNumber = trailerNumber;
		this.registrationNumber = registrationNumber;
		this.type = type;
		this.inServiceDate = inServiceDate;
		this.make = make;
		this.model = model;
		this.year = year;
		this.ownershipType = ownershipType;
		this.regularPickUpAddress = regularPickUpAddress;
		this.regularDropOffAddress = regularDropOffAddress;
		this.recurringType = recurringType;
		this.desiredRate = desiredRate;
		this.currency = currency;
		this.desiredRateMeasurement = desiredRateMeasurement;
		this.length = length;
		this.lengthMeasurement = lengthMeasurement;
		this.maxLoadSize = maxLoadSize;
		this.maxLoadSizeMeasurement = maxLoadSizeMeasurement;
		this.grossVehicleMass = grossVehicleMass;
		this.grossVehicleMassMeasurement = grossVehicleMassMeasurement;
		this.grossVehicleMass = grossVehicleMass;
		this.netVehicleMass = netVehicleMass;
		this.netVehicleMassMeasurement = netVehicleMassMeasurement;
		this.maxHeightClearance = maxHeightClearance;
		this.maxHeightClearanceMeasurement = maxHeightClearanceMeasurement;
		this.loadMode = loadMode;
		this.vehicleIdentificationNumber = vehicleIdentificationNumber;
		this.description = description;
	}
}