<template>
	<div>
		<loading :show="show" :label="label">
	      <div class="processing-row" slot="loading-container">
	        <div class="text-center">
	          <div slot="loading-spinner">
	            <img src="@/assets/images/station-loading.gif" height="100" width="100"/>
	          </div>
	          <p class="text-center" slot="loading-text">{{ label }}</p>
	        </div>
	      </div>
	    </loading>

		<div class="panel">
			<div class="panel-body">
				<div class="row">
					<div class="col-md-10">
						<h4>New Customer</h4>
					</div>
					<div class="col-md-2">
						<router-link :to="{ name: 'customers' }">
							<button class="btn btn-danger pull-right">Close</button>
						</router-link>
					</div>
				</div>

				<fieldset>
					<legend>Basic Info</legend>
					<form class="form" @submit.prevent="save">
						<div class="row">
							<div class="col-md-3">
								<div class="form-group">
									<label>Name</label>
									<input type="text" name="" class="form-control" placeholder="Enter name" v-model="customer.name">
								</div>
							</div>
							<div class="col-md-3">
								<div class="form-group">
									<label>Email</label>
									<input type="email" name="" class="form-control" placeholder="Enter customer email" v-model="customer.email">
								</div>
							</div>
						</div>

						<button class="btn btn-success btn-sm pull-right" type="button" @click="addPhoneNumberField">Add more <span class="glyphicon glyphicon-plus"></span></button><br>
			            <div v-if="phoneNumberFields.length > 0">
			              <div v-for="(field, index) in phoneNumberFields" :key="field.id">
			                <div class="row">
			                  <div class="col-md-3">
			                    <div class="form-group">
			                      <label for="phoneNumbers">Country Code</label>
			                      <select class="form-control" v-model="field.zipCodeId" required>
			                        <option value="" disabled>Select option</option>
			                        <option v-for="zipCode in zipCodes" :key="zipCode.id" :value="zipCode.id">+{{ zipCode.code }}</option>
			                      </select>
			                    </div>
			                  </div>
			                  <div class="col-md-3">
			                    <div class="form-group">
			                      <label for="phoneNumbers">Business Phone Number</label>
			                      <input type="number" name="phoneNumber" class="form-control" v-model="field.phoneNumber" minlength="6" maxlength="9" placeholder="Enter phone number e.g 77123456789" required>
			                    </div>              
			                  </div>
			                  <div class="col-md-2">
			                    <div class="form-group" v-show="phoneNumberFields.length > 1">
			                      <label>&nbsp;</label><br>
			                      <button class="btn btn-danger btn-sm pull-left" type="button" @click="phoneNumberFields.splice(index, 1)">Remove <span class="glyphicon glyphicon-minus"></span></button><br>
			                    </div>
			                  </div>
			                </div>          
			              </div>
			            </div>

						<div class="row">
							<div class="col-md-12">
								<div class="form-group">
									<label>Notes</label>
									<textarea class="form-control" rows="5" placeholder="Enter notes about the customer" v-model="customer.description" minlength="5" maxlength="250"></textarea>
								</div>
							</div>
						</div>

						<div class="row">
							<div class="col-md-10"></div>
							<div class="col-md-2">
								<div class="form-group">
									<label>&nbsp;</label>
									<button class="btn btn-success btn-block" type="submit">Submit</button>
								</div>
							</div>
						</div>
					</form>
				</fieldset>
			</div>
		</div>
	</div>
</template>

<script type="text/javascript">
  import ZipCodeService from "@/services/ZipCodeService.js";
  import CustomerService from "@/services/CustomerService.js";
  import Customer from "@/models/Customer.js";
  import loading from "vue-full-loading";

  export default {
    name: "NewCustomer",
    components: {
      loading
    },

    data: function() {
      return {
        customer: new Customer,
        show: false,
        label: "Processing request",
        errors: null,
        zipCodes: [],
        phoneNumberFields: [{
          zipCodeId: null,
          phoneNumber: null
        }],
        message: null
      };
    },

    created() {
      this.getZipCodes();
    },

    computed: {
    	getUser(){
    		return this.$store.state.auth.user.user;
    	}
    },

    methods: {
      addPhoneNumberField: function(){
        this.phoneNumberFields.push({
          zipCodeId: null,
          phoneNumber: null
        });
      },

      getZipCodes: function() {
        return ZipCodeService.findAll().then(response => {
        	this.zipCodes = response.data.body;
        }).catch(error => {
          console.log(error);
        });
      },

      save: function() {
        this.show = true;
        this.customer.phoneNumbers = this.phoneNumberFields;
        this.customer.associatedUserUsername = this.getUser.username;
        this.customer.companyType = this.companyType;
        CustomerService.save(this.customer).then(response => {
          if (response.data.statusCode === 201) {
            this.$router.push({ name: "new-customer-address", params: { id: response.data.body.id }});
          }
          if (response.data.statusCode === 500) {
            this.message = response.data.body.message;
          }
          this.show = false;
        }).catch(error => {
          console.log(error.response);
            if (error.response.status === 500) {
              this.message = error.response.data.message;
            }
            this.show = false;
          });
      }
    }
  };
</script>