<template>
  <div>
    <h4>My Load Payments</h4>
    <br>

    <div class="panel">
      <div class="panel-body">
        <form class="form">
          <div class="row">
            <div class="col-md-3">
              <div class="form-group">
                <label>Payment Type</label>
                <select class="form-control" v-model="paymentType">
                  <option value="" disabled>Select payment type</option>
                  <option value="ALL">All payments</option>
                  <option value="ONLINE_PAYMENT">Online payments</option>
                  <option value="BANK">Bank payments</option>
                  <option value="CASH">Cash payments</option>
                </select>
              </div>
            </div>

            <div class="col-md-2">
              <div class="form-group">
                <label>Currency</label>
                <select class="form-control">
                  <option value="" disabled>Select currency</option>
                  <option value="ZWL">ZWL</option>
                  <option value="USD">USD</option>
                </select>
              </div>
            </div>
            <div class="col-md-2">
              <label>From</label>
              <input type="date" name="dateFrom" id="dateFrom" class="form-control">
            </div>
            <div class="col-md-2">
              <label>To Date</label>
              <input type="date" name="dateFrom" id="dateFrom" class="form-control">
            </div>
          </div>
        </form>
        
        <table class="table table-bordered table-hover table-striped table-responsive">
          <thead class="bg-primary">
            <!-- <th>ID</th> -->
            <th>Invoice</th>
            <th>Load Number</th>
            <th>Debit Reference</th>
            <th>Amount</th>
            <th>Channel</th>
            <!-- <th>Status</th> -->
            <th>Action</th>
          </thead>
          <tbody v-if="loads !== null">
            <tr v-for="load in loads" :key="load.id">
              <!-- <td>{{ load.invoice.id }}</td> -->
              <td>
                <a :href="PDF_API_URL + 'pdf/generateInvoice/' + load.invoice.id " target="_blank">{{ load.invoice.invoiceNumber }}</a>
              </td>
              <td>{{ load.loadNumber }}</td>
              <td>{{ load.invoice.payment.debitReference }}</td>
              <td>{{ load.invoice.payment.currency }}{{ load.invoice.payment.amount }}</td>
              <td>{{ load.invoice.payment.paymentMethod }}</td>
              <!-- <td>
                <span class="label label-primary" v-show="load.invoice.status === 'ACTIVE'">Submitted</span>
                <span class="label label-primary" v-show="load.invoice.status === 'UNPAID'">Unpaid</span>
                <span class="label label-success" v-show="load.invoice.status === 'PAID'">View withholding POP</span>
                <span class="label label-success" v-show="load.invoice.status === 'AUTHORIZED'">Authorized</span>
                <span class="label label-success" v-show="load.invoice.status === 'RELEASED'">Released</span>
                <span class="label label-success" v-show="load.invoice.status === 'WITHHOLDING'">In Escrow</span>
              </td> -->
              <td>
                <span>
                  <router-link :to="{ name: 'view-payment', params: { invoiceNumber: load.invoice.invoiceNumber }, query: { filter: 'payments-list'}}">
                    <button class="btn btn-primary btn-sm">View <span class="glyphicon glyphicon-info-sign"></span></button>
                  </router-link>
                </span>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script type="text/javascript">
  import LoadService from "@/services/LoadService.js";
  import { PDF_API_URL } from "@/config/PdfServiceConfig.js";

  export default {
    name: "MyPayments",
    data: function() {
      return {
        paymentType: null,
        payments: [],
        loads: [],
        PDF_API_URL: PDF_API_URL,
      };
    },

    created: function() {
      if (this.getRoles.includes('BROKER') || this.getRoles.includes('SHIPPER')) {
        this.findByOwnerUsername();
      }
      if (this.getRoles.includes('CARRIER')) {
        this.findByCarrierAssociatedUserUsername();
      }
    },

    computed: {
      loggedIn() {
        return this.$store.state.auth.status.loggedIn;
      },

      getUser: function() {
        return this.$store.state.auth.user.user;
      },

      getRoles: function() {
        let roles = [];
        for (var i = 0; i < this.getUser.authorities.length; i++) {
          roles.push(this.getUser.authorities[i].authority);
        }
        return roles;
      },
    },

    methods: {
      findByCarrierAssociatedUserUsername: function(){
        LoadService.findByCarrierAssociatedUserUsername(this.$store.state.auth.user.user.username).then(response => {
          this.loads = response.data.body.filter(load => {
            return load.invoice !== null && load.invoice.payment !== null;
          });
        }).catch(error => {
          console.log(error);
        });
      },

      findByOwnerUsername: function(){
        LoadService.findByOwnerUsername(this.$store.state.auth.user.user.username).then(response => {
          this.loads = response.data.body.filter(load => {
            return load.invoice !== null && load.invoice.payment !== null;
          });
        }).catch(error => {
          console.log(error);
        });
      }
    }
  };
</script>
