<template>
  <div>
    <loading :show="show" :label="label">
      <div class="processing-row" slot="loading-container">
        <div class="text-center">
          <div slot="loading-spinner">
            <img src="@/assets/images/station-loading.gif" height="100" width="100"/>
          </div>
          <p class="text-center" slot="loading-text">{{ label }}</p>
        </div>
      </div>
    </loading>

    <div v-if="company !== null">
      <div class="panel">
        <div class="panel-body text-center">
          <h3>Welcome to FreightBoard TMS 🎉, {{ company != null ? company.name: '' }}</h3>
          <h4>You just started a smarter way to manage your fleet and loads efficiently</h4>
        </div>
      </div>
    </div>

    <div class="panel">
      <div class="panel-body text-center">
        <div class="row">
          <div class="col-md-4">
            <img src="@/assets/images/box-truck.png" height="30" width="30">
            <h4>Post Your Truck</h4>
            <p>Post your truck or fleet so shippers and brokers can find you.</p>
            <router-link :to="{ name: 'new-truck' }">
              <button class="btn btn-primary"><span class="glyphicon glyphicon-plus"></span> Add New Posting</button>
            </router-link>
          </div>
          <div class="col-md-4">
            <img src="@/assets/images/trailer.png" height="30" width="30">
            <h4>Post Your Trailer</h4>
            <p>Post your trailer so shippers and brokers can find you.</p>
            <router-link :to="{ name: 'new-trailer' }">
              <button class="btn btn-primary"><span class="glyphicon glyphicon-plus"></span> Add New Posting</button>
            </router-link>
          </div>
          <div class="col-md-4">
            <img src="@/assets/images/driver.png" height="30" width="30">
            <h4>Post Driver</h4>
            <p>Manage your truck or fleet drivers.</p>
            <router-link :to="{ name: 'new-driver' }">
              <button class="btn btn-primary"><span class="glyphicon glyphicon-plus"></span> Add New Posting</button>
            </router-link>
          </div>
        </div>
      </div>
    </div>
      
    <div class="row">
      <div class="col-md-4">
        <div class="panel">
          <div class="panel-body text-center">
            <h2>01 <img src="@/assets/images/checkmark.png" width="50px" height="50px"></h2>
            <h4>Setup your organization</h4>
            <p>Add the pay day, load start number, per diem and more.</p>
          </div>
        </div>
      </div>
      <div class="col-md-4">
        <div class="panel">
          <div class="panel-body text-center">
            <h2>02</h2>
            <h4>Build your first load</h4>
            <p>Create loads to be assigned to a driver and dispatched.</p>
          </div>
        </div>
      </div>
      <div class="col-md-4">
        <div class="panel">
          <div class="panel-body">
            <h2>03</h2>
            <h4>Send your first invoice</h4>
            <p>Select invoices to be sent to your customer/broker.</p>
          </div>
        </div>
      </div>
    </div>

    <h4><img src="@/assets/images/pro.png" width="50px" height="50px"> Integrations</h4>
    <div class="row">
      <div class="col-md-4">
        <div class="panel">
          <div class="panel-body text-center">
            <h4>ELD provider</h4>
            <p>Track your fleet in real-time. <a href="#">Read more.</a></p>
            <button class="btn btn-default">Integrate ELD</button>
          </div>
        </div>
      </div>
      <div class="col-md-4">
        <div class="panel">
          <div class="panel-body text-center">
            <h4>Loadboards</h4>
            <p>Book loads directly from CarrierTMS. <a href="#">Read more.</a></p>
            <button class="btn btn-default">Integrate LoadBoards</button>
          </div>
        </div>
      </div>
      <div class="col-md-4">
        <div class="panel">
          <div class="panel-body">
            <h4>Quickbooks</h4>
            <p>Send invoices generated in CarrierTMS to Quickbooks. <a href="#">Read more.</a></p>
            <button class="btn btn-default">Integrate Quickbooks</button>
          </div>
        </div>
      </div>
    </div>

    <h4><img src="@/assets/images/dispatch.png" width="50px" height="50px"> Dispatching</h4>
    <div class="row">
      <div class="col-md-4">
        <div class="panel">
          <div class="panel-body text-center">
            <h4>Truckboard</h4>
            <p>Ease load planning and driver scheduling for dispatchers. <a href="#">Read more.</a></p>
            <button class="btn btn-default">Go to TruckBoard</button>
          </div>
        </div>
      </div>
      <div class="col-md-4">
        <div class="panel">
          <div class="panel-body text-center">
            <h4>Assign a dispatcher to a load</h4>
            <p>Choose a dispatcher to manage a load directly. <a href="#">Read more.</a></p>
            <button class="btn btn-default">Assign dispatcher</button>
          </div>
        </div>
      </div>
    </div>

    <h4><img src="@/assets/images/organization.png" width="50px" height="50px"> Organization profile</h4>
    <div class="row">
      <div class="col-md-4">
        <div class="panel">
          <div class="panel-body text-center">
            <h4>Invite users</h4>
            <p>Invite for dispatch, accounting and management roles in your organization. <a href="#">Read more.</a></p>
            <button class="btn btn-default">Invite users</button>
          </div>
        </div>
      </div>
      <div class="col-md-4">
        <div class="panel">
          <div class="panel-body text-center">
            <h4>Bulk importing</h4>
            <p>Import drivers, trucks, trailers, businesses, customers, and loads in bulk. <a href="#">Read more.</a></p>
            <button class="btn btn-default">Import bulk</button>
          </div>
        </div>
      </div>
    </div>
</div>
</template>

<script>
  import loading from "vue-full-loading";
  import CompanyService from "@/services/CompanyService.js";
  import ProvinceService from "@/services/ProvinceService.js";

  export default {
    name: "CarrierDashboard",
    components: {
      loading
    },

    data: function() {
      return {
        show: false,
        label: "Processing request",
        company: null,
        provinces: []
      };
    },

    mounted: function() {
      this.findByAssociatedUserUsername();
      this.findProvinces();
    },

    computed: {

      getUser: function() {
        return this.$store.state.auth.user.user;
      },

      getRoles: function() {
        let roles = [];
        for (var i = 0; i < this.getUser.authorities.length; i++) {
          roles.push(this.getUser.authorities[i].authority);
        }
        return roles;
      }
    },

    methods: {
      findByAssociatedUserUsername: function() {
        this.show = true;
        CompanyService.findByAssociatedUserUsername(this.getUser.username).then(response => {
          if (response.data.statusCode == 200) {
            this.company = response.data.body;
          }
          this.show = false;
        }).catch(error => {
          console.log(error);
          this.show = false;
        });
      },

      findProvinces: function() {
        this.show = true;
        ProvinceService.findAll().then(response => {
          if (response.data.statusCode == 200) {
            this.provinces = response.data.body;
          }
          this.show = false;
        }).catch(error => {
          console.log(error);
          this.show = false;
        });
      }
    }
  };
</script>
