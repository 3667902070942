<template>
	<div>
		<loading :show="show" :label="label">
	      <div class="processing-row" slot="loading-container">
	        <div class="text-center">
	          <div slot="loading-spinner">
	            <img src="@/assets/images/station-loading.gif" height="100" width="100"/>
	          </div>
	          <p class="text-center" slot="loading-text">{{ label }}</p>
	        </div>
	      </div>
	    </loading>

		<div class="panel">
			<div class="panel-body">
				<div class="row">
					<div class="col-md-10">
						<h4>New driver</h4>
					</div>
					<div class="col-md-2">
						<router-link :to="{ name: 'driver-list' }">
							<button class="btn btn-danger pull-right">Close</button>
						</router-link>
					</div>
				</div>

				<fieldset>
					<legend>Basic Info</legend>
					<form class="form" @submit.prevent="save">
						<div class="row">
							<div class="col-md-3">
								<div class="form-group">
									<label>Salutation</label>
									<select v-model="driver.salutation" class="form-control border-info" required>
				                  <option value="">Select salutation</option>
				                  <option value="MR">Mr</option>
				                  <option value="MRS">Mrs</option>
				                  <option value="MISS">Miss</option>
				                  <option value="MS">Ms</option>
				                  <option value="DOCTOR">Doc</option>
				                  <option value="PROFESSOR">Prof</option>
				                  <option value="SIR">Sir</option>
				                </select>
								</div>
							</div>
							<div class="col-md-3">
								<div class="form-group">
									<label>First Name</label>
									<input type="text" name="" class="form-control" placeholder="Enter last name" v-model="driver.firstName" required>
								</div>
							</div>
							<div class="col-md-3">
								<div class="form-group">
									<label>Last Name</label>
									<input type="text" name="" class="form-control" placeholder="Enter last name" v-model="driver.lastName" required>
								</div>
							</div>
							<div class="col-md-3">
								<div class="form-group">
									<label>Email</label>
									<input type="email" name="" class="form-control" placeholder="Enter email" v-model="driver.email">
								</div>
							</div>
						</div>

						<button class="btn btn-success btn-sm pull-right" type="button" @click="addPhoneNumberField">Add more <span class="glyphicon glyphicon-plus"></span></button><br>
			            <div v-if="phoneNumberFields.length > 0">
			              <div v-for="(field, index) in phoneNumberFields" :key="field.id">
			                <div class="row">
			                  <div class="col-md-3">
			                    <div class="form-group">
			                      <label for="countryId">Country Code</label>
			                      <select class="form-control" v-model="field.countryId" required>
			                        <option value="" disabled>Select option</option>
			                        <option v-for="country in countries" :key="country.id" :value="country.id">+{{ country.code }} - {{ country.name }}</option>
			                      </select>
			                    </div>
			                  </div>
			                  <div class="col-md-3">
			                    <div class="form-group">
			                      <label for="number">Phone Number</label>
			                      <input type="number" name="number" class="form-control" v-model="field.number" minlength="6" maxlength="10" placeholder="Enter phone number" required>
			                    </div>              
			                  </div>
			                  <div class="col-md-2">
			                    <div class="form-group" v-show="phoneNumberFields.length > 1">
			                      <label>&nbsp;</label><br>
			                      <button class="btn btn-danger btn-sm pull-left" type="button" @click="phoneNumberFields.splice(index, 1)">Remove <span class="glyphicon glyphicon-minus"></span></button><br>
			                    </div>
			                  </div>
			                </div>          
			              </div>
			            </div>

						<div class="row">
							<div class="col-md-12">
								<div class="form-group">
									<label>Notes (optional)</label>
									<textarea class="form-control" rows="5" placeholder="Enter notes about the driver" v-model="driver.description" minlength="5" maxlength="250"></textarea>
								</div>
							</div>
						</div>

						<div class="row">
							<div class="col-md-10"></div>
							<div class="col-md-2">
								<div class="form-group">
									<label>&nbsp;</label>
									<button class="btn btn-success btn-block" type="submit">Submit</button>
								</div>
							</div>
						</div>
					</form>
				</fieldset>
			</div>
		</div>
	</div>
</template>

<script type="text/javascript">
  import CountryService from "@/services/CountryService.js";
  import DriverService from "@/services/DriverService.js";
  import Driver from "@/models/Driver.js";
  import loading from "vue-full-loading";

  export default {
    name: "NewDriver",
    components: {
      loading
    },

    data: function() {
      return {
        driver: new Driver,
        show: false,
        label: "Processing request",
        errors: null,
        countries: [],
        phoneNumberFields: [{
          countryId: null,
          number: null
        }],
        message: null
      };
    },

    mounted() {
      this.findAllCountries();
    },

    computed: {
    	getUser: function() {
    		return this.$store.state.auth.user.user;
        },
    },

    methods: {
      addPhoneNumberField: function(){
        this.phoneNumberFields.push({
          countryId: null,
          number: null
        });
      },

      findAllCountries: function() {
      	this.show = true;
        return CountryService.findAll().then(response => {
        	this.countries = response.data.body;
        	this.show = false;
        }).catch(error => {
          console.log(error);
          this.show = false;
        });
      },

      save: function() {
        this.show = true;
        this.driver.phoneNumbers = this.phoneNumberFields;
        this.driver.carrierUsername = this.getUser.username;
        this.driver.personType = 'DRIVER';
        DriverService.save(this.driver).then(response => {
          if (response.data.statusCode === 201) {
          	this.$router.push({ name: "driver-list" });
            // this.$router.push({ name: "new-driver-address", params: { id: response.data.body.id }});
          }
          if (response.data.statusCode === 500) {
            this.message = response.data.body.message;
          }
          this.show = false;
        }).catch(error => {
          console.log(error.response);
            if (error.response.status === 500) {
              this.message = error.response.data.message;
            }
            this.show = false;
          });
      }
    }
  };
</script>